





























import Vue from "vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"

interface Params {
  headers: any[];
  selectObj: any[];
}
export default Vue.extend({

  components:{
    ObjectToCsv,
    ObjectToBacklog
  },

  data(): Params {
    return {
      headers: [
        { text: '変更日', value: "createDte" },
        { text: '入庫日時', value: "entrantTime" },
        { text: '変更日時', value: "exitTime" },
        { text: '駐車場名', value: "parkingName" },
        { text: '請求金額', value: "accountTotal" },
        { text: '削除事象', value: "logKindId" },
        { text: '変更理由', value: "reasonForChange" },
      ],
      selectObj: [],
    }
  },
  props:{
    DeleteHistoryList:{}
  }
});
