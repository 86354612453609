




























import Vue from "vue";
import axios from "axios";
import Calendar from '@/components/Home/Calendar/index.vue';
import List from '@/components/Home/List/index.vue';
interface Home {
  notification: any;
  labels: any;
}
export default Vue.extend({
  data(): Home {
    return {
      notification: [],
      labels: [],
    }
  },
  components: {
    Calendar,
    List
  },
  methods: {
    async getNotification(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/notifications`;
      return await axios
      .get(url)
      .then(res => {
        this.notification = res.data
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // labelsを取得
    async getLabels(): Promise<void> {
      this.labels = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/labels`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.labels = res.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getNotification()
    this.getLabels()
  }
})
