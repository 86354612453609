





































































































































































































import Vue from "vue";
import axios from "axios";

export default {
  data: () => ({
    // テーブルヘッダー
    headers: [
      { text: '駐車場ID', align: 'start', value: 'parkingId' },
      { text: '設定期間', value: 'startDte' },
      { text: '理由', value: 'causeType' },
      { text: '登録時間', value: 'createDte' },
      { text: 'ボタン表示', value: 'buttonType' },
      { text: 'アクション', value: 'actions' },
    ],

    // モーダルウインドウ表示スイッチ
    dialogUpsert: false,

    page: 1,
    itemsPerPage: 10,
    search: '',

    selectedLocalParking: 0,

    isCreate: true,
    localParking: 0,
    startDte: '',
    endDte: '',
    causeType: 1,
    messageJp: '',
    messageEn: '',
    createDte: '',
    buttonType: false,
    
  }),

  props: {
    messageSettings: {},
    selectedParking: {},
    getMessageSettingsLoading: {},
  },

  computed: {
    records(): any {
      return this.messageSettings.messageSettings.filter(record => 
        this.selectedLocalParking === 0 || record.parkingId === this.selectedLocalParking
      ).map(record => {
        record.startDte = this.datetimeString(record.startDte).slice(0, -3)
        record.endDte = this.datetimeString(record.endDte).slice(0, -3)
        record.createDte = this.datetimeString(record.createDte)
        record.updateDte = this.datetimeString(record.updateDte)
        return record
      }).sort((a, b) => {
        if (a.endDte > b.endDte) {
          return -1
        } else if (a.endDte < b.endDte) {
          return 1
        } else if (a.startDte > b.startDte) {
          return -1
        } else if (a.startDte < b.startDte) {
          return 1
        } else {
          return a.parkingId - b.parkingId
        }
      })
    },
    pageCount(): number {
      return Math.ceil(this.records.length / this.itemsPerPage)
    },
    localParkings() {
      const parkings = [{ pid: 0, name: 'すべて' }]
      this.messageSettings.messageSettings.forEach(record => {
        const parking = { pid: record.parkingId, name: record.parkingName }
        if (!parkings.includes(parking)) parkings.push(parking)
      })
      this.messageSettings.failedParkingIds.forEach(id => {
        parkings.push({ pid: -1 * id, name: `${id}: 接続できませんでした`})
      })
      return parkings
    },
    localParkingRules() {
      return [
        (v: any) => v >= 0 || '接続できない駐車場は選択できません',
      ]
    },
    startDteRules() {
      return [
        (v: any) => !!v || '必須項目です',
        (v: any) => new Date(v) <= new Date(this.endDte) || '終了時間より過去に設定してください。',
      ]
    },
    endDteRules() {
      return [
        (v: any) => !!v || '必須項目です',
        (v: any) => new Date(v) >= new Date() || '現在時刻より未来に設定してください。',
        (v: any) => new Date(v) >= new Date(this.startDte) || '開始時間より未来に設定してください。',
      ]
    },
    messageRules() {
      return [
        (v: any) => (this.causeType !== 3 || !!v) || '必須項目です',
      ]
    },
    formIsValid() {
      return this.startDteRules.every(rule => rule(this.startDte) === true) &&
             this.endDteRules.every(rule => rule(this.endDte) === true) &&
             this.messageRules.every(rule => rule(this.messageJp) === true) &&
             this.messageRules.every(rule => rule(this.messageEn) === true) &&
             this.localParkingRules.every(rule => rule(this.localParking) === true)
    },
  },
  methods: {
    reload () {
      this.$emit('reload')
    },
    createItem() {
      this.isCreate = true
      this.localParking = 0
      this.startDte = `${this.today()} 00:00`
      this.endDte = `${this.tomorrow()} 05:00`
      this.causeType = 1
      this.messageJp = ''
      this.messageEn = ''
      this.createDte = ''
      this.dialogUpsert = true
      this.buttonType = false
    },
    updateItem(item) {
      this.isCreate = false
      this.localParking = item.parkingId
      this.startDte = item.startDte
      this.endDte = item.endDte
      this.causeType = item.causeType
      this.messageJp = item.messageJp
      this.messageEn = item.messageEn
      this.createDte = item.createDte
      this.dialogUpsert = true
      this.buttonType = item.buttonType
    },
    async upsert(): Promise<void> {
      if (!window.confirm(`${this.isCreate ? '登録' : '更新'}しますか？`)) return

      this.dialogUpsert = false
      this.$vloading.show()
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/message_settings/${this.isCreate ? 'create' : 'update'}`;
      const params: any = {
        serverId: this.selectedParking.id,
        parkingId: this.localParking,
        startDte: this.startDte,
        endDte: this.endDte,
        causeType: this.causeType,
        buttonType: this.buttonType,
      }
      if (this.causeType === 3) {
        params['messageJp'] = this.messageJp
        params['messageEn'] = this.messageEn
      }
      if (!this.isCreate) {
        params['createDte'] = this.createDte
      }
      return await axios
      .post(url, params)
      .then(res => {
        if (res.data.failed.length > 0) {
          alert(res.data.failed.join('\n'));
          console.log(res.data)
        }
        if (res.data.succeeded.length > 0) {
          this.$emit('reload')
        }
      })
      .catch(error => {
        alert(`${this.isCreate ? '登録' : '更新'}に失敗しました`);
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide()
      })
    },
    async deleteItem(item): Promise<void> {
      if (!window.confirm('本当に削除しますか？')) return

      const url = `${process.env.VUE_APP_API_URL_BASE}/api/message_settings/destroy`;
      const params: any = {
        serverId: this.selectedParking.id,
        parkingId: item.parkingId,
        startDte: item.startDte,
        endDte: item.endDte,
      }
      return await axios
      .post(url, params)
      .then(res => {
        console.log(res.data)
        if (res.data.failed.length > 0) {
          alert(`駐車場ID${res.data.failed.join(', ')}の削除に失敗しました`);
        }
        this.$emit('reload')
      })
      .catch(error => {
        alert('削除に失敗しました');
        console.log(error)
      })
    },
    datetimeString(dt: string) {
      if (!dt) return ''

      return this.datetimeToString(new Date(dt))
    },
    datetimeToString(date: Date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)} ${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}:${('0' + second).slice(-2)}`
    },
    today() {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
    },
    tomorrow() {
      const date = new Date()
      const tomorrow = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`)
      const year = tomorrow.getFullYear()
      const month = tomorrow.getMonth() + 1
      const day = tomorrow.getDate()
      return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
    },
  }
}
