
























































































import Vue from "vue";
import axios from "axios";
interface Params {
  title: string;
  text: string;
  startTime:string;
  endTime:string;
  department: number;
  departmentItems: any;
  selectedLabels: any;
  valid: boolean;
  kind: number;
  loading:boolean;
}
export default Vue.extend({
  data(): Params {
    return {
      title: "",
      text: "",
      startTime: this.createDate,
      endTime: this.createDate,
      department: 0,
      departmentItems: [
        { text:"LocalG", value: 1 },
        { text:"WebG", value: 2 },
        { text:"MobileG", value: 3 },
        { text:"稼働保守G", value: 4 },
        { text:"サービスG", value: 5 },
        { text:"監視G", value: 6 }
      ],
      selectedLabels: [],
      valid:false,
      kind:2,
      loading:false
    }
  },
  props: {
    createDate: {},
    labels: {}
  },
  watch: {
    createDate(newVal) {
      this.startTime = newVal;
      this.endTime = newVal;
    }
  } ,
  methods: {
    isValidEndTime(value) {
      if (!this.startTime || !value) {
        return true; // startTimeやendTimeが入力されていない場合は検証をスキップ
      }
      return new Date(this.startTime) <= new Date(value);
    },
    formReset() {
      this.title = '';
      this.text = '';
      this.startTime = '';
      this.endTime = '';
      this.department = 0;
      this.selectedLabels = [];
      this.valid = false;
      this.kind = 2;
    },
    // SQL実行
    async send(title:string, text:string, startTime:string, endTime:string, department:number, selectedLabels:any, kind:number): Promise<void>{
      if (!this.$refs.form.validate()) return
      this.loading = true;
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/notifications`;
      const formData = { params:
        {
          title: title,
          text: text,
          start: startTime,
          end: endTime || startTime,
          userId: JSON.parse(localStorage.user).id,
          kind: kind,
          department: department,
          labels: selectedLabels
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        alert("送信しました");
        this.formReset();
        this.$emit('reload');
      })
      .catch(error => {
        alert("失敗しました");
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
})
