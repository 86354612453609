











































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
export default Vue.extend({
  data: () => ({
    result: [],
    headers: [
        { text: 'parking_id', value: 'parking_id'},
        { text: '駐車場名', value: 'parking_name' },
        { text: 'アカウント名', value: 'account_name' },
        { text: '作成時間', value: 'create_date' },
        { text: 'ログ', value: 'message' }
      ],
  }),
  methods: {
    async send(num:number): Promise<void> {
      if (this.selectServer) {
        this.result = [];
        this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/trouble_shootings/alert_log`;
        const formData = { params: { serverId: this.selectServer, type: num } };
        return await axios
        .get(url, formData)
        .then((res: { data: object; }) => {
          this.result = res.data
        })
        .catch((error: any) => {
          console.log(error)
        })
        .finally(() => {
          this.$vloading.hide();
        });
      } else {
        alert("物件を選択してください");
      }
    },
  }, 
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
