














































import Vue from "vue";
import axios from "axios";
export default {
  props: {
    selectParkings: {},
    getWhiteLimitLoading: {},
    whiteLimit: {},
  },
  computed: {
    whiteLimitValue: {
      get: function () { return this.whiteLimit },
      set: function (newValue) { this.$emit("changeWhiteLimit", newValue) }
    }
  },
  methods: {
    async updateWhiteLimit(): Promise<void> {
      if( this.$refs.updateform.validate() ) {
        const result = window.confirm('更新しますか？')
        if (result) {
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/update_white_limit`;
          const formData = { params: { id: this.selectParkings, whiteLimit: this.whiteLimitValue } };
          return await axios
          .post(url, formData.params)
          .then(res => {
            alert("更新しました")
            this.$emit('reload')
          })
          .catch(error => {
            console.log(error)
            alert("更新に失敗しました")
            this.$emit('reload')
          });
        }
      }
    },
    reload () {
      this.$emit('reload')
    },
  }
}
