







import Vue from "vue";

export default Vue.extend({
  props: {
    rentalCar: {
      type: Boolean,
      dafault: false
    }
  },
  computed: {
    rentalCarFlg: {
      get: function () { return this.rentalCar },
      set: function (newValue) { this.$emit("update:rentalCar", newValue) }
    }
  }
})

