









import Vue from "vue";
import axios from "axios";
import TitleBar from '@//components/Examination/TitleBar/index.vue';
import LongTermParkingVehicle from '@//components/Search/LongTermParkingVehicle/index.vue';
interface Params {
  servers: any;
  selectParking: number | null;
  menuTitle: string;
}
export default Vue.extend({
  data(): Params {
    return {
      servers: [],
      selectParking: null,
      menuTitle:"長期駐車車両一覧"
    }
  },
  components: {
    TitleBar,
    LongTermParkingVehicle
  },
  methods: {
    updateParking(newParking) {
      this.selectParking = newParking;
    },
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      })
      ;
    },
  },
  mounted() {
    this.getServerInfo();
  }
})
