

































































































































import Vue from "vue";
import axios from "axios";

  interface ReceiptSettingsDialog {
    valid:  boolean;
  }

  export default Vue.extend({
    data(): ReceiptSettingsDialog {
      return {
        valid:  true,
      }
    },
    props: {
      parkingId: { type: Number, default: 0},
      selectParkings: { type: Number, default: 0},
      corporateNumber: { type: String, default: ""},
      parkingName01: { type: String, default: ""},
      parkingName02: { type: String, default: ""},
      parkingName03: { type: String, default: ""},
      parkingName04: { type: String, default: ""},
      parkingName05: { type: String, default: ""},
      parkingName06: { type: String, default: ""}
    },
  methods: {
    async send(){
      if (this.$refs.form.validate()) {
        if (window.confirm(
          '以下の内容で更新しますか？\n\n'+
          'CORPORATE_NUMBER:'+this.corporateNumber+'\n'+
          'PARKING_NAME_01:'+this.parkingName01+'\n'+
          'PARKING_NAME_02:'+this.parkingName02+'\n'+
          'PARKING_NAME_03:'+this.parkingName03+'\n'+
          'PARKING_NAME_04:'+this.parkingName04+'\n'+
          'PARKING_NAME_05:'+this.parkingName05+'\n'+
          'PARKING_NAME_06:'+this.parkingName06+'\n')) {
          this.$emit("closeModal")
          this.$vloading.show();
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/update_receipt_settings`;
          await axios.post(url, {
            id: this.selectParkings,
            parkingId: this.parkingId,
            corporateNumber: this.corporateNumber,
            parkingName01: this.parkingName01,
            parkingName02: this.parkingName02,
            parkingName03: this.parkingName03,
            parkingName04: this.parkingName04,
            parkingName05: this.parkingName05,
            parkingName06: this.parkingName06,
          })
          .then(res => {
            this.$vloading.hide();
            this.$emit("reload")
          })
          .catch((error)=>{
            alert("更新に失敗しました")
            this.$vloading.hide();
          })
        }
      } else {
        this.$refs.form.validate()
      }
    },
    closeModal() {
      this.$emit("closeModal")
    }
  }
})
