const VueAxiosPlugin = {}
export default VueAxiosPlugin.install = function (Vue, { axios }) {
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
  //axios.defaults.timeout = 30*60*1000
  axios.interceptors.request.use(request => {
    // request.setHeader('Access-Control-Allow-Origin', 'https://commons-api.sub.pitdesign.jp/');
    if (localStorage['x-csrf-token']) {
      request.headers.common['X-CSRF-Token'] = localStorage['x-csrf-token']
    }
    return request
  })
  axios.interceptors.response.use((response) => {
    // console.log(response);
    if (response.headers['x-csrf-token']) {
      localStorage.setItem("x-csrf-token", response.headers['x-csrf-token']);
    }
    return response
  },  //(error) => {
      // if (error.status === 401) {// 今後、errorごとに処理を分けていきたい。 h_yamamno
      // location.href = `${process.env.VUE_APP_FRONT_BASE_PATH}/`;
      // }
      // }
  
  )
}
