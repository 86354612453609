






























































import Vue from 'vue'
import * as axios from 'axios'
import SelectServerList from "../components/forms/SelectServerList.vue";
import serversMixin from "../mixins/serversMixin";

export default Vue.extend({
  mixins: [serversMixin],
  components: { SelectServerList },
  data: () => ({
    dateRange: {
      date: new Date().toISOString().substr(0, 10),
      dates: []
    },
    valid: true,
    rules: ["required"],
    invalid: false,
    result: [],
  }),
  computed: {
    dateRangeText () { return this.dateRange.dates.join(' ~ ') },
  },
  methods: {
    selectedServers(arr) { this.server.selectedList = arr },
    async submit () {
      this.$vloading.show();
      // api\config\routes.rb でアクセスpointを指定  commons/unpaid_from#get_unpaid
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_unpaid_form`
      const formData = { params: {
        startDate: this.dateRange.dates[0],
        endDate:   this.dateRange.dates[1],
        servers:   this.server.selectedList,
      }}
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
      return await axios.post(url, formData.params, { timeout : 1800000 })
      .then(res => {
        const json = res.data
        alert(json.message)
        this.$vloading.hide()
      })
      .catch(err => {
        this.$vloading.hide()
        console.log(err)
        alert("データの取得に失敗しました。")
      })
    },
  },
  mounted() {
    this.getServerInfo(); //serversMixinで定義
  },
})
