





























import Vue from "vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"

interface Params {
  headers: any[];
  selectObj: any[];
}
export default Vue.extend({

  components:{
    ObjectToCsv,
    ObjectToBacklog
  },

  data(): Params {
    return {
      headers: [
        { text: '物件名', value: "parkingName" },
        { text: 'PARKING_ID', value: "parkingId" },
        { text: '状態', value: "vehicleStatusId" },
        { text: '入庫時間', value: "entrantTime" },
        { text: '出庫時間', value: "exitTime" },
        { text: '利用時間', value: "parkingTime" },
        { text: '請求金額', value: "accountTotal" },
        { text: 'サービス券利用', value: "payMethodsFlg" },
        { text: '現金', value: "payCash" },
        { text: '電子マネー/IC', value: "payIcCard" },
        { text: 'credit', value: "payCredit" },
        { text: 'QR', value: "payQr" },
        { text: '未払金額', value: "payArrears" },
        { text: '削除記録', value: "deleteFlg" },
        { text: '支払金額', value: "payTotal" },
        { text: '精算機操作', value: "payOperationFlg" },
      ],
      selectObj: [],
    }
  },
  props:{
    UsageHistoryList:{}
  }
});
