













































import Vue from "vue";
export default Vue.extend({
  props: {
    environment:     {},
    researchTypeFlg: {},
    researchStepFlg: {},
    researchEndFlg:  {},
  },
  methods: {
    type02Step00Pankuzu(): void{
      if(this.environment == "local"){
        this.$emit(
                    'pankuzu-syukkonomi-A-local-event', // 親componentに記述したイベント名
                    0,
                    2,
                    0,
                    0,
                    0,
                    [],
                    1,
                    3,
                    [],
                    12,
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    ""
                  )
      } else if (this.environment == "web"){
        this.$emit(
                    'pankuzu-syukkonomi-A-web-event', // 親componentに記述したイベント名
                    0,
                    3,
                    0,
                    0,
                    0,
                    [],
                    1,
                    3,
                    [],
                    12,
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    ""
                  )
      } else {
        document.location.reload()
      }
    },
    type02Step01Pankuzu(): void{
      this.$emit(
                  'pankuzu-syukkonomi-B-event', // 親componentに記述したイベント名
                  1,
                  0,
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type02Step02Pankuzu(): void{
      this.$emit(
                  'pankuzu-syukkonomi-C-event', // 親componentに記述したイベント名
                  2,
                  0,
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type02Step03Pankuzu(): void{
      this.$emit(
                  'pankuzu-syukkonomi-D-event', // 親componentに記述したイベント名
                  3,
                  0,
                  [],
                  [],
                  [],
                  ""
                )
    },
    type02Step04Pankuzu(): void{
      this.$emit(
                  'pankuzu-syukkonomi-E-event', // 親componentに記述したイベント名
                  4,
                  0,
                  [],
                  []
                )
    },
  }
})
