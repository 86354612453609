










































































































































































































































































































































































import Vue from "vue";

// title_bar
import TitleBar                       from '../components/not_number/TitleBar.vue';            // メニュー付きタイトル

// search_form
import NotNumberSearchForm            from '../components/not_number/from/SearchForm.vue';            // 最初の検索フォーム
import NotNumberLocalSearchResultForm from '../components/not_number/from/LocalSearchResultForm.vue'; // local検索後の調査選択フォーム
import NotNumberWebSearchResultForm   from '../components/not_number/from/WebSearchResultForm.vue';   // web検索後の調査選択フォーム

// clip_board
import NotNumberClipBoardType01 from '../components/not_number/clipboard/Type01.vue'; // 入出庫あり
import NotNumberClipBoardType02 from '../components/not_number/clipboard/Type02.vue'; // 出庫のみ
import NotNumberClipBoardType03 from '../components/not_number/clipboard/Type03.vue'; // 入出庫なし

// pankuzu
import NotNumberPankuzType01 from '../components/not_number/pankuzu/Type01.vue'; // 入出庫あり
import NotNumberPankuzType02 from '../components/not_number/pankuzu/Type02.vue'; // 出庫のみ
import NotNumberPankuzType03 from '../components/not_number/pankuzu/Type03.vue'; // 入出庫なし

// research
import NotNumberResearchType01 from '../components/not_number/research/Type01.vue'; // 入出庫あり
import NotNumberResearchType02 from '../components/not_number/research/Type02.vue'; // 出庫のみ
import NotNumberResearchType03 from '../components/not_number/research/Type03.vue'; // 入出庫なし

// list
import NotNumberReleaseNoteList       from '../components/not_number/list/ReleaseNoteList.vue';       // リリースノート

interface Notnumner {
  // 各種フラグ
  researchStartFlg: number; // 初期値 = 1, 調査開始(local) = 2, 調査開始(web) = 3, 調査移行 = 0
  researchTypeFlg:  number; // 初期値 = 0, 入出庫あり = 1, 出庫のみあり = 2, 入出庫なし = 3
  researchStepFlg:  number; // 初期値 = 0 各種調査ステップを判断
  researchEndFlg:   number; // 初期値 = 0 各種調査終了事象を判断
  problemFlg:       number; // 1 = プレート問題, 2 = 取付問題, 3 = 車体問題
  escalationFlg:    number; // 1 = 社員報告, 2 = 動画調査依頼

  // local or web どちらの検索テーブルを表示するか
  environment:string; // "local" = localの検索テーブルを表示, "web" = webの検索テーブルを表示

  // 初期取得情報
  today:        string; // フォーマットされた本日の日付け 例:2020-01-01
  userName:     string; // ログイン中のユーザーネーム
  version:      string; // ナンバーなし調査のバージョン
  servers:      any[];  // 物件一覧の値（セレクトボックス）

  // 検索フォーム入力情報
  valid:         boolean; // バリデーション
  startDate:     string;  // 始点(日付)
  endDate:       number;  // 終点(数値)
  inputNumber:   string;  // ナンバー(数値)
  selectParking: string;  // 選択した物件（id）

  // 調査情報保管
  researchParkingInformation: any[]; // 調査中の物件情報
  NumberCheckLists:           any[]; // 最初の4桁チェックで選択取得した情報
  desserts:                   any[]; // 最初の4桁チェックでSQL取得した情報

  // テーブルヘッダー
  numberCheckHeaders:              any[]; // 4桁チェック検索
  numberImgCheckHeaders:           any[]; // 4桁チェック検索(img付き)
  cameraCheckHeaders:              any[]; // カメラ管制調査
  cameraCheckCompletedHeaders:     any[]; // カメラ管制調査調査終了
  hoseiCheckHeaders01:             any[]; // 補正内容調査1分補正
  hoseiCheckHeaders02:             any[]; // 補正内容調査特殊補正
  hoseiCheckHeaders03:             any[]; // 補正内容調査ダブり削除
  seisankiCheckHeaders:            any[]; // 精算操作調査
  deleteCheckHeaders:              any[]; // 削除記録調査
  inOutMaintenancesCheckHeaders:   any[]; // 時間帯削除調査
  uploadCheckHeaders:              any[]; // アップロード遅延調査

  // テーブルフッターにあるページネーションの数字を入れるハッシュ
  footerProps: {};

  // 入出庫あり [step1]精算済 / 精算機調査調査
  type1SeisankiCheckLists:                any[]; // SQLで取得した検索情報（精算機）
  type1SeisankiCheckCompletedListsStep1:  any[]; // セレクトボックスで選択した検索情報
  type1NumberCheckCompletedListsStep1:    any[]; // セレクトボックスで選択した検索情報

  // 入出庫あり [step2]削除記録調査
  type1DeleteCheckLists:                     any[]; // [step1]のセレクトボックスで選択した検索情報（削除記録）
  type1DeleteCheckCompletedLists:            any[];
  type1NumberCheckCompletedListsStep2:       any[];
  type1SeisankiCheckCompletedListsStep2:     any[];
  type1InOutMaintenancesCheckLists:          any[]; // SQLで取得した検索情報（時間帯削除記録）
  type1InOutMaintenancesCompletedCheckLists: any[];

  // 入出庫あり [step3]アップロード調査
  type1UploadCheckLists:                 any[]; // SQLで取得した検索情報
  type1UploadCheckCompletedLists:        any[];
  type1NumberCheckCompletedListsStep3:   any[];
  type1SeisankiCheckCompletedListsStep3: any[];

  // 入出庫あり [step4]車両画像調査
  type1NumberCheckCompletedLists:      any[]; // 車両画像調査完了リザルト
  type1NumberCheckDisplayLists:        any[]; // 選択中ページネーションで表示する画像リスト
  type1NumberCheckPagination:         number; // ページネーション用のモデル（初期値は1）（表示計算用のカウントで使用する）
  type1NumberCheckPaginationPageSize: number; // ページネーションの表示枚数(初期値は3)
  type1NumberCheckPaginationLength:   number; // ページネーション表示計算用のカウント

  // 入出庫あり [step5]カメラ管制調査
  type1CameraCheckLists:              any[]; // カメラ管制調査リザルト
  type1CameraCheckCompletedLists:     any[]; // カメラ管制調査調査終了リザルト

  // 入出庫あり [step6]補正内容調査
  type1HoseiCheckLists:                 any[]; // 補正内容調査リザルト
  type1HoseiCheckCompletedListsHosei:   any[]; // 補正内容調査終了リザルト（1分補正で削除）
  type1HoseiCheckCompletedListsHoseiSP: any[]; // 補正内容調査終了リザルト（1分補正（特殊）で削除）
  type1HoseiCheckCompletedListsDaburi:  any[]; // 補正内容調査終了リザルト（ダブり補正で削除）

  // 入出庫あり [step7]撮影環境調査
  type1SurroundingsCheckLists:   any[];
  type1SurroundingsCheckStart:   any[];
  type1SurroundingsCheckEnd:     any[];

  // 出庫のみ [step1]車両画像調査
  type2NumberCheckCompletedLists:      any[]; // 車両画像調査完了リザルト
  type2NumberCheckDisplayLists:        any[]; // 選択中ページネーションで表示する画像リスト
  type2NumberCheckPagination:         number; // ページネーション用のモデル（初期値は1）（表示計算用のカウントで使用する）
  type2NumberCheckPaginationPageSize: number; // ページネーションの表示枚数(初期値は3)
  type2NumberCheckPaginationLength:   number; // ページネーション表示計算用のカウント

  // 出庫のみ [step2]カメラ管制調査
  type2CameraCheckLists:              any[]; // カメラ管制調査リザルト
  type2CameraCheckCompletedLists:     any[]; // カメラ管制調査調査終了リザルト

  // 出庫のみ [step3]補正内容調査
  type2HoseiCheckLists:                 any[]; // 補正内容調査リザルト
  type2HoseiCheckCompletedListsHosei:   any[]; // 補正内容調査終了リザルト（1分補正で削除）
  type2HoseiCheckCompletedListsHoseiSP: any[]; // 補正内容調査終了リザルト（1分補正（特殊）で削除）
  type2HoseiCheckCompletedListsDaburi:  any[]; // 補正内容調査終了リザルト（ダブり補正で削除）

  // 出庫のみ [step4]撮影環境調査
  type2SurroundingsCheckLists:   any[];
  type2SurroundingsCheckStart:   any[];
  type2SurroundingsCheckEnd:     any[];

  // 入出庫なし [step1]精算機調査
  type3SeisankiCheckLists:          any[];
  type3SeisankiCheckCompletedLists: any[];

  // 入出庫なし [step2]カメラ管制調査
  type3CameraCheckLists:          any[]; // カメラ管制調査リザルト
  type3CameraCheckCompletedLists: any[]; // カメラ管制調査調査終了リザルト

  // 入出庫なし [step3]補正内容調査
  type3HoseiCheckLists:                 any[]; // 補正内容調査リザルト
  type3HoseiCheckCompletedListsHosei:   any[]; // 補正内容調査終了リザルト（1分補正で削除）
  type3HoseiCheckCompletedListsHoseiSP: any[]; // 補正内容調査終了リザルト（1分補正（特殊）で削除）
  type3HoseiCheckCompletedListsDaburi:  any[]; // 補正内容調査終了リザルト（ダブり補正で削除）

  // 入出庫なし [step4]撮影環境調査
  type3SurroundingsCheckLists:   any[];
  type3SurroundingsCheckStart:   any[];
  type3SurroundingsCheckEnd:     any[];

  // 補正調査にて表示する画像のURL
  hoseiImgUrl: string; // 画像のURL

  // selectboxの複数選択許可
  singleSelect:    boolean; // 複数選択許可する
  notSingleSelect: boolean; // 複数選択許可しない

  // 入出庫なし[step3]補正調査にて出す質問ダイアログのスイッチ
  dialogType03Step03End01: boolean;
  dialogType03Step03End02: boolean;
  dialogType03Step03End04: boolean;
  dialogType03Step03End05: boolean;
}

export default Vue.extend({

  name: "not-number",

  data(): Notnumner {
    return {
      today:                                     "",
      userName:                                  "",
      version:                                   "version.0.6.7",
      hoseiImgUrl:                               "",
      valid:                                     true,
      researchStartFlg:                          1,
      researchTypeFlg:                           0,
      researchStepFlg:                           0,
      researchEndFlg:                            0,
      escalationFlg:                             0,
      problemFlg:                                0,
      environment:                               "",
      footerProps:                               {'items-per-page-options': [100, -1]},
      researchParkingInformation:                [],
      endDate:                                   2,
      singleSelect:                              false,
      notSingleSelect:                           true,
      servers:                                   [],
      startDate:                                 "",
      inputNumber:                               "",
      selectParking:                             "",
      desserts:                                  [],
      NumberCheckLists:                          [],
      type1SeisankiCheckLists:                   [],
      type1SeisankiCheckCompletedListsStep1:     [],
      type1SeisankiCheckCompletedListsStep2:     [],
      type1SeisankiCheckCompletedListsStep3:     [],
      type1DeleteCheckLists:                     [],
      type1DeleteCheckCompletedLists:            [],
      type1InOutMaintenancesCheckLists:          [],
      type1InOutMaintenancesCompletedCheckLists: [],
      type1UploadCheckLists:                     [],
      type1UploadCheckCompletedLists:            [],
      type1NumberCheckCompletedLists:            [],
      type1NumberCheckCompletedListsStep1:       [],
      type1NumberCheckCompletedListsStep2:       [],
      type1NumberCheckCompletedListsStep3:       [],
      type1NumberCheckPagination:                1,
      type1NumberCheckPaginationPageSize:        3,
      type1NumberCheckDisplayLists:              [],
      type1NumberCheckPaginationLength:          12,
      type1CameraCheckLists:                     [],
      type1CameraCheckCompletedLists:            [],
      type1HoseiCheckLists:                      [],
      type1HoseiCheckCompletedListsHosei:        [],
      type1HoseiCheckCompletedListsHoseiSP:      [],
      type1HoseiCheckCompletedListsDaburi:       [],
      type1SurroundingsCheckLists:               [],
      type1SurroundingsCheckStart:               [],
      type1SurroundingsCheckEnd:                 [],
      type2NumberCheckCompletedLists:            [],
      type2NumberCheckPagination:                1,
      type2NumberCheckPaginationPageSize:        3,
      type2NumberCheckDisplayLists:              [],
      type2NumberCheckPaginationLength:          12,
      type2CameraCheckLists:                     [],
      type2CameraCheckCompletedLists:            [],
      type2HoseiCheckLists:                      [],
      type2HoseiCheckCompletedListsHosei:        [],
      type2HoseiCheckCompletedListsHoseiSP:      [],
      type2HoseiCheckCompletedListsDaburi:       [],
      type2SurroundingsCheckLists:               [],
      type2SurroundingsCheckStart:               [],
      type2SurroundingsCheckEnd:                 [],
      type3SeisankiCheckLists:                   [],
      type3SeisankiCheckCompletedLists:          [],
      type3CameraCheckLists:                     [],
      type3CameraCheckCompletedLists:            [],
      type3HoseiCheckLists:                      [],
      type3HoseiCheckCompletedListsHosei:        [],
      type3HoseiCheckCompletedListsHoseiSP:      [],
      type3HoseiCheckCompletedListsDaburi:       [],
      dialogType03Step03End01:                   false,
      dialogType03Step03End02:                   false,
      dialogType03Step03End04:                   false,
      dialogType03Step03End05:                   false,
      type3SurroundingsCheckLists:               [],
      type3SurroundingsCheckStart:               [],
      type3SurroundingsCheckEnd:                 [],
      numberCheckHeaders:               [
                                        { value: 'inOutText',   text: '入出庫( ENT / INOUT )' , groupable: false },
                                        { value: 'parkingId',   text: 'ID'      , groupable: false },
                                        { value: 'entrantTime', text: '入庫時間' , groupable: false },
                                        { value: 'exitTime',    text: '出庫時間' , groupable: false },
                                        { value: 'carNumber',   text: 'ナンバー' },
                                        { value: 'status01' ,   text: '状態01'   , groupable: false },
                                        { value: 'status02' ,   text: '状態02'   , groupable: false }
                                        ],
      numberImgCheckHeaders:            [
                                        { value: 'inOutText',   text: '入出庫( ENT / INOUT )' , groupable: false },
                                        { value: 'parkingId',   text: 'ID'      , groupable: false },
                                        { value: 'entrantTime', text: '入庫時間' , groupable: false },
                                        { value: 'exitTime',    text: '出庫時間' , groupable: false },
                                        { value: 'carNumber',   text: 'ナンバー'},
                                        { value: 'status01' ,   text: '状態01'   , groupable: false },
                                        { value: 'status02' ,   text: '状態02'   , groupable: false },
                                        { value: 'frontage' ,   text: '間口'     , groupable: false },
                                        { value: 'img',         text: '画像'     , groupable: false }
                                        ],
      deleteCheckHeaders:               [
                                        { value: 'inOutText',   text: '入出庫( ENT / INOUT )' , groupable: false },
                                        { value: 'parkingId',                    text: 'ID'     , groupable: false },
                                        { value: 'entrantTime',                  text: '入庫時間', groupable: false },
                                        { value: 'exitTime',                     text: '出庫時間', groupable: false },
                                        { value: 'carNumber',                    text: 'ナンバー' },
                                        { value: 'dupInCarsAutoExiter',          text: '重複入庫キラー', groupable: false },
                                        { value: 'dailyAutoExiter',              text: '自動出庫ツール', groupable: false },
                                        { value: 'delWrongRecognitionNumber',    text: '一部誤認識消込', groupable: false },
                                        ],
      inOutMaintenancesCheckHeaders:    [
                                        { value: 'kindId',    text: '削除原因' },
                                        { value: 'startDte',  text: '始点' },
                                        { value: 'endDte',    text: '終点' },
                                        { value: 'count',     text: '削除台数' },
                                        { value: 'userName',  text: '作業者' },
                                        { value: 'createDte', text: '実行時間' },
                                        ],
      uploadCheckHeaders:               [
                                        { value: 'inOutText',   text: '入出庫( ENT / INOUT )' , groupable: false },
                                        { value: 'parkingId',   text: 'ID'      , groupable: false},
                                        { value: 'entrantTime', text: '入庫時間' , groupable: false},
                                        { value: 'exitTime',    text: '出庫時間' , groupable: false},
                                        { value: 'carNumber',   text: 'ナンバー'},
                                        { value: 'createDte',   text: '作成時間'   , groupable: false},
                                        ],
      cameraCheckHeaders:               [
                                        { value: 'id',         text: 'レコードID' , groupable: false},
                                        { value: 'adoptedId',  text: '採用ID' },
                                        { value: 'csvDate',    text: '作成時刻' , groupable: false},
                                        { value: 'carNumber',  text: 'ナンバー' },
                                        { value: 'direction',  text: '方向'     , groupable: false},
                                        { value: 'localized',  text: '処理内容' , groupable: false},
                                        { value: 'cameraType', text: 'タイプ'   , groupable: false},
                                        { value: 'gateId',     text: '間口ID'   , groupable: false},
                                        { value: 'cameraId',   text: 'カメラID' , groupable: false}
                                        ],
      cameraCheckCompletedHeaders:      [
                                        { value: 'direction',  text: '方向' },
                                        { value: 'csvDate',    text: '時刻' },
                                        { value: 'carNumber',  text: 'ナンバー' },
                                        { value: 'localized',  text: '処理内容' },
                                        { value: 'cameraType', text: 'タイプ' }
                                        ],
      hoseiCheckHeaders01:              [
                                        { value: 'pId',              text: 'pId'},
                                        { value: 'timeText',         text: '入出庫時間'},
                                        { value: 'carNumber',        text: '手動入力'},
                                        { value: 'aiCarNumber',      text: 'AI入力'},
                                        { value: 'deleter',          text: '削除'},
                                        { value: 'worker',           text: '作業者'},
                                        { value: 'img',              text: '画像'}
                                        ],
      hoseiCheckHeaders02:              [
                                        { value: 'pId',           text: 'pId'},
                                        { value: 'timeText',      text: '入出庫時間'},
                                        { value: 'carNumber',     text: '手動入力'},
                                        { value: 'aiCarNumber',   text: 'AI入力'},
                                        { value: 'motorcycleEtc', text: 'バイク等'},
                                        { value: 'diplomat',      text: '外交官'},
                                        { value: 'worker',        text: '作業者'},
                                        { value: 'img',           text: '画像'}
                                        ],
      hoseiCheckHeaders03:              [
                                        { value: 'pId',          text: 'pId',     groupable: false},
                                        { value: 'groupId',      text: 'GROUP'},
                                        { value: 'entrantTime',  text: '入庫時間', groupable: false},
                                        { value: 'carNumber',    text: '該当車両', groupable: false},
                                        { value: 'deleter',      text: '削除',     groupable: false},
                                        { value: 'worker',       text: '作業者',   groupable: false},
                                        { value: 'img',          text: '画像',     groupable: false}
                                        ],
      seisankiCheckHeaders:             [
                                        { value: 'pId',         text: 'pId'        , groupable: false},
                                        { value: 'seisanki',    text: '精算機'},
                                        { value: 'journalTime', text: '操作時間' ,     groupable: false},
                                        { value: 'input',       text: '入力ナンバー' , groupable: false},
                                        { value: 'shortLabel',  text: '操作内容',      groupable: false},
                                        { value: 'carNumber' ,  text: '操作対応車両'   , groupable: false},
                                        ],
    };
  },

  components: {
                TitleBar,
                NotNumberReleaseNoteList,
                NotNumberSearchForm,
                NotNumberLocalSearchResultForm,
                NotNumberWebSearchResultForm,
                NotNumberClipBoardType01,
                NotNumberClipBoardType02,
                NotNumberClipBoardType03,
                NotNumberPankuzType01,
                NotNumberPankuzType02,
                NotNumberPankuzType03,
                NotNumberResearchType03,
                NotNumberResearchType02,
                NotNumberResearchType01,
              },

  methods: {

    localResult(record, parkingData, startFlg, environment, selectParking, startDate, endDate, inputNumber): void{
      this.NumberCheckLists           = []
      this.desserts                   = record
      this.researchParkingInformation = parkingData
      this.researchStartFlg           = startFlg
      this.environment                = environment
      this.selectParking              = selectParking
      this.startDate                  = startDate
      this.endDate                    = endDate
      this.inputNumber                = inputNumber
    },

    webResult(record, parkingData, startFlg, environment, selectParking, startDate, endDate, inputNumber): void{
      this.NumberCheckLists           = []
      this.desserts                   = record
      this.researchParkingInformation = parkingData
      this.researchStartFlg           = startFlg
      this.environment                = environment
      this.selectParking              = selectParking
      this.startDate                  = startDate
      this.endDate                    = endDate
      this.inputNumber                = inputNumber
    },

    webType01Start(seisankiCheckLists, startFlg, typeFlg, stepFlg): void{
      this.type1SeisankiCheckLists           = seisankiCheckLists
      this.researchStartFlg                  = startFlg
      this.researchTypeFlg                   = typeFlg
      this.researchStepFlg                   = stepFlg
    },

    type01InOutMaintenancesCheck(resData, stepFlg): void{
      this.type1InOutMaintenancesCheckLists = resData
      this.researchStepFlg                  = stepFlg
    },

    type01UploadCheck(stepFlg): void{
      this.researchStepFlg                  = stepFlg
    },

    type01VehicleCheck(list, length, stepFlg): void{
        this.type1NumberCheckDisplayLists     = list
        this.type1NumberCheckPaginationLength = length
        this.researchStepFlg                  = stepFlg
    },

    type01CameraCheckStart(resData, stepFlg, problemFlg): void{
      this.type1CameraCheckLists = resData
      this.researchStepFlg       = stepFlg
      this.problemFlg            = problemFlg
    },

    type01HoseiCheckStart(resData, stepFlg): void{
      this.type1HoseiCheckLists  = resData
      this.researchStepFlg       = stepFlg
    },

    type01SurroundingsCheckStart(resData, stepFlg): void{
      this.type1SurroundingsCheckLists = resData
      this.researchStepFlg             = stepFlg
    },

    localType02Start(NumberCheckLists, displayLists, paginationLength, startFlg, typeFlg, stepFlg): void{
      this.NumberCheckLists                  = NumberCheckLists
      this.type2NumberCheckDisplayLists      = displayLists
      this.type2NumberCheckPaginationLength  = paginationLength
      this.researchStartFlg                  = startFlg
      this.researchTypeFlg                   = typeFlg
      this.researchStepFlg                   = stepFlg
    },

    webType02Start(NumberCheckLists, displayLists, paginationLength, startFlg, typeFlg, stepFlg): void{
      this.NumberCheckLists                  = NumberCheckLists
      this.type2NumberCheckDisplayLists      = displayLists
      this.type2NumberCheckPaginationLength  = paginationLength
      this.researchStartFlg                  = startFlg
      this.researchTypeFlg                   = typeFlg
      this.researchStepFlg                   = stepFlg
    },

    type02CameraCheckStart(resData, stepFlg, problemFlg): void{
      this.type2CameraCheckLists = resData
      this.researchStepFlg       = stepFlg
      this.problemFlg            = problemFlg
    },

    type02HoseiCheckStart(resData, stepFlg): void{
      this.type2HoseiCheckLists = resData
      this.researchStepFlg      = stepFlg
    },

    type02SurroundingsCheckStart(resData, stepFlg): void{
      this.type2SurroundingsCheckLists = resData
      this.researchStepFlg             = stepFlg
    },

    localType03Start(seisankiCheckLists, startFlg, typeFlg, stepFlg): void{
      this.type3SeisankiCheckLists           = seisankiCheckLists
      this.researchStartFlg                  = startFlg
      this.researchTypeFlg                   = typeFlg
      this.researchStepFlg                   = stepFlg
    },

    webType03Start(seisankiCheckLists, startFlg, typeFlg, stepFlg): void{
      this.type3SeisankiCheckLists           = seisankiCheckLists
      this.researchStartFlg                  = startFlg
      this.researchTypeFlg                   = typeFlg
      this.researchStepFlg                   = stepFlg
    },

    type03CameraCheckStart(resData, stepFlg): void{
      this.type3CameraCheckLists = resData
      this.researchStepFlg       = stepFlg
    },

    type03HoseiCheckStart(resData, stepFlg): void{
      this.type3HoseiCheckLists  = resData
      this.researchStepFlg       = stepFlg
    },

    type03SurroundingsCheckStart(resData, stepFlg, problemFlg): void {
      this.type3SurroundingsCheckLists = resData
      this.researchStepFlg             = stepFlg
      this.problemFlg                  = problemFlg
    },

    type01PaginationMove(number): void{
      this.type1NumberCheckCompletedLists = []
      this.type1NumberCheckDisplayLists = this.NumberCheckLists.slice(this.type1NumberCheckPaginationPageSize*(number -1), this.type1NumberCheckPaginationPageSize*(number)),
      this.type1NumberCheckPaginationLength = Math.ceil(this.NumberCheckLists.length/this.type1NumberCheckPaginationPageSize)
    },

    type02PaginationMove(number): void{
      this.type2NumberCheckCompletedLists = []
      this.type2NumberCheckDisplayLists = this.NumberCheckLists.slice(this.type2NumberCheckPaginationPageSize*(number -1), this.type2NumberCheckPaginationPageSize*(number)),
      this.type2NumberCheckPaginationLength = Math.ceil(this.NumberCheckLists.length/this.type2NumberCheckPaginationPageSize)
    },

    hoseiImgview(url): void{
      this.hoseiImgUrl = url
    },

    normalEnd(number): void{
      this.researchEndFlg = number
      this.dialogType03Step03End01 = false
      this.dialogType03Step03End02 = false
      this.dialogType03Step03End04 = false
      this.dialogType03Step03End05 = false
    },

    // パンくずリスト（フラグの変更と選択したstepまでのデータを初期化）
    pankuzuType01Step00(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, aa):void{
      this.problemFlg                                = a,
      this.researchStartFlg                          = b,
      this.researchTypeFlg                           = c,
      this.researchStepFlg                           = d,
      this.researchEndFlg                            = e,
      this.NumberCheckLists                          = f,
      this.type1NumberCheckPagination                = g,
      this.type1NumberCheckPaginationPageSize        = h,
      this.type1NumberCheckDisplayLists              = i,
      this.type1NumberCheckPaginationLength          = j,
      this.type1NumberCheckCompletedLists            = k,
      this.type1SeisankiCheckCompletedListsStep1     = l,
      this.type1NumberCheckCompletedListsStep1       = m,
      this.type1SeisankiCheckCompletedListsStep2     = n,
      this.type1NumberCheckCompletedListsStep2       = o,
      this.type1InOutMaintenancesCompletedCheckLists = p,
      this.type1SeisankiCheckCompletedListsStep3     = q,
      this.type1NumberCheckCompletedListsStep3       = r,
      this.type1UploadCheckLists                     = s,
      this.type1UploadCheckCompletedLists            = t,
      this.type1CameraCheckLists                     = u,
      this.type1CameraCheckCompletedLists            = v,
      this.type1HoseiCheckLists                      = w,
      this.type1HoseiCheckCompletedListsHosei        = x,
      this.type1HoseiCheckCompletedListsHoseiSP      = y,
      this.type1HoseiCheckCompletedListsDaburi       = z,
      this.hoseiImgUrl                               = aa
    },

    pankuzuType01Step01(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x):void{
      this.problemFlg                                = a,
      this.researchStepFlg                           = b,
      this.researchEndFlg                            = c,
      this.type1NumberCheckPagination                = d,
      this.type1NumberCheckPaginationPageSize        = e,
      this.type1NumberCheckDisplayLists              = f,
      this.type1NumberCheckPaginationLength          = g,
      this.type1NumberCheckCompletedLists            = h,
      this.type1SeisankiCheckCompletedListsStep1     = i,
      this.type1NumberCheckCompletedListsStep1       = j,
      this.type1SeisankiCheckCompletedListsStep2     = k,
      this.type1NumberCheckCompletedListsStep2       = l,
      this.type1InOutMaintenancesCompletedCheckLists = m,
      this.type1SeisankiCheckCompletedListsStep3     = n,
      this.type1NumberCheckCompletedListsStep3       = o,
      this.type1UploadCheckLists                     = p,
      this.type1UploadCheckCompletedLists            = q,
      this.type1CameraCheckLists                     = r,
      this.type1CameraCheckCompletedLists            = s,
      this.type1HoseiCheckLists                      = t,
      this.type1HoseiCheckCompletedListsHosei        = u,
      this.type1HoseiCheckCompletedListsHoseiSP      = v,
      this.type1HoseiCheckCompletedListsDaburi       = w,
      this.hoseiImgUrl                               = x
    },

    pankuzuType01Step02(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v):void{
      this.problemFlg                                = a,
      this.researchStepFlg                           = b,
      this.researchEndFlg                            = c,
      this.type1NumberCheckPagination                = d,
      this.type1NumberCheckPaginationPageSize        = e,
      this.type1NumberCheckDisplayLists              = f,
      this.type1NumberCheckPaginationLength          = g,
      this.type1NumberCheckCompletedLists            = h,
      this.type1SeisankiCheckCompletedListsStep2     = i,
      this.type1NumberCheckCompletedListsStep2       = j,
      this.type1InOutMaintenancesCompletedCheckLists = k,
      this.type1SeisankiCheckCompletedListsStep3     = l,
      this.type1NumberCheckCompletedListsStep3       = m,
      this.type1UploadCheckLists                     = n,
      this.type1UploadCheckCompletedLists            = o,
      this.type1CameraCheckLists                     = p,
      this.type1CameraCheckCompletedLists            = q,
      this.type1HoseiCheckLists                      = r,
      this.type1HoseiCheckCompletedListsHosei        = s,
      this.type1HoseiCheckCompletedListsHoseiSP      = t,
      this.type1HoseiCheckCompletedListsDaburi       = u,
      this.hoseiImgUrl                               = v
    },

    pankuzuType01Step03(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s):void{
      this.problemFlg                                = a,
      this.researchStepFlg                           = b,
      this.researchEndFlg                            = c,
      this.type1NumberCheckPagination                = d,
      this.type1NumberCheckPaginationPageSize        = e,
      this.type1NumberCheckDisplayLists              = f,
      this.type1NumberCheckPaginationLength          = g,
      this.type1NumberCheckCompletedLists            = h,
      this.type1SeisankiCheckCompletedListsStep3     = i,
      this.type1NumberCheckCompletedListsStep3       = j,
      this.type1UploadCheckLists                     = k,
      this.type1UploadCheckCompletedLists            = l,
      this.type1CameraCheckLists                     = m,
      this.type1CameraCheckCompletedLists            = n,
      this.type1HoseiCheckLists                      = o,
      this.type1HoseiCheckCompletedListsHosei        = p,
      this.type1HoseiCheckCompletedListsHoseiSP      = q,
      this.type1HoseiCheckCompletedListsDaburi       = r,
      this.hoseiImgUrl                               = s
    },

    pankuzuType01Step04(a, b, c, d, e, f, g, h, i):void{
      this.researchStepFlg                      = a,
      this.researchEndFlg                       = b,
      this.type1CameraCheckLists                = c,
      this.type1CameraCheckCompletedLists       = d,
      this.type1HoseiCheckLists                 = e,
      this.type1HoseiCheckCompletedListsHosei   = f,
      this.type1HoseiCheckCompletedListsHoseiSP = g,
      this.type1HoseiCheckCompletedListsDaburi  = h,
      this.hoseiImgUrl                          = i
    },

    pankuzuType01Step05(a, b, c, d, e, f, g):void{
      this.researchStepFlg                      = a,
      this.researchEndFlg                       = b,
      this.type1HoseiCheckLists                 = c,
      this.type1HoseiCheckCompletedListsHosei   = d,
      this.type1HoseiCheckCompletedListsHoseiSP = e,
      this.type1HoseiCheckCompletedListsDaburi  = f,
      this.hoseiImgUrl                          = g
    },

    pankuzuType01Step06(a, b, c, d, e, f):void{
      this.researchStepFlg                      = a,
      this.researchEndFlg                       = b,
      this.type1HoseiCheckCompletedListsHosei   = c,
      this.type1HoseiCheckCompletedListsHoseiSP = d,
      this.type1HoseiCheckCompletedListsDaburi  = e,
      this.hoseiImgUrl                          = f
    },

    pankuzuType01Step07(a, b, c, d):void{
      this.researchStepFlg             = a,
      this.researchEndFlg              = b,
      this.type2SurroundingsCheckStart = c,
      this.type2SurroundingsCheckEnd   = d
    },

    pankuzuType02Step00(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r):void{
      this.problemFlg                           = a,
      this.researchStartFlg                     = b,
      this.researchTypeFlg                      = c,
      this.researchStepFlg                      = d,
      this.researchEndFlg                       = e,
      this.NumberCheckLists                     = f,
      this.type2NumberCheckPagination           = g,
      this.type2NumberCheckPaginationPageSize   = h,
      this.type2NumberCheckDisplayLists         = i,
      this.type2NumberCheckPaginationLength     = j,
      this.type2NumberCheckCompletedLists       = k,
      this.type2CameraCheckLists                = l,
      this.type2CameraCheckCompletedLists       = m,
      this.type2HoseiCheckLists                 = n,
      this.type2HoseiCheckCompletedListsHosei   = o,
      this.type2HoseiCheckCompletedListsHoseiSP = p,
      this.type2HoseiCheckCompletedListsDaburi  = q,
      this.hoseiImgUrl                          = r
    },

    pankuzuType02Step01(a, b, c, d, e, f, g, h, i):void{
      this.researchStepFlg                      = a,
      this.researchEndFlg                       = b,
      this.type2CameraCheckLists                = c,
      this.type2CameraCheckCompletedLists       = d,
      this.type2HoseiCheckLists                 = e,
      this.type2HoseiCheckCompletedListsHosei   = f,
      this.type2HoseiCheckCompletedListsHoseiSP = g,
      this.type2HoseiCheckCompletedListsDaburi  = h,
      this.hoseiImgUrl                          = i
    },

    pankuzuType02Step02(a, b, c, d, e, f, g):void{
      this.researchStepFlg                      = a,
      this.researchEndFlg                       = b,
      this.type2HoseiCheckLists                 = c,
      this.type2HoseiCheckCompletedListsHosei   = d,
      this.type2HoseiCheckCompletedListsHoseiSP = e,
      this.type2HoseiCheckCompletedListsDaburi  = f,
      this.hoseiImgUrl                          = g
    },

    pankuzuType02Step03(a, b, c, d, e, f):void{
      this.researchStepFlg                      = a,
      this.researchEndFlg                       = b,
      this.type2HoseiCheckCompletedListsHosei   = c,
      this.type2HoseiCheckCompletedListsHoseiSP = d,
      this.type2HoseiCheckCompletedListsDaburi  = e,
      this.hoseiImgUrl                          = f
    },

    pankuzuType02Step04(a, b, c, d):void{
      this.researchStepFlg             = a,
      this.researchEndFlg              = b,
      this.type2SurroundingsCheckStart = c,
      this.type2SurroundingsCheckEnd   = d
    },

    pankuzuType03Step00(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o):void{
      this.problemFlg                           = a,
      this.researchStartFlg                     = b,
      this.researchTypeFlg                      = c,
      this.researchStepFlg                      = d,
      this.researchEndFlg                       = e,
      this.NumberCheckLists                     = f,
      this.type3SeisankiCheckLists              = g,
      this.type3SeisankiCheckCompletedLists     = h,
      this.type3CameraCheckLists                = i,
      this.type3CameraCheckCompletedLists       = j,
      this.type3HoseiCheckLists                 = k,
      this.type3HoseiCheckCompletedListsHosei   = l,
      this.type3HoseiCheckCompletedListsHoseiSP = m,
      this.type3HoseiCheckCompletedListsDaburi  = n,
      this.hoseiImgUrl                          = o
    },

    pankuzuType03Step01(a, b, c, d, e, f, g, h, i, j):void{
      this.problemFlg                           = a,
      this.researchStepFlg                      = b,
      this.researchEndFlg                       = c,
      this.type3CameraCheckLists                = d,
      this.type3CameraCheckCompletedLists       = e,
      this.type3HoseiCheckLists                 = f,
      this.type3HoseiCheckCompletedListsHosei   = g,
      this.type3HoseiCheckCompletedListsHoseiSP = h,
      this.type3HoseiCheckCompletedListsDaburi  = i,
      this.hoseiImgUrl                          = j
    },

    pankuzuType03Step02(a, b, c, d, e, f, g, h):void{
      this.problemFlg                           = a,
      this.researchStepFlg                      = b,
      this.researchEndFlg                       = c,
      this.type3HoseiCheckLists                 = d,
      this.type3HoseiCheckCompletedListsHosei   = e,
      this.type3HoseiCheckCompletedListsHoseiSP = f,
      this.type3HoseiCheckCompletedListsDaburi  = g,
      this.hoseiImgUrl                          = h
    },

    pankuzuType03Step03(a, b, c, d, e, f, g):void{
      this.problemFlg                           = a,
      this.researchStepFlg                      = b,
      this.researchEndFlg                       = c,
      this.type3HoseiCheckCompletedListsHosei   = d,
      this.type3HoseiCheckCompletedListsHoseiSP = e,
      this.type3HoseiCheckCompletedListsDaburi  = f,
      this.hoseiImgUrl                          = g
    },

    pankuzuType03Step04(a, b, c, d, e):void{
      this.problemFlg                           = a,
      this.researchStepFlg                      = b,
      this.researchEndFlg                       = c,
      this.type3SurroundingsCheckStart          = d,
      this.type3SurroundingsCheckEnd            = e
    },
  },

  mounted() {
    const user     = localStorage.user;                                                                                             // ログイン中のユーザー情報を取得
    this.userName  = JSON.parse(user).name;                                                                                         // ログイン中のユーザー情報から名前を取得
    const today    = new Date();                                                                                                    // 本日の日付けデータを取得
    this.startDate = today.getFullYear() + "-" + ('00' + (today.getMonth()+1)).slice(-2) + "-"+ ('00' + today.getDate()).slice(-2); // 日付けを検索フォームに追加（ユーザーにより可変する値）
    this.today     = today.getFullYear() + "-" + ('00' + (today.getMonth()+1)).slice(-2) + "-"+ ('00' + today.getDate()).slice(-2); // 日付けを検索フォームに追加（不変）
  }

});
