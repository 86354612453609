var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{staticClass:"mt-5 text-caption",attrs:{"color":"#37474F","dark":"","icon":"mdi-database","dense":"","outlined":""}},[_vm._v(" 説明"),_c('br'),_vm._v(" 入庫時間と精算時間を始点とし出庫時を終点とした料金を算出します。 ")]),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"フルナンバー入力"},model:{value:(_vm.fullNumber),callback:function ($$v) {_vm.fullNumber=$$v},expression:"fullNumber"}})],1),_c('v-col',[_c('v-btn',{attrs:{"outlined":"","color":"blue"},on:{"click":function($event){return _vm.assignNumberPlate(_vm.fullNumber)}}},[_vm._v(" フルナンバー入力実行 ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-5",attrs:{"small":"","color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('div',[_c('b',[_vm._v("■使い方")]),_c('br'),_vm._v(" 1.フォームにナンバーを入力してください。"),_c('br'),_vm._v(" 2.フルナンバー入力実行をクリックしてください。"),_c('br'),_vm._v(" 3.問題なければ4つの入力フォームにナンバーが入力されます。"),_c('br'),_c('b',[_vm._v("■有効な入力")]),_c('br'),_vm._v(" 例1.品川330あ1010"),_c('br'),_vm._v(" 例2.東京 330 あ **10（半角スペースで区切る）"),_c('br'),_c('b',[_vm._v("■無効な入力")]),_c('br'),_vm._v(" 例1.品川330お1010（「お」はナンバープレートに存在しない）"),_c('br'),_vm._v(" 例2.東京 330 あ **10（全角スペースで区切る）"),_c('br')])])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"place","dense":"","outlined":"","hint":"必須項目","persistent-hint":"","rules":[
          function (v) { return /^[あ-ん]{3}$|^[一-鿐ヶ]{1,4}$|^$/.test(v) || '不正な値です'; },
          function (v) { return !!v || '必須項目'; }
        ]},model:{value:(_vm.place),callback:function ($$v) {_vm.place=$$v},expression:"place"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"class","dense":"","outlined":"","hint":"必須項目","persistent-hint":"","rules":[
          function (v) { return /^[1-9][0-9ACFHKLMPXY][0-9ACFHKLMPXY]$|^[1-9][0-9ACFHKLMPXY]$/.test(v) || '不正な値です'; },
          function (v) { return !!v || '必須項目'; }
        ]},model:{value:(_vm.classNumber),callback:function ($$v) {_vm.classNumber=$$v},expression:"classNumber"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"kana","dense":"","outlined":"","hint":"必須項目","persistent-hint":"","rules":[
          function (v) { return /^[あ-えか-さす-ふほ-をABEHKMTY]$|^$/.test(v) || '不正な値です'; },
          function (v) { return !!v || '必須項目'; }
        ]},model:{value:(_vm.kana),callback:function ($$v) {_vm.kana=$$v},expression:"kana"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"car_number","dense":"","outlined":"","hint":"必須項目","persistent-hint":"","rules":[
          function (v) { return /^\*\*\*[1-9]$|^\*\*[1-9][0-9]$|^\*[1-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$|^$/.test(v) || '不正な値です'; },
          function (v) { return !!v || '必須項目'; }
        ]},model:{value:(_vm.carNumber),callback:function ($$v) {_vm.carNumber=$$v},expression:"carNumber"}})],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"warning","disabled":!_vm.valid},on:{"click":function($event){return _vm.send(_vm.selectParking, _vm.place, _vm.classNumber, _vm.kana, _vm.carNumber)}}},[_c('b',[_vm._v("検索")])])],1)],1)],1),(_vm.headers != null)?_c('v-sheet',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_c('b',[_vm._v("検索結果")])]),_c('v-spacer'),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.copyToClipBoard()}}},[_c('b',[_vm._v("選択したレコードをコピー(backlog)")])])],1),_c('v-data-table',{staticClass:"text-truncate",attrs:{"fixed-header":"","dense":"","height":"300","show-select":"","multi-sort":"","return-object":"","item-key":"index","headers":_vm.headers,"items":_vm.indexedItems,"items-per-page":15},model:{value:(_vm.copyRecord),callback:function ($$v) {_vm.copyRecord=$$v},expression:"copyRecord"}})],1):_vm._e(),_c('div',{staticClass:"clip-board",attrs:{"id":"spl20copy"}},[_vm._v(" ''■ コモンズ調査 先精算問題調査.sql（仮）''"),_c('br'),_vm._l((_vm.headers),function(item){return _c('span',{key:item.id},[_vm._v(" |"+_vm._s(item.value)+" ")])}),_vm._v(" |h"),_c('br'),_vm._l((_vm.copyRecord),function(item){return _c('span',{key:item.id},[_vm._l((item),function(value,key,index){return _c('span',{key:key},[(index != 0)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),_vm._v("| ")])}),_c('br')],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }