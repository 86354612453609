












































































































































import Vue from "vue";
import axios from "axios";
export default {
  data: () => ({
    // テーブルヘッダー
    headers: [
      { text: 'ID', align: 'start', value: 'WHITE_ID' },
      { text: '駐車場名', value: 'localParking' },
      { text: '車両情報', value: 'numberPlate' },
      { text: '種別', value: 'dayOfWeek' },
      { text: 'park&ride', value: 'parkAndRide' },
      { text: '開始日', value: 'startDate' },
      { text: '終了日', value: 'endDate' },
      { text: '登録日', value: 'createDate' },
      { text: '修正', value: 'actions' },
    ],

    dayOfWeek: [
      { id: 2, kind: "全日" },
      { id: 0, kind: "平日" },
      { id: 1, kind: "休日のみ"},
    ],

    // モーダルウインドウ表示スイッチ
    dialogUpsert: false,

    page: 1,
    itemsPerPage: 20,
    search: '',

    upsertId: 0,
    localParkings: [],
    place: "",
    placeRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => !!v?.match(/^([あ-ん]+|[一-鿐ヶ]+)$/) || '使用できない文字が含まれています。',
      (v: any) => !!v?.match(/^[あ-ん]{3}|[一-鿐ヶ]{1,4}$/) || '文字数が異常です。',
    ],
    classNumber: "",
    classNumberRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => !!v?.match(/^[0-9ACFHKLMPXY]+$/) || '使用できない文字が含まれています。',
      (v: any) => !!v?.match(/^[1-9][0-9][0-9ACFHKLMPXY]?$/) || 'フォーマットが異常です。',
    ],
    kana: "",
    kanaRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => v.length === 1 || '１文字で入力してください。',
      (v: any) => !!v?.match(/^[あ-えか-さす-ふほ-を]$/) || '使用できない文字が含まれています。',
    ],
    carNumber: "",
    carNumberRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => !!v?.match(/^[*0-9]+$/) || '使用できない文字が含まれています。',
      (v: any) => v.length === 4 || `4桁未満のナンバーは先頭に*を追加してください。=> ${`***${v}`.slice(-4)}`,
      (v: any) => !!v?.match(/^(\*\*\*[1-9]|\*\*[1-9][0-9]|\*[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/) || 'フォーマットが異常です。',
    ],
    selectedLocalParking: null,
    selectedDayOfWeek: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    note: "",
  }),

  props: {
    monthlyContracts: {},
    selectedParking: {},
    getMonthlyTollContractsLoading: {},
  },

  computed: {
    records(): any {
      return this.monthlyContracts.filter(record => record.CAR_NUMBER?.includes(this.search)).map(record => {
        const result = record
        const isParkAndRide = new Date(record.START_TIME).getTime() !== new Date("2000-01-01T00:00:00.000Z").getTime() || 
                              new Date(record.END_TIME).getTime() !== new Date("2000-01-01T00:00:00.000Z").getTime()
        result.localParking = this.localParkings.filter(localParking => localParking.pid === record.PARKING_ID)[0]?.note
        result.numberPlate =`${record.PLACE} ${record.CLASS_NUMBER} ${record.KANA} ${record.CAR_NUMBER}`
        result.dayOfWeek = this.dayOfWeek.filter(k => k.id === record.DAYOFWEEK)[0]?.kind
        result.startTime = isParkAndRide ? this.timeString(record.START_TIME) : ''
        result.endTime = isParkAndRide ? this.timeString(record.END_TIME) : ''
        result.parkAndRide = isParkAndRide ? `${result.startTime} 〜 ${result.endTime}` : "------"
        result.startDate = this.dateString(record.START_DTE)
        result.endDate = this.dateString(record.END_DTE)
        result.createDate = this.dateString(record.CREATE_DTE)
        return result
      })
    },
    pageCount(): number {
      return Math.ceil(this.records.length / this.itemsPerPage)
    },
    placeIsValid(): boolean { return this.placeRules.every(rule => rule(this.place) === true) },
    classNumberIsValid(): boolean { return this.classNumberRules.every(rule => rule(this.classNumber) === true) },
    kanaIsValid(): boolean { return this.kanaRules.every(rule => rule(this.kana) === true) },
    carNumberIsValid(): boolean { return this.carNumberRules.every(rule => rule(this.carNumber) === true) },
    formIsValid(): boolean { return this.placeIsValid && this.classNumberIsValid && this.kanaIsValid && this.carNumberIsValid },
  },
  async mounted() {
    await this.getLocalParkings()
  },
  methods: {
    reload () {
      this.$emit('reload')
    },
    // 新規登録
    insertItem () {
      this.upsertId = 0
      this.selectedLocalParking = this.localParkings[0]
      this.place = ''
      this.classNumber = ''
      this.kana = ''
      this.carNumber = ''
      this.startDate = this.today()
      this.endDate = ''
      this.startTime = ''
      this.endTime = ''
      this.selectedDayOfWeek = this.dayOfWeek[0]
      this.note = ''
      this.dialogUpsert = true
    },
    // 更新
    updateItem (item) {
      this.upsertId = item.WHITE_ID
      this.selectedLocalParking = this.localParkings.filter(p => p.pid === item.PARKING_ID)[0]
      this.place = item.PLACE
      this.classNumber = item.CLASS_NUMBER
      this.kana = item.KANA
      this.carNumber = item.CAR_NUMBER
      this.startDate = item.startDate
      this.endDate = item.endDate
      this.startTime = item.startTime
      this.endTime = item.endTime
      this.selectedDayOfWeek = this.dayOfWeek.filter(d => d.id === item.DAYOFWEEK)[0]
      this.note = item.NOTE
      this.dialogUpsert = true
    },
    // 新規登録 or 更新
    async upsert(): Promise<void> {
      if (!window.confirm(`${this.upsertId === 0 ? '登録' : '更新'}しますか？`)) return

      const url = `${process.env.VUE_APP_API_URL_BASE}/api/${this.upsertId === 0 ? 'create' : 'update'}_toll_monthly_car_record`;
      const params = {
        serverId: this.selectedParking.id,
        PARKING_ID: this.selectedLocalParking?.pid,
        START_DTE: this.startDate,
        END_DTE: this.endDate,
        PLACE: this.place,
        CLASS_NUMBER: this.classNumber,
        KANA: this.kana,
        CAR_NUMBER: this.carNumber,
        DAYOFWEEK: this.selectedDayOfWeek.id,
        START_TIME: this.startTime,
        END_TIME: this.endTime,
        NOTE: this.note,
      };
      if (this.upsertId !== 0) params['WHITE_ID'] = this.upsertId
      return await axios
      .post(url, params)
      .then(res => {
        this.$emit('reload')
        this.dialogUpsert = false
      })
      .catch(error => {
        alert(`${this.upsertId === 0 ? '登録' : '更新'}に失敗しました`);
        console.log(error)
        this.$emit('reload')
        this.dialogUpsert = false
      })
    },
    today() {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
    },
    dateString(dte) {
      const date = new Date(dte)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
    },
    timeString(dte) {
      const date = new Date(dte)
      const hour = date.getHours()
      const minute = date.getMinutes()
      return `${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}`
    },
    async getLocalParkings() {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_local_parkings`;
      const formData = { params: { lid: this.selectedParking.lid }};
      await axios.get(url, formData)
      .then(res => {
        this.localParkings = [ { pid: 0, note: '全物件' }];
        res.data.forEach(item => this.localParkings.push(item));
      })
      .catch(error => {
        console.log(error);
      });
    },
  }
}
