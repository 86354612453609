














import Vue from "vue";

export default Vue.extend({
  props: {
    parkingTime: {
      default: ""
    },
    timeLabel: {
      required: false
    }
  },
  computed: {
    timeRange: {
      get: function () { return this.parkingTime },
      set: function (newValue) { this.$emit("update:parkingTime", newValue) }
    }
  }
})
