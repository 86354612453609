
























































































































































































































































































import Vue from "vue";
export default Vue.extend({
  props: {
    today:                                     {},
    userName:                                  {},
    version:                                   {},
    researchTypeFlg:                           {},
    researchStepFlg:                           {},
    researchEndFlg:                            {},
    escalationFlg:                             {},
    problemFlg:                                {},
    hoseiImgUrl:                               {},
    researchParkingInformation:                {},
    NumberCheckLists:                          {},
    type1NumberCheckCompletedLists:            {},
    type1SeisankiCheckCompletedListsStep1:     {},
    type1InOutMaintenancesCompletedCheckLists: {},
    type1CameraCheckCompletedLists:            {},
    type1HoseiCheckCompletedListsHosei:        {},
    type1HoseiCheckCompletedListsHoseiSP:      {},
    type1HoseiCheckCompletedListsDaburi:       {},
    type1SurroundingsCheckStart:               {}
  },
})
