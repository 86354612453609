<template>
  <div>
    <v-card outlined>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <!-- 今日に移動 -->
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                今日に移動
              </v-btn>
              <!-- 月を移動 -->
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <!-- カレンダーの月日表示 -->
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <!-- カレンダー -->
            <v-calendar
              ref="calendar"
              v-model="focus"
              :events="changeKeyNotification"
              :event-color="getEventColor"
              type="month"
              @click:event="showEvent"
              @click:date="createEvent"
              locale="ja-jp"
              @change="changeColor"
              color="orange lighten-1"
            ></v-calendar>
            <!-- オープンメニュー -->
            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="colors[selectedEvent.color]" dark>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle>
                  <span v-if="selectedEvent.department == 1">LocalG : </span>
                  <span v-if="selectedEvent.department == 2">WebG : </span>
                  <span v-if="selectedEvent.department == 3">MobileG : </span>
                  <span v-if="selectedEvent.department == 4">稼働保守G : </span>
                  <span v-if="selectedEvent.department == 5">サービスG : </span>
                  <span v-if="selectedEvent.department == 6">監視G : </span>
                  {{ selectedEvent.realName }}<br>
                  実施期間 {{ selectedEvent.start }} ~ {{ selectedEvent.end }}
                </v-card-subtitle>
                <v-card-text>
                  <span style="white-space: pre-line;" v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <!-- お知らせ登録画面 -->
    <v-dialog v-model="createDialog" width="800">
      <NewForm :createDate="createDate" :labels="labels" @reload="reload"/>
    </v-dialog>
  </div>
</template>
<script>
  import NewForm from './new.vue';
  export default {
    data: () => ({
      focus: '',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      colors: [
        "#2E2D36",
        "#C41827",
        "#EDC309",
        "#035BD5",
        "#D85D98",
        "#3A3A45",
        "#9A1F2E",
        "#F2D119",
        "#0765F0",
        "#E068A8"
      ],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      createDialog:false,
      createDate:""
    }),
    components: {
      NewForm
    },
    props: {
      notification: {},
      labels: {}
    },
    computed: {
      changeKeyNotification () {
        const array = this.notification.map(value => {
          return {
            name: value.title,
            details: value.text,
            start: value.start,
            end: value.end,
            color: value.department,
            realName: value.realName,
            department: value.department,
          }
        })
        return array
      }
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      // 土曜と日曜の色変更
      changeColor: function () {
        setTimeout(() => {
          const dayList = Array.from(this.$refs.calendar.$vnode.elm.getElementsByClassName('v-calendar-weekly__week'))
          dayList.map(el => {
            el.children[0].classList.add('sunday')
            el.children[6].classList.add('saturday')
          })
        }, 200)
      },
      getEventColor (event) {
        return this.colors[event.color]
      },
      // 今日へ
      setToday () {
        this.focus = ''
      },
      // 前の月へ
      prev () {
        this.$refs.calendar.prev()
      },
      // 先の月へ
      next () {
        this.$refs.calendar.next()
      },
      // お知らせの詳細表示
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
      // 日付クリックでお知らせ送信画面追加
      createEvent({ date }) {
        console.log(date)
        this.createDate = date;
        this.createDialog = true;
      },
      reload(){
        this.createDialog = false;
        this.$emit('reload');
      },
    },
  }
</script>
<style>
.saturday {
  background: rgba(0, 0, 255, 0.4);
}
.sunday {
  background: rgba(255, 0, 0, 0.4);
}
</style>