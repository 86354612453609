<template>
  <!-- ログイン中の画面でしかサイドメニューは表示しない -->
  <v-card class="mx-auto" id="sidevar" width="200" v-if="$route.path!='/'&& $route.path!='/user-update' && $route.path!='/invalid-link' && userRole <= 4 && name != null">
    <!-- 指定の横幅以下になるとサイドメニューは非表示となる -->
    <v-navigation-drawer permanent>
      <!-- サイドメニュー -->
      <v-list class="grey lighten-4" height="100%">
        <!-- ユーザー情報 -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
            <div v-if="userRole == 1">管理者({{userRole}})</div>
            <div v-else-if="userRole == 2">社員({{userRole}})</div>
            <div v-else-if="userRole == 3">協力会社様({{userRole}})</div>
            <div v-else-if="userRole == 4 && name != null">アルバイト({{userRole}})</div>
            <div v-else-if="userRole == 0 && name != null">admin({{userRole}})</div>
            <div v-else class="text-center">ユーザー登録中...</div>
            </v-list-item-subtitle>
            <v-list-item-title class="text-h6" v-if="userRole <= 4 && name != null">
              <v-icon left>mdi-login</v-icon>{{ name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <!-- メニューリスト -->
        <v-list-item v-for="item in groups" :key="item.title" link dense>
          <v-menu transition="slide-x-transition" :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-bind="attrs" v-on="on">
                <v-list-item-title>
                  <v-icon :color="item.color" class="mr-1 mb-1">{{ item.icon }}</v-icon>{{ item.title }}
                  <v-icon class="float-right">mdi-chevron-right</v-icon>
                  <v-divider></v-divider>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <div class="sideber-group-menu">
              <v-list-item>
                <v-list-item-content>
                  <v-icon :color="item.color" x-large>{{ item.icon }}</v-icon>
                  <v-divider dark class="ma-4"/>
                  <div v-for="menu in item.menus" :key="menu.id" link dense class="mx-2 my-4">
                    <v-hover v-slot="{ hover }">
                      <router-link class="text-decoration-none" :to="menu.url">
                        <span :class="hover ? 'orange--text' : 'white--text'"><b>・{{menu.name}}</b></span>
                      </router-link>
                    </v-hover>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-menu>
        </v-list-item>
        <!-- ログアウトボタン -->
        <v-row class="justify-center">
          <router-link class="text-decoration-none" to="/" v-if="userRole <= 4 && name != null">
            <v-btn class="mt-4" tile outlined color="pink">
              <v-icon left>mdi-logout</v-icon>logout
            </v-btn>
          </router-link>
        </v-row>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  name: "SideMenu",
  data() {
    return {
      userRole: Number,
      items: Array,
      groups: Array,
      name: "",
    };
  },
  mounted() {
    this.mountUser(); // ユーザー情報の取得
    this.setGroups(); // PC版メニューの取得
  },
  methods: {
    mountUser() { // ユーザー情報の取得
      const user = localStorage.user;
      if (user !== undefined){
        this.userRole = JSON.parse(user).role_id
        this.name = JSON.parse(user).name
      } else if (user === undefined && this.$route.path!='/' && this.$route.name!='user-update') {
        location.href = `${process.env.VUE_APP_FRONT_BASE_PATH}/`;
      }
    },
    setGroups() { // PC版メニューの取得
      // admin
      if (this.userRole === 0){
        this.groups = [
          {
            title: "お知らせ", icon: "mdi-bell-circle", color: "#EDC309",
            menus: [
              { name: "お知らせ送信", url: "/notifications" },
              { name: "物件のお知らせ", url: "/labels-notifications" }
            ]
          },
          {
            title: "物件情報", icon: "mdi-server", color: "#deb887",
            menus: [
              { name: "サーバー情報", url: "/servers" }
            ]
          },
          {
            title: "SQL実行", icon: "mdi-database", color: "#4682b4",
            menus: [
              { name: "Querier", url: "/query-tools" },
              { name: "UpDataCheck SQL", url: "/update-check-sql" }
            ]
          },
          {
            title: "一括登録処理", icon: "mdi-car-multiple", color: "#a52a2a",
            menus: [
              { name: "アラート通知車両登録", url: "/alert-vehicle-bulk-registration" },
            ]
          },
          {
            title: "資料出力", icon: "mdi-download-multiple", color: "#a52a2a",
            menus: [
              { name: "精算情報出力", url: "/checkout-records" },
              { name: "店舗別報告資料出力", url: "/unpaid-form" },
              { name: "未精算レコード出力", url: "/unpaid-records" },
              { name: "未精算レコード出力(入庫中)", url: "/unpaid-records-staying" },
              { name: "不正利用車両の抽出", url: "/output-unauthorized-use-vehicles" },
              { name: "未精算出庫車両抽出", url: "/unfair-vehicles" },
              { name: "満空表出力", url: "/parking-availability-reports" }
            ]
          },
          {
            title: "データサイエンス", icon: "mdi-data-matrix", color: "#888",
            menus: [
              { name: "分析グループの作成", url: "/groups" },
              { name: "売り上げ予測", url: "/sales-forecast" },
            ]
          },
          {
            title: "Backlog関連", icon: "mdi-alpha-b-box", color: "#32cd32",
            menus: [
              { name: "Backlog集計(SMP_CALL)", url: "/backlog-aggregate" }
            ]
          },
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "Local接続", icon: "mdi-lock", color: "#ff8c00",
            menus: [
              { name: "UpDataCheck 前日", url: "/update-check-the-day-before" },
              { name: "UpDataCheck 当日", url: "/update-check-on-the-day" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "未払い車両検索", url: "/unpaid-research" },
              { name: "アラート通知車両検索", url: "/black-list-research" },
              { name: "車両情報", url: "/vehicles-information" },
              { name: "？あり車両一覧", url: "/miss-recognized" },
              { name: "長期駐車車両一覧", url: "/long-term-parking-vehicle" },
            ]
          },
          {
            title: "公開API", icon: "mdi-api", color: "red",
            menus: [
              { name: "特定車両入出庫情報出力", url: "/select-in-out-information" },
              { name: "アラート車両登録(全件)", url: "/all-black-lists" },
              { name: "アラート車両削除(全件)", url: "/all-black-lists-delete" },
              { name: "アラート車両登録(選択)", url: "/select-black-lists" },
              { name: "アラート車両削除(選択)", url: "/select-black-lists-delete" },
            ]
          },
          {
            title: "メンバー招待", icon: "mdi-account", color: "#888888",
            menus: [
              { name: "ユーザー新規登録", url: "/sign-up" },
              { name: "ユーザー管理", url: "/user-list" },
            ]
          }
        ]

      // 管理者
      } else if (this.userRole === 1){
        this.groups = [
          {
            title: "お知らせ", icon: "mdi-bell-circle", color: "#EDC309",
            menus: [
              { name: "お知らせ送信", url: "/notifications" },
              { name: "物件のお知らせ", url: "/labels-notifications" }
            ]
          },
          {
            title: "物件情報", icon: "mdi-server", color: "#deb887",
            menus: [
              { name: "サーバー情報", url: "/servers" }
            ]
          },
          {
            title: "SQL実行", icon: "mdi-database", color: "#4682b4",
            menus: [
              { name: "Querier", url: "/query-tools" },
              { name: "UpDataCheck SQL", url: "/update-check-sql" }
            ]
          },
          {
            title: "一括登録処理", icon: "mdi-car-multiple", color: "#a52a2a",
            menus: [
              { name: "アラート通知車両登録", url: "/alert-vehicle-bulk-registration" },
            ]
          },
          {
            title: "資料出力", icon: "mdi-download-multiple", color: "#a52a2a",
            menus: [
              { name: "精算情報出力", url: "/checkout-records" },
              { name: "店舗別報告資料出力", url: "/unpaid-form" },
              { name: "未精算レコード出力", url: "/unpaid-records" },
              { name: "未精算レコード出力(入庫中)", url: "/unpaid-records-staying" },
              { name: "不正利用車両の抽出", url: "/output-unauthorized-use-vehicles" },
              { name: "未精算出庫車両抽出", url: "/unfair-vehicles" },
              { name: "満空表出力", url: "/parking-availability-reports" }
            ]
          },
          {
            title: "データサイエンス", icon: "mdi-data-matrix", color: "#888",
            menus: [
              { name: "分析グループの作成", url: "/groups" },
              { name: "売り上げ予測", url: "/sales-forecast" },
            ]
          },
          {
            title: "Backlog関連", icon: "mdi-alpha-b-box", color: "#32cd32",
            menus: [
              { name: "Backlog集計(SMP_CALL)", url: "/backlog-aggregate" }
            ]
          },
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "Local接続", icon: "mdi-lock", color: "#ff8c00",
            menus: [
              { name: "UpDataCheck 前日", url: "/update-check-the-day-before" },
              { name: "UpDataCheck 当日", url: "/update-check-on-the-day" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "未払い車両検索", url: "/unpaid-research" },
              { name: "アラート通知車両検索", url: "/black-list-research" },
              { name: "車両情報", url: "/vehicles-information" },
              { name: "？あり車両一覧", url: "/miss-recognized" },
              { name: "長期駐車車両一覧", url: "/long-term-parking-vehicle" },
            ]
          },
          {
            title: "メンバー招待", icon: "mdi-account", color: "#888888",
            menus: [
              { name: "ユーザー新規登録", url: "/sign-up" },
              { name: "ユーザー管理", url: "/user-list" },
            ]
          }
        ]
      // 社員
      } else if (this.userRole === 2){ // 社員
        this.groups = [
          {
            title: "お知らせ", icon: "mdi-bell-circle", color: "#EDC309",
            menus: [
              { name: "お知らせ送信", url: "/notifications" },
              { name: "物件のお知らせ", url: "/labels-notifications" }
            ]
          },
          {
            title: "物件情報", icon: "mdi-server", color: "#deb887",
            menus: [
              { name: "サーバー情報", url: "/servers" }
            ]
          },
          {
            title: "一括登録処理", icon: "mdi-car-multiple", color: "#a52a2a",
            menus: [
              { name: "アラート通知車両登録", url: "/alert-vehicle-bulk-registration" },
            ]
          },
          {
            title: "資料出力", icon: "mdi-download-multiple", color: "#a52a2a",
            menus: [
              { name: "精算情報出力", url: "/checkout-records" },
              { name: "店舗別報告資料出力", url: "/unpaid-form" },
              { name: "未精算レコード出力", url: "/unpaid-records" },
              { name: "未精算レコード出力(入庫中)", url: "/unpaid-records-staying" },
              { name: "不正利用車両の抽出", url: "/output-unauthorized-use-vehicles" },
              { name: "未精算出庫車両抽出", url: "/unfair-vehicles" },
              { name: "満空表出力", url: "/parking-availability-reports" }
            ]
          },
          {
            title: "データサイエンス", icon: "mdi-data-matrix", color: "#888",
            menus: [
              { name: "分析グループの作成", url: "/groups" },
              { name: "売り上げ予測", url: "/sales-forecast" },
            ]
          },
          {
            title: "Backlog関連", icon: "mdi-alpha-b-box", color: "#32cd32",
            menus: [
              { name: "Backlog集計(SMP_CALL)", url: "/backlog-aggregate" }
            ]
          },
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "未払い車両検索", url: "/unpaid-research" },
              { name: "アラート通知車両検索", url: "/black-list-research" },
              { name: "車両情報", url: "/vehicles-information" },
              { name: "？あり車両一覧", url: "/miss-recognized" },
              { name: "長期駐車車両一覧", url: "/long-term-parking-vehicle" },
            ]
          },
          {
            title: "メンバー招待", icon: "mdi-account", color: "#888888",
            menus: [
              { name: "ユーザー新規登録", url: "/sign-up" }
            ]
          }
        ]

      // 協力会社
      } else if (this.userRole === 3){
        this.groups = []

      // アルバイト
      } else if (this.userRole === 4 && this.name != null){
        this.groups = [
          {
            title: "Commons調査", icon: "mdi-view-dashboard", color: "#9370db",
            menus: [
              { name: "ナンバーなし調査", url: "/not-number" },
              { name: "選択式SQL調査", url: "/sql_select" },
            ]
          },
          {
            title: "情報検索", icon: "mdi-magnify", color: "#b22222",
            menus: [
              { name: "車両情報", url: "/vehicles-information" }
            ]
          },
        ]

      }
    }
  }
});
</script>
<style scoped>
  /* PC版のサイドメニューをクリックした際に表示されるメニューリストのデザイン */
  .sideber-group-menu {
    width:350px;
    padding:10px;
    background:#2A3B4D;
  }
</style>
