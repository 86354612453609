
































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  data: () => ({
    place: "東京",
    classNumber: "330",
    kana: "あ",
    carNumber: "1010",
    kindId: 1,
    alertMailAddress: ["aaa@example.com","bbb@example.com"],
    userId: JSON.parse(localStorage.user).id,
    result: "no_result",
    mailItems: ["aaa@example.com","bbb@example.com","ccc@example.com"],
    kindItems: [1,2]
  }),
  methods: {
    async submit(): Promise<void> {
      this.result = ""
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/all_black_lists_delete`;
      const formData = {
        params: {
          place: this.place,
          classNumber: this.classNumber,
          kana: this.kana,
          carNumber: this.carNumber,
          kindId:this.kindId,
          alertMailAddress:this.alertMailAddress,
          userId: this.userId
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        console.log(res)
        this.result = res.data
        alert("取得成功");
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("取得失敗");
        this.$vloading.hide();
      });
    },
    responseCopy(): void{
      const text = document.getElementById('response-text');
      console.log(text)
      navigator.clipboard.writeText(text.innerText).then(e => { alert('コピーしました')})
    },
  }
});
