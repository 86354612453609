






























































import Vue from "vue";
import axios from "axios";
interface Result {
  result: any[] | null;
  keyword:string;
}
export default Vue.extend({
  data(): Result {
    return {
      result: null,
      keyword: "",
    }
  },
  methods: {
    async getLabelsNotifications(): Promise<void> {
      this.result = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/labels_notifications`;
      return await axios
      .get(url)
      .then(res => {
        this.result = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getLabelsNotifications();
  }
})
