<template>
  <div class="result-unpaid-records">
    <router-link :to="{name: 'output-unpaid-records-staying'}">検索に戻る</router-link>
    <p v-if="this.filteredRecords.length!==0" class="mt-5">現在入庫中の未精算車両を表示しています。</p>
    <p v-else class="mt-5">現在入庫中の未精算車両はありませんでした。</p>
    <div class="d-flex desc-area">
      <div>
        <div>物件名：{{this.$route.params.name}}</div>
      </div>
      <vue-json-to-csv v-if="this.roleId!==3" :json-data="filteredRecords" :labels="labels">
        <v-btn class="mr-4 hidden-sm-and-down" :disabled="disabled">CSV出力</v-btn>
      </vue-json-to-csv>
    </div>
    <v-select density="compact" label="駐車場名" v-model="selectedParking" :items="parkings" return-object hide-details></v-select>
    <v-row dense no-gutters>
      <v-col cols="6">
        <v-text-field label="4桁ナンバー" v-model="carNumber" hide-details :append-icon="carNumber !== '' ? 'mdi-close' : null" @click:append="carNumber = ''"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-checkbox v-model="hideChecked" label="対応済みを隠す"></v-checkbox>
      </v-col>
    </v-row>
    <v-data-table
      v-if="this.filteredRecords.length!==0"
      :headers="headersArray"
      :items="filteredRecords"
      hide-default-footer
      fixed-header
      item-key="id"
      class="mt-7 elevation-1"
      mobile-breakpoint=100
      :items-per-page="itemsPerPage"
      :sort-by="['PARKING_ID', 'CAR_INFO']"
      >
        <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id" @click.stop="openDialog(item)" :style="`background: ${item.checked ? '#AAA' : '#FFF'};`">
            <td width="20%">
              <p class="details" v-if="selectedParking.value === 0">
                {{item.PARKING_NAME}}
              </p>
              <v-img
                max-height="100"
                max-width="100"
                :src="item.IMAGE_DATA_OBJECT_PATH"
              ></v-img>
            </td>
            <td width="30%">
              <p class="number-plate">
                {{item.CAR_INFO}}
              </p>
            </td>
            <td>
              <p class="details">
                <b>入庫時間：</b>{{item.ENTRANT}}<br>
                <b>未精算回数：</b>{{item.UNPAY_COUNT}}回<br>
                <b>未払い請求金額合計：</b>{{item.UNPAY_TOTAL | separateDigit}}円
              </p>
            </td>
          </tr>
        </tbody>
        <v-dialog v-model="dialog" max-width="800px">
          <v-card>
            <v-card-title class="headline title">
              <h3>{{currentRecord.CAR_INFO}}</h3>
            </v-card-title>
            <v-img :src="currentRecord.IMAGE_DATA_OBJECT_PATH" class="m-3"></v-img>
            <h4 class="ml-5">未精算回数：{{currentRecord.UNPAY_COUNT}}回</h4>
            <h4 class="ml-5">未精算金額合計：{{currentRecord.UNPAY_TOTAL | separateDigit}}円</h4>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-checkbox
                v-model="unpaidRecords[currentIndex].checked"
                label="対応済み"
                @click="checkboxClickEvent(currentIndex)"
              ></v-checkbox>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>

</template>

<script type="ts">
import Vue from "vue";
import * as axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueJsonToCsv);

export default Vue.extend({
  name: "ResultUnpaidRecordsStaying",
  components: { VueJsonToCsv },
  data: () => ({
    roleId: Number,
    unpaidRecords: [],
    disabled: true,
    labels: {
      PARKING_NAME:   { title: "駐車場名" },
      ENTRANT:        { title: "入庫日時" },
      CAR_INFO:       { title: "車両情報" },
      UNPAY_COUNT:    { title: "未精算回数" },
      UNPAY_TOTAL:    { title: "未払い請求金額合計" }
    },
    dialog: false,
    currentRecord: {},
    currentIndex: 0,
    headersArray: [
          { text: '駐車場名', value: 'PARKING_ID' },
          { text: '車両情報', value: 'CAR_INFO', sort: (a, b) => a.substr(-4).replace(/\*/g, '') - b.substr(-4).replace(/\*/g, '') },
          { text: 'その他情報', value: 'UNPAY_TOTAL' },
    ],
    itemsPerPage: 1000,
    selectedParking: { title: 'すべて', value: 0 },
    carNumber: "",
    hideChecked: false,
  }),
  filters: {
    separateDigit: function (value) {
      if (!value) return ''
      value = value.toLocaleString()
      return value
    }
  },
  computed: {
    filteredRecords() {
      return this.unpaidRecords.filter(record =>
        (this.selectedParking.value === 0 || record.PARKING_ID === this.selectedParking.value) &&
        (this.carNumber === "" || record.CAR_INFO?.slice(-4)?.includes(this.carNumber)) &&
        (this.hideChecked === false || record.checked === false) &&
        record.UNPAY_TOTAL > 0
      )
    },
    parkings() {
      const parkings = []
      this.unpaidRecords.forEach(p => {
        if (!parkings.some(pp => pp.value === p.PARKING_ID)) {
          parkings.push({ text: p.PARKING_NAME, value: p.PARKING_ID })
        }
      })
      return [{ text: 'すべて', value: 0 }].concat(parkings)
    },
    getToday() {
      const today = new Date()
      return today.getFullYear() + "/" +  (today.getMonth() + 1)  + "/"+ today.getDate()
    }
  },
  methods: {
    async getUnpaidInfo() {
      this.$vloading.show();
      const formData = { id: this.$route.params.id };
      axios.post('/api/get_upaid_records_staying_now', formData
      )
        .then(res => {
          const checkedList = this.checkedList()
          const usedCheckedList = []
          this.unpaidRecords = res.data.map(datum => {
            if (checkedList.includes(datum.INOUT_NO)) {
              usedCheckedList.push(datum.INOUT_NO)
            }
            return {
              ...datum,
              checked: checkedList.includes(datum.INOUT_NO)
            }
          })
          // ローカルストレージが溢れないよう、API返却値に含まれないチェック記録は削除する
          this.saveCheckedList(usedCheckedList)
          if (this.unpaidRecords.length == 0) {
            this.disabled = true;
            alert("対象のデータがありませんでした。");
            this.$vloading.hide();
            return;
          }
          this.disabled = false;
          this.$vloading.hide();
        })
        .catch(err => {
          this.$vloading.hide();
          alert("データの取得に失敗しました。");
          console.log(err);
        });
    },
    openDialog(record) {
      this.currentRecord = record
      this.unpaidRecords.map((r, i) => { if (r.INOUT_NO === record.INOUT_NO) this.currentIndex = i })
      this.dialog = true
    },
    checkboxClickEvent(index) {
      let checkedList = this.checkedList()
      const inoutNo = this.unpaidRecords[index]?.INOUT_NO
      const checked = this.unpaidRecords[index]?.checked
      if (checked === true) {
        if (!checkedList.includes(inoutNo)) checkedList.push(inoutNo)
      } else {
        checkedList = checkedList.filter(n => n !== inoutNo)
      }
      this.saveCheckedList(checkedList)
      this.dialog = false
    },
    checkedList() {
      const all = localStorage.resultUnpaidRecordsStaying ? JSON.parse(localStorage.resultUnpaidRecordsStaying) : {}
      return all[`${this.$route.params.id}`] || []
    },
    saveCheckedList(list) {
      const all = localStorage.resultUnpaidRecordsStaying ? JSON.parse(localStorage.resultUnpaidRecordsStaying) : {}
      all[`${this.$route.params.id}`] = list
      localStorage.resultUnpaidRecordsStaying = JSON.stringify(all)
    },
  },
  mounted() {
    this.getUnpaidInfo(); //serversMixinで定義
    if (localStorage.user){
      this.roleId = JSON.parse(localStorage.user).role_id;
    } else if (localStorage.user===undefined) {
      this.$router.push("/");
    } 
  },
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&family=Sawarabi+Gothic&display=swap');

.result-unpaid-records { color: #616161 !important; font-family: 'Sawarabi Gothic', sans-serif; margin: 0;}

.details { color: #616161 !important; font-family: 'Sawarabi Gothic', sans-serif; margin: 0; font-size: 0.7rem;}
.number-plate { color: #616161 !important; font-family: 'Sawarabi Gothic', sans-serif; margin: 0; font-size: 1.3rem;}

h3 { color: #616161; }

h4 { color: #616161; }

td { padding: 10px !important;}

.desc-area { justify-content: space-between; }
</style>
