
































import Vue from 'vue'
import * as axios from 'axios'
import VueJsonToCsv from 'vue-json-to-csv'
import SelectServerList from "../components/forms/SelectServerList.vue";
import QueryTextArea from "../components/forms/QueryTextArea.vue";
import serversMixin from "../mixins/serversMixin";

Vue.use(VueJsonToCsv)

export default Vue.extend({
  mixins: [serversMixin],
  components: { VueJsonToCsv, SelectServerList, QueryTextArea },
  data: () => ({
    dateRange: {
      date: new Date().toISOString().substr(0, 10),
      dates: []
    },
    valid: true,
    rules: ["required"],
    invalid: false,
    disabled: true,
    result: [],
    // labels: {
    //           result:        { title: '結果' }
    //         },
  }),
  computed: {
    dateRangeText () { return this.dateRange.dates.join(' ~ ') },
  },
  methods: {
    selectedServers(arr) { this.server.selectedList = arr },
    async submit () {
      this.$vloading.show();
      // this.$root.developerName → app/javascript/packs/index.jsで指定
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_query_results`  // commons/query_tools#send_query
      const formData = {
        querytexts:   this.querytext,
        servers:   this.server.selectedList,
      }
      const headers = { "Content-Type": "application/json" }
      return await axios.post(url, formData, { headers: headers,responseType: 'json' })
      .then((res) => {
        console.log(res.data)
        this.result = res.data
        if (this.result.length == 0) {
          this.disabled = true
          alert("対象のデータがありませんでした。")
          this.$vloading.hide()
          return
        }
        this.disabled = false
        this.$vloading.hide()
      })
      .catch(err => {
        this.$vloading.hide()
        alert("データの取得に失敗しました。")
      })
    },
  },
  mounted() {
    this.getServerInfo(); //serversMixinで定義
  },
})
