
























































































































































































import Vue from "vue";
export default Vue.extend({
  props: {
    today:                                {},
    userName:                             {},
    version:                              {},
    researchTypeFlg:                      {},
    researchStepFlg:                      {},
    researchEndFlg:                       {},
    escalationFlg:                        {},
    problemFlg:                           {},
    hoseiImgUrl:                          {},
    researchParkingInformation:           {},
    type3SeisankiCheckCompletedLists:     {},
    type3CameraCheckCompletedLists:       {},
    type3HoseiCheckCompletedListsHosei:   {},
    type3HoseiCheckCompletedListsHoseiSP: {},
    type3HoseiCheckCompletedListsDaburi:  {},
    type3SurroundingsCheckStart:          {}
  },
})
