

















































































































































































import Vue from "vue";
interface VehicleInformation {
  headers: any[];
  csv: string;
}
export default Vue.extend({
  data(): VehicleInformation { 
    return { 
      headers: [
        { text: '入庫', value: 'entrantTime' },
        { text: '出庫', value: 'exitTime' },
        { text: 'ナンバー', value: 'fullNumber' },
        { text: '状態', value: 'vehicleStatusId' },
        { text: '履歴', value: 'history' },
        { text: '画像', value: 'image' }
      ],
      csv: ""
    };
  },
  props: {
    searchData: { },
  },
  methods:{
    // CSV出力
    sendCsv(){
      // header作成
      this.csv = ""
      this.csv += "駐車場名,id,入庫日,入庫時刻,出庫日,出庫時刻,車両番号,状態,手入力,利用時間, 請求金額, 現金支払い, ICカード支払い, クレジットカード支払い, QR決済, バーコード使用枚数, 入口, 出口\n"
      // 合計作成
      let parkingTimeTotal = 0
      let acountTotalTotal = 0
      let payCashTotal = 0
      let payIcCardTotal = 0
      let payCreditTotal = 0
      let payQrTotal = 0
      let sheetsTotal = 0
      for (const elem of this.searchData) {
        parkingTimeTotal += elem.parkingTime
        acountTotalTotal += elem.accountTotal
        payCashTotal += elem.payCash
        payIcCardTotal += elem.payIcCard
        payCreditTotal += elem.payCredit
        payQrTotal += elem.payQr
        sheetsTotal += elem.sheets
      }
      this.csv += "合計,,,,,,,,,"
      this.csv += parkingTimeTotal + "," + acountTotalTotal + "," + payCashTotal + "," + payIcCardTotal + "," + payCreditTotal + "," + payQrTotal + "," + sheetsTotal
      this.csv += ",,\n"
      // 情報作成
      for (const elem of this.searchData) {
        this.csv +=
        // 駐車場名
        elem.parkingName + "," +
        // parking_id
        elem.parkingId + "," +
        // 入庫日,入庫時刻
        new Date(elem.entrantDte).toISOString().substr(0, 10) + "," +
        elem.entrantTime + ","
        // 出庫日,出庫時刻
        if (elem.exitTime != null) {
          this.csv += new Date(elem.exitTime).toISOString().substr(0, 10) + ","
          this.csv += elem.exitTime + ","
        } else {
          this.csv += "" + ","
          this.csv += "" + ","
        }
        // 車両番号
        this.csv += elem.place + " " + elem.classNumber + " " + elem.kana + " " + elem.carNumber + ","
        // 状態
        if (elem.vehicleStatusId == 0) {
          this.csv += "入庫中" + ","
        } else if (elem.vehicleStatusId == 1) {
          this.csv += "未精算出庫" + ","
        } else if (elem.vehicleStatusId == 2) {
          this.csv += "出庫済み" + ","
        } else {
          this.csv += "不明" + ","
        }
        // 手入力
        if (elem.manWorkFlg == 0) {
          this.csv += "" + ","
        } else {
          this.csv += "レ" + ","
        }
        // 利用時間
        this.csv += elem.parkingTime + ","
        // 請求金額
        this.csv += elem.accountTotal + ","
        // 現金支払い
        this.csv += elem.payCash + ","
        // ICカード支払い
        this.csv += elem.payIcCard + ","
        // クレジット支払い
        this.csv += elem.payCredit + ","
        // QR決済
        this.csv += elem.payQr + ","
        // サービス券使用枚数
        this.csv += elem.sheets + ","
        // 入口
        if (elem.entranceName == null) {
          this.csv += "" + ","
        } else {
          this.csv += elem.entranceName + ","
        }
        // 出口
        if (elem.exitName == null) {
          this.csv += ""
        } else {
          this.csv += elem.exitName
        }
        // 次の行へ
        this.csv += "\n"
      }
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);                                 // 文字化け対応
      const url = URL.createObjectURL(new Blob([bom,this.csv], {type: 'text/csv'}));  // ここからcsvダウンロード処理
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'コモンズ車両情報.csv');
      document.body.appendChild(link);
      link.click();
    }
  }
})
