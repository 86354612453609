




























































import Vue from 'vue'
import * as axios from "axios";

interface ParkingAvailabilityReports {
  storeId: number | null,
  selectDate: string,
  datePicker: boolean,
  stoers: any[]
}

const dt = new Date()
const lastMonth = new Date(dt.getFullYear(), dt.getMonth(), 1)

export default Vue.extend({
  name: 'ParkingAvailabilityReports',
  data (): ParkingAvailabilityReports {
    return {
      storeId: null,
      selectDate: lastMonth.toISOString().substr(0, 7),
      datePicker: false,
      stoers: []
    }
  },
  computed: {
    store: {
      get (): {[index: string]: string | number | boolean | null } {
        return this.$store.getters['store/stores']
      }
    }
  },
  methods: {
    // セレクトリストの表示用
    displayText (item) {
      return item.name
    },
    async getStoreInfos (): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/stores`;
      return await axios
      .get(url)
      .then(res => {
        this.stoers = res.data;
        console.log(this.stoers)
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
    async getCsvRecord (): Promise<void> {
      this.$vloading.show()
      try {
        return await axios.get(
          `${process.env.VUE_APP_API_URL_BASE}/api/parking_availability_reports?store_id=${this.storeId}&target_month=${this.selectDate}`,
          { responseType: 'blob' }
        )
          .then(res => {
            if (!res) {
              throw new Error('データがありませんでした。')
            }
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([res.data]))
            link.setAttribute('download', 'output_record.xlsx')
            document.body.appendChild(link)
            link.click()
          })
          .catch(e => {
            alert( 'データ取得に失敗しました')
          })
      } catch (error) {
        console.log(error)
        alert(error.message || 'データ取得に失敗しましたB。')
      } finally {
        this.$vloading.hide();

      }
    }
  },
  mounted () {
    this.getStoreInfos()
  }
})
