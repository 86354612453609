








































































































































































import Vue from "vue";
import * as axios from "axios";
import SelectServerList from "../components/forms/SelectServerList.vue";
import serversMixin from "../mixins/serversMixin";

export default Vue.extend({
  mixins: [serversMixin],
  components: { SelectServerList },
  data: () => ({
    valid: true,
    formatType: "all",
    sortOrder: "unpaid_count",
    dateRange: {
      date: new Date().toISOString().slice(0, 10),
      dates: []
    },
    freeMinutes: 0,
    unpaidCount: 5,
    unpaidAmount: 0,
    greaterThanOrEqualToZero: [
      (v: any) => v >= 0 && v == Math.floor(v) || '0以上の整数を入力してください',
    ],
    downloadModal: false,
    downloadList: [],
    downloadHeaders: [
      { text: '対象物件', value: 'serverNote' },
      { text: '出力条件', value: 'formatType' },
      { text: '期間', value: 'enteredAt' },
      { text: 'その他の条件', value: 'freeMinutes' },
      { text: '作成開始時刻', value: 'requiredAt' },
      { text: 'アクション', value: 'actions' },
    ],
    page: 1,
    itemsPerPage: 10,
    getUnfairVehicleDownloadsLoading: false,
    downloadProgress: [],
  }),
  computed: {
    formIsValid() {
      return this.greaterThanOrEqualToZero.every(
        rule => [this.freeMinutes, this.unpaidCount, this.unpaidAmount].every(value => rule(value) === true)
      ) && this.server.selectedList.length > 0 && this.dateRange.dates.length > 0
    },
    dateRangeText () { return this.dateRange.dates.join(' ~ ') },
    pageCount(): number {
      return Math.ceil(this.downloadList.length / this.itemsPerPage)
    },
  },
  methods: {
    selectedServers(arr: string): void { this.server.selectedList = arr },
    submit () {
      this.$vloading.show();
      const data = {
        unfairVehicle: {
          serverIds: this.server.selectedList,
          formatType: this.formatType,
          sortOrder: this.sortOrder,
          enteredAt: this.dateRange.dates.slice(0, 1)[0],
          exitedAt: this.dateRange.dates.slice(-1)[0],
          freeMinutes: this.freeMinutes,
          unpaidCount: this.unpaidCount,
          unpaidAmount: this.unpaidAmount,
        }
      }
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unfair_vehicles`
      axios.post(url, data, { headers: this.headers })
      .then((res) => {
        const json = res.data
        alert(json.message)
        this.getUnfairVehicleDownloads()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    getUnfairVehicleDownloads() {
      this.getUnfairVehicleDownloadsLoading = true
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unfair_vehicles_list`
      axios.get(url)
      .then((res) => {
        const json = res.data
        this.downloadList = json
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
        this.getUnfairVehicleDownloadsLoading = false
      });
    },
    download(key, filename) {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unfair_vehicles_download?object_key=${key}`
      this.downloadProgress.push(key)
      axios.get(url, { responseType: 'arraybuffer' })
      .then(res => {
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.downloadProgress = this.downloadProgress.filter(v => v !== key)
      });
    },
    openDownloads() {
      this.getUnfairVehicleDownloads()
      this.downloadModal = true
    }
  },
  mounted() {
    this.getServerInfo(); //serversMixinで定義
    this.getUnfairVehicleDownloads()
  }
});
