


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    selectParking:                        {},
    startDate:                            {},
    endDate:                              {},
    inputNumber:                          {},
    researchTypeFlg:                      {},
    researchStepFlg:                      {},
    researchEndFlg:                       {},
    problemFlg:                           {},
    type2NumberCheckDisplayLists:         {},
    type2NumberCheckPagination:           {},
    type2NumberCheckCompletedLists:       {},
    type2NumberCheckPaginationLength:     {},
    type2CameraCheckLists:                {},
    type2CameraCheckCompletedLists:       {},
    cameraCheckHeaders:                   {},
    footerProps:                          {},
    cameraCheckCompletedHeaders:          {},
    hoseiImgUrl:                          {},
    type2HoseiCheckLists:                 {},
    type2HoseiCheckCompletedListsHosei:   {},
    type2HoseiCheckCompletedListsHoseiSP: {},
    type2HoseiCheckCompletedListsDaburi:  {},
    type2SurroundingsCheckLists:          {},
    type2SurroundingsCheckStart:          {},
    type2SurroundingsCheckEnd:            {},
    numberCheckHeaders:                   {},
    hoseiCheckHeaders01:                  {},
    hoseiCheckHeaders02:                  {},
    hoseiCheckHeaders03:                  {},
  },

  computed: {
    type2NumberCheckCompletedListsValue:     { get () { return this.type2NumberCheckCompletedLists },     set (newVal) { if (this.type2NumberCheckCompletedLists !== newVal)     this.$emit('ChangeType2NumberCheckCompletedLists', newVal) }},
    type2NumberCheckPaginationValue:     { get () { return this.type2NumberCheckPagination },     set (newVal) { if (this.type2NumberCheckPagination !== newVal)     this.$emit('ChangeType2NumberCheckPagination', newVal) }},
    type2CameraCheckCompletedListsValue:     { get () { return this.type2CameraCheckCompletedLists },     set (newVal) { if (this.type2CameraCheckCompletedLists !== newVal)     this.$emit('ChangeType2CameraCheckCompletedLists', newVal) }},
    type2HoseiCheckCompletedListsHoseiValue:   { get () { return this.type2HoseiCheckCompletedListsHosei },   set (newVal) { if (this.type2HoseiCheckCompletedListsHosei !== newVal)   this.$emit('ChangeType2HoseiCheckCompletedListsHosei', newVal) }},
    type2HoseiCheckCompletedListsHoseiSPValue: { get () { return this.type2HoseiCheckCompletedListsHoseiSP }, set (newVal) { if (this.type2HoseiCheckCompletedListsHoseiSP !== newVal) this.$emit('ChangeType2HoseiCheckCompletedListsHoseiSP', newVal) }},
    type2HoseiCheckCompletedListsDaburiValue:  { get () { return this.type2HoseiCheckCompletedListsDaburi },  set (newVal) { if (this.type2HoseiCheckCompletedListsDaburi !== newVal)  this.$emit('ChangeType2HoseiCheckCompletedListsDaburi', newVal) }},
    type2SurroundingsCheckStartValue:          { get () { return this.type2SurroundingsCheckStart },          set (newVal) { if (this.type2SurroundingsCheckStart !== newVal)          this.$emit('ChangeType2SurroundingsCheckStart', newVal) }},
    type2SurroundingsCheckEndValue:            { get () { return this.type2SurroundingsCheckEnd },            set (newVal) { if (this.type2SurroundingsCheckEnd !== newVal)            this.$emit('ChangeType2SurroundingsCheckEnd', newVal) }},
  },

  methods: {
    normalEnd(number): void{
      this.$emit('normal-end', number)
    },
    type02PaginationMove(number): void{
      this.$emit('type02-pagination-move',number)
    },
    backToStep2(): void{
      this.$emit('back-to-step2', 3, 0, [], [], [], "")
    },
    // 調査終了
    reload(): void{
      document.location.reload()
    },
    // 補正画像取得
    hoseiImgview(url): void{
      this.$emit('hosei-img-view', url)
    },

    // 出庫のみ [step2]カメラ管制調査へ移行
    async type02CameraCheckStartBtn(eventFlg): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_camera_check`;
      const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
      return await axios
      .post(url, formData.params)
      .then(res => {
        this.$emit('type02-camera-check-start', res.data, 2, eventFlg)
        this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

    // 出庫のみ [step3]補正内容調査へ移行
    async type02HoseiCheckStartBtn(): Promise<void>{
      this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_hosei_check`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit('type02-hosei-check-start', res.data, 3)
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

    // 出庫のみ [step4]撮影環境調査へ移行
    async type02SurroundingsCheckStartBtn(): Promise<void>{
      this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_surroundings_check`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit('type02-surroundings-check-start', res.data, 4)
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

  },

})
