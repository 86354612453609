





















































































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";

interface Data {
  result: any[];
  beforeChange: any[];
  beforeService: any[];
  stepReset: any[];
  dayPointOpen: any[];
  absoluteMaxToll: any[];
  unpaidInvoicesFlg: any[];
  valid:boolean;
}

export default Vue.extend({

  data(): Data {
    return {
      beforeChange: [],
      beforeService: [],
      stepReset: [],
      dayPointOpen: [],
      absoluteMaxToll: [],
      unpaidInvoicesFlg: [],
      result:[],
      valid:false,
    }
  },

  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getSettings();
      }
    }
  },

  mounted() {
    this.getSettings()
  },

  methods: {
    async send(
      id:number, 
      beforeChange:number,
      beforeService:number,
      stepReset:number,
      dayPointOpen:number,
      absoluteMaxToll:number,
      unpaidInvoicesFlg:number
    ): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!window.confirm('更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/check_bases/${id}`;
      const formData = {
        serverId: this.selectServer,
        BEFORE_CHARGE: beforeChange,
        BEFORE_SERVICE: beforeService,
        STEP_RESET: stepReset,
        DAY_POINT_OPEN: dayPointOpen,
        ABSOLUTE_MAX_TOLL :absoluteMaxToll,
        UNPAID_INVOICES_FLG: unpaidInvoicesFlg
      };
      return await axios
      .patch(url, formData)
      .then(() => {
        alert("更新しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("更新に失敗しました");
      })
      .finally(() => {
        this.getSettings()
        this.$vloading.hide();
      });
    },
    async getSettings(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/check_bases`;
      return await axios
      .get(url, {params: { id: this.selectServer }} )
      .then((res:any) => {
        console.log(res.data)
        this.result = [];
        this.beforeChange = [];
        this.beforeService = [];
        this.stepReset = [];
        this.dayPointOpen = [];
        this.absoluteMaxToll = [];
        this.unpaidInvoicesFlg = [];
        this.result = res.data.flat()
        this.result.forEach(item => {
          this.beforeChange.push(item.BEFORE_CHARGE);
          this.beforeService.push(item.BEFORE_SERVICE);
          this.stepReset.push(item.STEP_RESET);
          this.dayPointOpen.push(item.DAY_POINT_OPEN);
          this.absoluteMaxToll.push(item.ABSOLUTE_MAX_TOLL);
          this.unpaidInvoicesFlg.push(item.UNPAID_INVOICES_FLG);
        });
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },

  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
