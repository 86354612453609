



































































import axios from "axios";
export default {
  props: {
    unpaidShareFlg: {},
    getUnpaidShareFlgLoading: {},
    selectParkings: {},
  },
  methods: {
    async update(flg:boolean): Promise<void> {
      if (!window.confirm(`${this.unpaidShareFlg === false ? '登録' : '登録を解除'}しますか？`)) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers/${this.selectParkings}`;
      const formData = { unpaidShareFlg: flg };
      const config = { headers: { 'Content-Type': 'application/json' }, responseType: 'json' };
      return await axios
      .patch(url, formData, config)
      .then(res => {
        console.log(res);
        alert("更新しました");
        this.$emit('reload');
      })
      .catch(error => {
        alert("更新に失敗しました");
        console.log(error);
      });
    },
    // 設定の取得
    reload () {
    console.log(this.unpaidShareFlg)
      this.$emit('reload')
    },
  },
}
