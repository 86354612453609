

































































































































































































import Vue from "vue";
import axios from "axios";

interface SalesForecast {
  formulaArray: object;
  selectedFormula: number;
  groups: any[];
  selectedGroups: number;
  parkingSpace: number | null;
  result: object | null;
  resultDialog: boolean;
  parkingName: string;
  valid: boolean;
}

export default Vue.extend({
  data(): SalesForecast {
    return {
      formulaArray: [ { id: 0, text: 'Aの年間平均売り上げ(2023年度) ÷ Aの平均車室数 × Bの車室数' }],
      selectedFormula: 0,
      groups:[],
      selectedGroups:0,
      parkingSpace:null,
      result: null,
      resultDialog: false,
      parkingName: "",
      valid:  true,
    }
  },

  methods: {
    // 物件グループの取得
    async getGroups(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/groups`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.groups = res.data;
      })
      .catch(error => {
        console.log("取得失敗！")
      });
    },
    // 売り上げ予測の出力
    async postSalesForecast(group, formula, space): Promise<void> {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        this.result = null
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/groups/sales_forecast`;
        const formData = { params: {
          groupId: group,
          formulaId: formula,
          parkingSpace: space,
        }};
        return await axios
        .post(url, formData.params)
        .then(res => {
          console.log(res.data.result);
          this.result = res.data.result;
          this.resultDialog = true;
        })
        .catch(error => {
          console.log("取得失敗！")
        })
        .finally(() => {
          this.$vloading.hide();
        });
      }
    },
  },

  mounted() {
    this.getGroups();
  }
})
