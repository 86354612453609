





























import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    currentServer: {
      type: Number,
      default: 0
    },
    parkingName: {
      type: String,
      default: ""
    },
    propAddress: {
      type: String,
      default: ""
    },
    imageUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    address: {
      get: function () { return this.propAddress }
    },
    serverName: {
      get: function () { return this.parkingName }
    },
    previewImageUrl: {
      get: function () { return this.imageUrl}
    }
  },
  methods: {
    async submit(): Promise<void> {
      const url      = `${process.env.VUE_APP_API_URL_BASE}/api/servers/${this.currentServer}/upload_parking_fee_list`;
      const file     = this.$refs.preview.$refs.input.files[0]
      const formData = new FormData()
      const config   = { headers: { 'Content-Type': 'multipart/form-data' } }
      formData.append("file[image]", file)
      formData.append("file[file_name]", file.name)
      formData.append("file[content_type]", file.type)
      return await axios
        .post(url, formData, config)
        .then(res => {
          console.log(res.data)
          this.$emit("closeModal");
          alert("アップロードに成功しました。");
        })
        .catch(error => {
          console.log(error)
          alert("アップロードに失敗しました！");
        });
    },
    previewImage() {
      const imageFile = this.$refs.preview.$refs.input.files[0]
      this.$emit("showPreview", URL.createObjectURL(imageFile))
    },
    closeModal() {
      this.$emit("closeModal")
    }
  }
})
