

























































































import Vue from "vue";
// import axios from "axios";
// import store from "/front/src/store/WebUI.ts";
import UsageHistoryList from "@/components/WebUI/VehicleInformations/UsageHistoryList.vue";
import DeleteHistoryList from "@/components/WebUI/VehicleInformations/DeleteHistoryList.vue";
import UnpaidList from "@/components/WebUI/VehicleInformations/UnpaidList.vue";
import PaidList from "@/components/WebUI/VehicleInformations/PaidList.vue";
import ReadCommon from "@/components/WebUI/VehicleInformations/ReadCommon.vue";

export default Vue.extend({
  components: {
    UsageHistoryList,
    DeleteHistoryList,
    UnpaidList,
    PaidList,
    ReadCommon
  },
  props: {
    result:{},
    successCarNumber:{},
    selectServer:{}
  },
  methods: {
    reload() {
      const number = this.successCarNumber.split(" ");
      this.$emit('reload', number[0], number[1], number[2], number[3]);
    }
  },
});
