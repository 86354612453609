






















































































































import Vue from "vue";
import axios from "axios";
import TitleBar from '@//components/Examination/TitleBar/index.vue';
interface Params {
  servers: any;
  selectParking: number | null;
  dates: any;
  panel: number | null;
  switch1:boolean;
  switch2:boolean;
  valid: boolean;
  dialog:boolean;
  menuTitle: string;
  result: any;
}
export default Vue.extend({
  data(): Params {
    return {
      servers: [],
      dates: [],
      panel: 0,
      selectParking: null,
      switch1:false,
      switch2:false,
      valid: false,
      dialog: false,
      menuTitle:"？あり車両一覧",
      result:[]
    }
  },
  components: {
    TitleBar
  },
  computed: {
    // datesの日付がindex[0](始点)とindex[1]（終点）で、逆転していた場合はその二つを入れ替える
    sortedDates() {
      const datesCopy:any = [...this.dates];
      if (datesCopy[0] && datesCopy[1] && new Date(datesCopy[0]) > new Date(datesCopy[1])) {
        const temp = datesCopy[0];
        datesCopy[0] = datesCopy[1];
        datesCopy[1] = temp;
      }
      return datesCopy;
    }
  },
  methods: {
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      })
      ;
    },
    async send(selectParking:number, sortedDates:any, switch1:boolean, switch2:boolean): Promise<void> {
      this.$vloading.show();
      function addOneDay(dateStr:string) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1);
        return date.toISOString().split('T')[0];
      }
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/commons/miss_recognizeds`;
      const formData = {
        params: {
          server: selectParking,
          start: sortedDates[0],
          end: addOneDay(sortedDates[1]),
          notNumberFlg: switch1,
          searchType: switch2,
        }
      }
      return await axios
      .get(url, formData)
      .then(res => {
        this.result = res.data;
        this.$vloading.hide()
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide()
      })
      .finally(() => {
        this.$vloading.hide()
      })
      ;
    },
    // 日付を 'yyyy-mm-dd' の形式にフォーマットする関数
    formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    const today = new Date();
    const formattedDate = this.formatDate(today);
    this.dates[0] = formattedDate;
    this.dates[1] = formattedDate;
    this.sortedDates[0] = formattedDate;
    this.sortedDates[1] = formattedDate;
    this.getServerInfo();
  }
})
