











































































import Vue from "vue";
import axios from "axios";

interface Params {
  startDate: string;
  endDate: string;
  keyword: string;
  headers: any[] | null;
  result: any[]| null;
  copyRecord: any[];
  valid: boolean;
}

export default Vue.extend({

  data(): Params {
    return {
      startDate: "",
      endDate: "",
      keyword: "",
      headers: null,
      result: null,
      copyRecord: [],
      valid: true
    }
  },

  computed: {
    // テーブルのレコードを選択するためにレコードにインデックス（一意の値）を追加
    indexedItems () {
      return this.result.map((item, index) => ({
        index: index,
        ...item
      }))
    }
  },

  methods: {
    // SQL実行
    async send(startDate:string, endDate:string, keyword:string): Promise<void>{
      if (!this.$refs.form.validate()) return
      this.result = [];
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/sql_select/sql14`;
      const formData = { params:
        {
          startDate: startDate,
          endDate: endDate,
          keyword: keyword,
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        if (res.data.length == 0) return alert("検索結果がありませんでした");
        this.copyRecord = [];
        this.result = res.data;
        const headerAry = [];
        for (const element of Object.keys(res.data[0])) {
          headerAry.push({text: element, value: element })
        };
        this.headers = headerAry;
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // コピー
    copyToClipBoard(): void{
      if(this.copyRecord.length != 0) {
        const text = document.getElementById('spl14copy');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else {
        alert('コピー対象を選んでください');
      }
    },
  },
})
