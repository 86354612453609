<template>
  <section class="login">
    <!-- ログイン前の画面は強制余白を解除するためにwrapperで囲う。詳細はcssで。 -->
    <div id="login-card-wrapper">
      <!-- ログインカードタイトル -->
      <v-card class="ma-auto pa-3" id="login-card" elevation="24">
        <h1 class="mb-2 pl-2">Commons Management</h1>
        <v-divider></v-divider>
        <!-- ヘルプ -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="float-right mt-5 mr-5" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span>ログインができない場合は、<br>お手数ですが、web開発部まで連絡をお願いします。</span>
        </v-tooltip>
        <!-- ログインフォームタイトル -->
        <v-card-title>
          <b>サインイン</b>
        </v-card-title>
        <!-- ログインフォームテキスト -->
        <v-card-text class="ml-1">
          初期設定はWebUIのIDとパスワードで設定されています。
        </v-card-text>
        <v-container fluid>
          <v-row>
            <!-- ID -->
            <v-col cols="10">
              <v-text-field
                label="ID"
                outlined
                dense
                prepend-icon="mdi-account"
                v-model="userName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- パスワード -->
            <v-col cols="10">
              <v-text-field
                label="password"
                outlined
                dense
                prepend-icon="mdi-key"
                v-model="password"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- ログイン失敗アラート -->
          <v-alert
            type="error"
            border="left"
            dense
            outlined
            class="mb-10"
            v-show="loginError"
          >
          ログインに失敗しました。<br>
          <span class="text-caption">パスワード等、誤りがないかもう一度確認してください。</span>
          </v-alert>
          <!-- ボタン -->
          <v-row justify="end">
            <v-btn class="mr-5 mb-4" color="orange" tile x-large @click="signIn" dark><b>login</b></v-btn>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </section>
</template>
<script>
  export default {
    name: "login",
    data() {
      return {
        userName: "",
        password: "",
        isTag1Active: true,
        passwordShow: false,
        loginError: false,
      };
    },
    mounted (){
      if (localStorage.user || localStorage['x-csrf-token']){
        this.$store.dispatch("clearUserObject");
      }
    },
    methods: {
      signIn() {
        this.loginError = false;
        this.$store.dispatch("signIn", {
          userName: this.userName,
          password: this.password
        })
        .then(() => {
          const basePath = process.env.VUE_APP_FRONT_BASE_PATH;
          const user = localStorage.user;
          const roleId = JSON.parse(user).role_id;
          if (roleId !==3) { // 協力会社以外の初期ページ
            location.href=`${basePath}home`;
          } else { // 協力会社の初期ページ
            const basePath = process.env.VUE_APP_FRONT_BASE_PATH;
            location.href=`${basePath}unpaid-records-staying`; 
          }
        })
        .catch(error => {
          console.log(error);
          this.loginError = true;
        });
      }
    }
  }
</script>
<style scope>
/*
  App.vueにて.contents-wrapperというcssがあり、router-viewで表示したコンテンツは、左に強制的な空白を作られる。
  以下cssは、その強制的に空白を排除して、ログインカードを中央に持ってくるために作成したcssです。 2022/08/25 yasui
*/
  #login-card-wrapper {
    margin: 0 0 0 -270px;
    position: relative;
    height:100vh;
    white:100vh;
  }
  @media screen and (max-width: 960px) {
    #login-card-wrapper { 
      margin: 0 0 0 0px;
      position: relative;
      height:100vh;
      white:100vh;
    }
  }
  #login-card {
    width:500px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
</style>
