


























































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    selectParking: {}, // 選択した物件（id）
    startDate:     {}, // 始点(日付)
    endDate:       {}, // 終点(数値)
    inputNumber:   {}, // ナンバー(数値)
    NumberCheckLists:                   {},
    desserts:                           {},
    numberImgCheckHeaders:                 {},
    footerProps:                        {},
    type2NumberCheckPaginationPageSize: {},
    type2NumberCheckPagination:         {},
  },

  computed: {
    NumberCheckListsValue: { get () { return this.NumberCheckLists }, set (newVal) { if (this.NumberCheckLists !== newVal) this.$emit('ChangeNumberCheckLists', newVal) }},
  },

  methods: {
    // 入出庫あり 調査開始
    async nyusyukkoari(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_seisanki_check`;
      const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
      return await axios
      .post(url, formData.params)
      .then(res => {
      this.$emit(
                  'web-type01-start-event', // 親componentに記述したイベント名
                  res.data,
                  0, // StartFlg
                  1, // TypeFlg
                  1  // StepFlg
                )
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },
    // 出庫のみ調査開始
    syukkonomi(): void{
      this.$vloading.show();
      this.$emit(
                  'web-type02-start-event', // 親componentに記述したイベント名
                  this.NumberCheckLists,
                  this.NumberCheckLists.slice(this.type2NumberCheckPaginationPageSize*(this.type2NumberCheckPagination - 1), this.type2NumberCheckPaginationPageSize*(this.type2NumberCheckPagination)),
                  this.type2NumberCheckPaginationLength = Math.ceil(this.NumberCheckLists.length/this.type2NumberCheckPaginationPageSize),
                  0, // StartFlg
                  2, // TypeFlg
                  1  // StepFlg
                )
      this.$vloading.hide();
    },
    // 入出庫なし 調査開始
    async nyusyukkonashi(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_seisanki_check`;
      const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
      return await axios
      .post(url, formData.params)
      .then(res => {
      this.$emit(
                  'web-type03-start-event', // 親componentに記述したイベント名
                  res.data,
                  0, // StartFlg
                  3, // TypeFlg
                  1  // StepFlg
                )
        this.$vloading.hide();
      })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },
  },
})
