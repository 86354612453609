












































import Vue from "vue";
import axios from "axios";
interface Index {
  selectParking: string|null;
  time: number;
  headers: any[];
  result: any[];
}
export default Vue.extend({
  data(): Index {
    return {
      selectParking: null,
      time: 48,
      headers: [
        { text: '駐車時間', value: 'PARKING_TERM' },
        { text: '入庫時間', value: 'entrant_time' },
        { text: '駐車場名', value: 'PARKING_NAME_1' },
        { text: '入口名', value: 'EXT_ENTR_NAME' },
        { text: 'ナンバー', value: 'CAR_NUMBER' },
      ],
      result:[]
    };
  },
  props: {
    servers: {},
  },
  filters: {
    formatTime(value:number) {
      if (!value) return '00:00:00';
      const days = Math.floor(value / 86400);
      const hours = Math.floor((value % 86400) / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const seconds = value % 60;
      return [
        days > 0 ? days + '日' : '',
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
      ].join(':').replace(/^:+|:+$/g, '');
    }
  },
  methods: {
    selectedParking(){
      this.$emit('updateParking', this.selectParking);
    },
    async send(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/commons/long_term_parking_vehicle`;
      const formData = {
        params: { server: this.selectParking, time: this.time }
      }
      return await axios
      .get(url, formData)
      .then(res => {
        this.result = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      })
      ;
    },
  }
})
