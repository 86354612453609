































































































































































































import Vue from "vue";
export default Vue.extend({
  props: {
    today:                                {},
    userName:                             {},
    version:                              {},
    researchTypeFlg:                      {},
    researchStepFlg:                      {},
    researchEndFlg:                       {},
    escalationFlg:                        {},
    problemFlg:                           {},
    hoseiImgUrl:                          {},
    researchParkingInformation:           {},
    NumberCheckLists:                     {},
    type2NumberCheckCompletedLists:       {},
    type2CameraCheckCompletedLists:       {},
    type2HoseiCheckCompletedListsHosei:   {},
    type2HoseiCheckCompletedListsHoseiSP: {},
    type2HoseiCheckCompletedListsDaburi:  {},
    type2SurroundingsCheckStart:          {}
  },
})
