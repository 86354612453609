
































































































import Vue from "vue";
export default Vue.extend({
  props: {
    environment:      {},
    researchTypeFlg:  {},
    researchStepFlg:  {},
    researchEndFlg:   {},
  },
  methods: {
    type01Step00Pankuzu(): void{
      if(this.environment == "web"){
        this.$emit(
                    'pankuzu-nyusyukkoari-A-web-event', // 親componentに記述したイベント名
                    0,
                    3,
                    0,
                    0,
                    0,
                    [],
                    1,
                    3,
                    [],
                    12,
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    ""
                  )
      } else {
        document.location.reload()
      }
    },
    type01Step01Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-B-event', // 親componentに記述したイベント名
                  0,
                  1,
                  0,
                  1,
                  3,
                  [],
                  12,
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type01Step02Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-C-event', // 親componentに記述したイベント名
                  0,
                  2,
                  0,
                  1,
                  3,
                  [],
                  12,
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type01Step03Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-D-event', // 親componentに記述したイベント名
                  0,
                  3,
                  0,
                  1,
                  3,
                  [],
                  12,
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type01Step04Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-E-event', // 親componentに記述したイベント名
                  4,
                  0,
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type01Step05Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-F-event', // 親componentに記述したイベント名
                  5,
                  0,
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type01Step06Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-G-event', // 親componentに記述したイベント名
                  6,
                  0,
                  [],
                  [],
                  [],
                  ""
                )
    },
    type01Step07Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkoari-H-event', // 親componentに記述したイベント名
                  7,
                  0,
                  [],
                  []
                )
    },
  }
})
