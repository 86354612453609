import {extend} from 'vee-validate';
import { required, numeric } from 'vee-validate/dist/rules';

export function appendRequiredRules() {
  extend('required', {
    ...required,
    message: "この項目は必ず入力してください。"
  })
}

export function appendNumberRules() {
  extend('number-required', {
    ...numeric,
    message: "半角数字で入力してください"
  })
}

extend('class-number', {
  message: '半角大文字英数字で3桁まで入力してください',
  validate(value) {
    if (value.match(/^[0-9A-Z]{1,3}$/)) {
      return true;
    }
  }
});