































import Vue from "vue";

export default Vue.extend({
  props: {
    server: {
      list: Array,
      keyword: {
        type: String,
        default: ""
      },
      selectedList: {
        type: Array,
        default: []
      },
      selectedAll: {
        type: Boolean,
        default: false
      },
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    maxSelectCount: {
      type: Number,
      default: 0
    },
  },
  methods: {
    // 全サーバー選択時に親モデルのselectedServersのデータを更新
    selectAllServers() {
      let arr: any[] = [];
      if (this.selectAll) {
        this.server.list.map(server => arr.push(server.id));
      } else {
        arr = [];
      }
      this.$emit('selectedServers', arr)
    },
    // サーバー選択時に親モデルのselectedServersのデータを更新
    selectServers(id) {
      this.$emit('selectedServers',
        this.server.selectedList.includes(id) ? this.server.selectedList.filter(v => v !== id) : [...this.server.selectedList, id])
    }
  },
  computed: {
    keyword: {
      get: function () { return this.server.keyword },
      set: function (newValue) { this.$emit("changeKeyword", newValue) }
    },
    selectAll: {
      get: function () { return this.server.selectedAll },
      set: function (newValue) { this.$emit("changeSelectedAll", newValue)}
    },
    selectedList: {
      get: function () { return this.server.selectedList },
      set: function (newValue) { this.$emit("changeSelectedList", newValue) } },
    filteredServers() {
      const servers = [];
      for (const i in this.server.list) {
        const server = this.server.list[i];
        if (server.note.indexOf(this.server.keyword) !== -1) { servers.push(server); }
      }
      return servers;
    },
    selectedMaxCount() {
      return this.maxSelectCount > 0 && this.server.selectedList.length >= this.maxSelectCount
    },
  },
})
