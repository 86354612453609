

















































































































































































































































































































































































import Vue from "vue";
interface NotNumberDialogParkingDataList {
  dialogTypeParkingData:  boolean;
  dialogTypeResearchData: boolean;
  dialogTypeClipBoard: boolean;
  dialogTypeHelp: boolean;
  recordCheckImgUrl: string;
  stepDataType1:any[];
  stepDataType2:any[];
  stepDataType3:any[];
}
export default Vue.extend({
  data(): NotNumberDialogParkingDataList {
    return {
      dialogTypeParkingData:  false,
      dialogTypeResearchData: false,
      dialogTypeClipBoard: false,
      dialogTypeHelp:false,
      recordCheckImgUrl: "",
      stepDataType1:[
                    {value:'[step1]精算済 / 精算機調査 : '},
                    {value:'[step2]削除記録調査 : '},
                    {value:'[step3]アップロード調査 : '},
                    {value:'[step4]車両画像調査 : '},
                    {value:'[step5]カメラ管制調査 : '},
                    {value:'[step6]補正内容調査 : '},
                    {value:'[step7]撮影環境調査 : '},
                    ],
      stepDataType2:[
                    {value:'[step1]精算操作調査 : '},
                    {value:'[step2]カメラ管制調査 : '},
                    {value:'[step3]補正内容調査 : '},
                    {value:'[step4]撮影環境調査 : '},
                    ],
      stepDataType3:[
                    {value:'[step1]車両画像調査 : '},
                    {value:'[step2]カメラ管制調査 : '},
                    {value:'[step3]補正内容調査 : '},
                    {value:'[step4]撮影環境調査 : '},
                    ]
    };
  },
  props: {
    researchStartFlg: {},
    researchTypeFlg:  {},
    researchStepFlg:  {},
    researchEndFlg:   {},
    version:{},
    problemFlg:{},
    NumberCheckLists:{},
    type1SeisankiCheckCompletedListsStep1:{},
    type3SeisankiCheckCompletedLists:{},
    numberImgCheckHeaders:{},
    seisankiCheckHeaders:{},
    researchParkingInformation: {},
    escalationFlg:{}
  },
  computed: {
    changeEscalationFlg:{ 
      get () { return this.escalationFlg },
      set (newVal) { if (this.escalationFlg !== newVal)
      this.$emit('ChangeEscalationFlg', newVal) }}
  },
  methods: {
    recordCheckImgview(url): void{
      this.recordCheckImgUrl = url
    },
    copyToClipBoard(flg): void{
      if (flg == 1) {
        const text = document.getElementById('not-number-clip-board-type01');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
          this.$emit('ChangeEscalationFlg', 0)
          this.dialogTypeClipBoard = false
        });
      } else if (flg == 2) {
        const text = document.getElementById('not-number-clip-board-type02');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
          this.$emit('ChangeEscalationFlg', 0)
          this.dialogTypeClipBoard = false
        });
      } else if (flg == 3) {
        const text = document.getElementById('not-number-clip-board-type03');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
          this.$emit('ChangeEscalationFlg', 0)
          this.dialogTypeClipBoard = false
        });
      } else {
        alert('コピーに失敗しました');
        this.$emit('ChangeEscalationFlg', 0)
        this.dialogTypeClipBoard = false
      }
    },
  }
})
