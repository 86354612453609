









































import Vue from "vue";
import axios from "axios";

interface NotNumberSearchForm {
  servers:            any[];    // 取得した物件一覧
  valid:              boolean;  // バリデーション
  endDateSelectBox:   any[];    // 終点のセレクトボックス
}
export default Vue.extend({
  data(): NotNumberSearchForm {
    return {
      servers:          [],
      valid:            true,
      endDateSelectBox: [
                        { value: 1, text: '始点日と同じ' },
                        { value: 2, text: '始点から1日後' },
                        { value: 3, text: '始点から2日後' },
                        { value: 4, text: '始点から3日後' },
                        { value: 5, text: '始点から4日後' },
                        { value: 6, text: '始点から5日後' },
                        { value: 7, text: '始点から6日後' },
                        { value: 8, text: '始点から7日後' },
                        ],
    };
  },

  props: {
    selectParking: {}, // 選択した物件（id）
    startDate:     {}, // 始点(日付)
    endDate:       {}, // 終点(数値)
    inputNumber:   {}, // ナンバー(数値)
  },

  computed: {
    selectParkingValue: { get () { return this.selectParking }, set (newVal) { if (this.selectParking !== newVal) this.$emit('ChangeSelectParking', newVal) }},
    startDateValue:     { get () { return this.startDate },     set (newVal) { if (this.startDate !== newVal)     this.$emit('ChangeStartDate', newVal) }},
    endDateValue:       { get () { return this.endDate },       set (newVal) { if (this.endDate !== newVal)       this.$emit('ChangeEndDate', newVal) }},
    inputNumberValue:   { get () { return this.inputNumber },   set (newVal) { if (this.inputNumber !== newVal)   this.$emit('ChangeInputNumber', newVal) }},
  },

  methods: {
    // 物件情報取得
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
        console.log(this.servers)
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
    // 調査開始(LOCAL)
    async localResearchStartBtn(): Promise<void>{
      this.$vloading.show();
      if (this.startDate == "" || this.inputNumber == "" || this.selectParking == "" || this.endDate == "") {
        alert('必要項目が入力されていません。');
        this.$vloading.hide();
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate, environment: "local"} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          const number = res.data.length
          this.$emit(
                      'local-search-event',      // 親componentに記述したイベント名
                      res.data.slice(1, number), // local検索で取得したレコード一覧
                      res.data[0],               // 物件情報(parking_mgr)
                      2,                         // StartFlg(local = 2)
                      "local",                   // 調査中の環境
                      this.selectParking,
                      this.startDate,
                      this.endDate,
                      this.inputNumber
                    )
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      }
    },
    // 調査開始(WEB)
    async webResearchStartBtn(): Promise<void>{
      this.$vloading.show();
      if (this.startDate == "" || this.inputNumber == "" || this.selectParking == "" || this.endDate == "") {
        alert('必要項目が入力されていません。');
        this.$vloading.hide();
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate, environment: "web"} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit(
                      'web-search-event',     // 親componentに記述したイベント名
                      new Array(res.data[1]), // web検索で取得したレコード一覧
                      res.data[0],            // 物件情報(parking_mgr)
                      3,                      // StartFlg(web = 3)
                      "web",                  // 調査中の環境
                      this.selectParking,
                      this.startDate,
                      this.endDate,
                      this.inputNumber
                    )
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      }
    },
  },

  mounted() {
    this.getServerInfo();
  }

})
