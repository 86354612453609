









import Vue from "vue";

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formattedText: '',
    };
  },
  watch: {
    selectObj: {
      immediate: true,
      handler(newVal) {
        this.formattedText = this.convertToFormattedText(newVal);
      },
    },
  },
  methods: {
    convertToFormattedText(objArray: Array<Record<string, any>>): string {
      if (objArray.length === 0) return '';

      // ヘッダー行の取得
      const headers = Object.keys(objArray[0]).map(key => key.toUpperCase());
      // データ行の取得
      const rows = objArray.map(obj => Object.values(obj).map(value => String(value).toLowerCase()));
      // 特定のフォーマットに整形
      const headerRow = `|${headers.join('|')}|h`;
      const dataRows = rows.map(row => `|${row.join('|')}|`).join('\n');

      return `${headerRow}\n${dataRows}`;
    },
    copyToClipboard() {
      // クリップボードにコピー
      navigator.clipboard.writeText(this.formattedText).then(() => {
        alert('コピーしました');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
  },
});
