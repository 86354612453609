





























import Vue from "vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"

interface Params {
  headers: any[];
  selectObj: any[];
}
export default Vue.extend({

  components:{
    ObjectToCsv,
    ObjectToBacklog
  },

  data(): Params {
    return {
      headers: [
        { text: '物件名', value: "parking_name" },
        { text: '入庫時間', value: "f_entrant_time" },
        { text: '出庫時間', value: "f_exit_time" },
        { text: '請求金額', value: "pay_arrears" },
        { text: '支払い済フラグ', value: "pay_finished_flg" }
      ],
      selectObj: [],
    }
  },
  props:{
    ReadCommon:{}
  }
});
