











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";

import BusinessVehicleClaimRevocation from "../components/server/information/BusinessVehicleClaimRevocation.vue"; // 事業用車両請求取り消し設定
import MonthlyTollContractManager from "../components/server/information/MonthlyTollContractManager.vue"; // 月極契約管理
import CommonDbManager from "../components/server/information/CommonDbManager.vue"; // 共有DB設定
import MessageSetting from "../components/server/information/MessageSetting.vue"; // ４桁プラス設定
import WhiteLimit from "../components/server/information/WhiteLimit.vue";
import WhiteListDeletePeriod from "../components/server/information/commons/WhiteListDeletePeriod.vue";
import TroublShooting from "../components/server/information/TroublShooting.vue"; // トラブル対応

// モーダルウインドウ
import ImageUploadForm from "../components/forms/ImageUploadForm.vue"; // 看板画像をアップロードするためのモーダルウインドウ
import ReceiptSettingsForm from "../components/forms/servers/ReceiptSettings/Form.vue";  // 領収種印字設定を更新するためのモーダルウインドウ

interface Servers {
  servers:                      any[]; //「検索フォーム」で表示される物件一覧
  basicInformationParkingData:  any[]; //「基本情報」項目で取得してきたデータ一覧
  receiptSettingsData:          any[]; //「領収書印字設定」項目で取得してきたデータ一覧
  ParkingInformationData:       any[]; //「駐車場情報」項目で取得してきたデータ一覧
  YonketaPlusConfData:          any[]; //「時間帯検索誘導設定(Mobile)」項目で取得してきたデータ一覧
  rulesMessage:                 any[]; //「時間帯検索誘導設定(Mobile)」項目で入力するメッセージのバリデーション
  rulesStartDate:               any[]; //「時間帯検索誘導設定(Mobile)」項目で入力する開始日のバリデーション
  rulesEndDate:                 any[]; //「時間帯検索誘導設定(Mobile)」項目で入力する終了日のバリデーション
  desserts:                     any[]; //「事業用車両請求取り消し設定」項目で取得してきたデータ一覧
  parkingIdList:                any[]; //「事業用車両請求取り消し設定」項目で表示するparkingID別リスト

  selectParkings:                     number; //「検索フォーム」で選択した物件の入力値（server_id）
  dailySalesForecast:                 number; //「売上予測」の「1日の売上予測」の入力値
  maxFreeTime:                        number; //「売上予測」の「最大無料時間」の入力値
  getHostSuccessFlg:                  number; // コモンズサーバーデータベースの確認（初期値:0）
  getBasicInformationSuccessFlg:      number; // 基本情報の取得成功フラグ（初期値:0）
  getReceiptSettingsSuccessFlg:       number; // 領収料印字設定の取得成功フラグ（初期値:0）
  getSalesForecastSuccessFlg:         number; // 基本情報の成功フラグ（初期値:0）
  getParkingInformationSuccessFlg:    number; // 基本情報の成功フラグ（初期値:0）
  getYonketaPlusConfSuccessFlg:       number; // 時間帯検索誘導設定(Mobile)設定表示の成功フラグ（初期値:0）
  whiteLimit:                         number;
  whiteListDeletePeriod:              number;
  receiptSettingsParkingId:           number;

  userName:                       string;
  basicInformationImageUrl:       string; // 看板画像URL
  host:                           string; // 検索フォームにて選択した物件のURL
  parkingName:                    string; // 検索フォームにて選択した物件の名前
  YonketaPlusCreateMessage:       string; //「時間帯検索誘導設定(Mobile)」項目で
  YonketaPlusCreateStartDate:     string; //「時間帯検索誘導設定(Mobile)」項目で
  YonketaPlusCreateEndDate:       string; //「時間帯検索誘導設定(Mobile)」項目で
  YonketaPlusUpdateMessage:       string; //「時間帯検索誘導設定(Mobile)」項目で
  YonketaPlusUpdateStartDate:     string; //「時間帯検索誘導設定(Mobile)」項目で
  YonketaPlusUpdateEndDate:       string; //「時間帯検索誘導設定(Mobile)」項目で
  receiptSettingsCorporateNumber: string;
  receiptSettingsParkingName01:   string;
  receiptSettingsParkingName02:   string;
  receiptSettingsParkingName03:   string;
  receiptSettingsParkingName04:   string;
  receiptSettingsParkingName05:   string;
  receiptSettingsParkingName06:   string;

  getHostLoading:                      boolean; //「物件名とアドレス」の取得判定と終了判定(初期値:false)
  getBasicInformationLoading:          boolean; //「基本情報」の取得判定と終了判定(初期値:false)
  getReceiptSettingsLoading:           boolean; //「領収料印字設定」の取得判定と終了判定(初期値:false)
  getSalesForecastLoading:             boolean; //「売上予測」と終了の判定(初期値:false)
  getParkingInformationLoading:        boolean; //「駐車場情報」と終了の判定(初期値:false)
  getDeleteUnpaidTimeConditionLoading: boolean; //「事業用車両請求取り消し設定」と終了の判定(初期値:false)
  getMonthlyTollContractsLoading:      boolean; //「月極契約リストの取得」と終了の判定(初期値:false)
  getMessageSettingsLoading:           boolean; //「４桁プラスリストの取得」と終了の判定(初期値:false)
  getWhiteLimitLoading:                boolean;
  getWhiteListDeletePeriodLoading:     boolean;
  dialogImageUpload:                   boolean; //「看板画像アップロード」の表示判定(初期値:false)
  dialogReceiptSettings:               boolean; //「領収書印字設定フォーム」の表示判定(初期値:false)
  searchFlg:                           boolean; // 検索開始と検索終了の判定(初期値:false)
  getYonketaPlusConfLoading:           boolean; //「時間帯検索誘導設定(Mobile)」項目で
  YonketaConfSwitch:                   boolean; //「時間帯検索誘導設定(Mobile)」項目で
  createValid:                         boolean; //「時間帯検索誘導設定(Mobile)」項目で
  updateValid:                         boolean; //「時間帯検索誘導設定(Mobile)」項目で
  receiptSettingsModelReload:          boolean;
  unpaidShareFlg:                      boolean; // 共有DB有効設定の状態フラグ
  getUnpaidShareFlgLoading:            boolean;
}

export default Vue.extend({
  name: "App",
  data(): Servers {
    return {
      userName: JSON.parse(localStorage.user).name,
      servers: [],
      selectParkings: 0,
      host: "",
      parkingName: "",
      getHostLoading: false,
      basicInformationParkingData: [],
      receiptSettingsData: [],
      basicInformationImageUrl: "",
      dailySalesForecast: 0,
      maxFreeTime: 0,
      getBasicInformationLoading: false,
      getReceiptSettingsLoading: false,
      getSalesForecastLoading: false,
      getParkingInformationLoading: false,
      getDeleteUnpaidTimeConditionLoading: false,
      getMonthlyTollContractsLoading: false,
      getMessageSettingsLoading: false,
      getWhiteLimitLoading: false,
      getWhiteListDeletePeriodLoading: false,
      dialogImageUpload: false,
      dialogReceiptSettings: false,
      searchFlg: false,
      ParkingInformationData: [],
      getHostSuccessFlg: 0,
      getBasicInformationSuccessFlg: 0,
      getReceiptSettingsSuccessFlg: 0,
      getSalesForecastSuccessFlg: 0,
      getParkingInformationSuccessFlg: 0,
      YonketaPlusConfData: [],
      getYonketaPlusConfLoading: false,
      YonketaConfSwitch: false,
      YonketaPlusCreateMessage: "",
      YonketaPlusCreateStartDate: "",
      YonketaPlusCreateEndDate: "",
      YonketaPlusUpdateMessage: "",
      YonketaPlusUpdateStartDate: "",
      YonketaPlusUpdateEndDate: "",
      createValid: true,
      updateValid: true,
      rulesMessage: [
        v => v.length <= 40 || '40文字制限があります',
        v => !!v || '未入力です'
      ],
      rulesDate: [
        v => !!v || '入力に不備があります'
      ],
      getYonketaPlusConfSuccessFlg:  0,
      parkingIdList: [],
      desserts: [],
      whiteLimit:0,
      whiteListDeletePeriod:0,
      receiptSettingsModelReload: true,
      receiptSettingsParkingId:0,
      receiptSettingsCorporateNumber:"",
      receiptSettingsParkingName01:"",
      receiptSettingsParkingName02:"",
      receiptSettingsParkingName03:"",
      receiptSettingsParkingName04:"",
      receiptSettingsParkingName05:"",
      receiptSettingsParkingName06:"",
      monthlyContracts: [],
      unpaidShareFlg: false, // 未払DBの共有設定
      getUnpaidShareFlgLoading: false, //共有未払設定の変更
      messageSettings: [],
    }
  },
  components: {
    ImageUploadForm,
    ReceiptSettingsForm,
    BusinessVehicleClaimRevocation, // 事業用車両請求取り消し設定
    MonthlyTollContractManager, // 月極契約管理
    CommonDbManager, // 共有DB設定
    MessageSetting, // ４桁プラス管理
    WhiteLimit,
    WhiteListDeletePeriod,
    TroublShooting
  },
  computed: {
    selectedParking() {
      return this.servers.filter(s => s.id === this.selectParkings)[0]
    }
  },
  methods: {
    // このイベントは物件を選択したタイミングで発火するものです
    // 機能追加の際はこちらにイベント名を記載してください
    // #########################################################################################
    // #########################################################################################
    selectParking():void{
      // 検索結果を閉じる
      this.searchFlg = false                 // 初期化(検索結果表示フラグ)

      // フラグの初期化
      this.getHostSuccessFlg = 0
      this.getBasicInformationSuccessFlg = 0
      this.getReceiptSettingsSuccessFlg = 0
      this.getSalesForecastSuccessFlg = 0
      this.getYonketaPlusConfSuccessFlg = 0
      this.YonketaConfSwitch = false
      this.desserts = []

      // 物件名
      this.getHost() //「物件名」

      // サーバー
      this.getBasicInformation() //「基本情報」
      this.getReceiptSettings()  //「領収書印字設定」

      // モバイル
      this.getYonketaPlusConf() //「時間帯検索誘導設定(Mobile)」

      // コモンズ
      this.getSalesForecast()             //「売上予測」
      this.getDeleteUnpaidTimeCondition() //「事業用車両請求取り消し設定」
      this.getMonthlyTollContracts()      //「月極契約リストの取得」
      this.getMessageSettings()            //「４桁プラスリストの取得」
      this.getWhiteLimit()                //「精算不要車両登録上限の設定」
      this.getWhiteListDeletePeriod()     //「精算不要車両登録の保存期間設定」

      // 共有DB設定
      this.getUnpaidShareFlg() //「未払共有設定の変更」

      // 保留
      // this.getParkingInformation() //「駐車場情報」(primary_key問題が解決するまで停止！！ 2022/10/03)

      // 検索結果を開く
      this.searchFlg = true // 情報の取得終了(検索結果表示フラグ)
    },
    // #########################################################################################
    // #########################################################################################

    // 共有DB設定
    async getUnpaidShareFlg(): Promise<void> {
      this.getUnpaidShareFlgLoading = true
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers/${this.selectParkings}`;
      return await axios
      .get(url)
      .then(res => {
        this.unpaidShareFlg = res.data.unpaid_share_flg;
        this.getUnpaidShareFlgLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.getUnpaidShareFlgLoading = false;
      });
    },

    async getWhiteListDeletePeriod(): Promise<void> {
      this.whiteListDeletePeriod = null
      this.getWhiteListDeletePeriodLoading = true  // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_white_list_delete_period`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.whiteListDeletePeriod = res.data.white_list_delete_period
        this.getWhiteListDeletePeriodLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getWhiteListDeletePeriodLoading = false
      });
    },

    async getWhiteLimit(): Promise<void> {
      this.whiteLimit = null
      this.getWhiteLimitLoading = true  // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_white_limit`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.whiteLimit = res.data.white_limit
        this.getWhiteLimitLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getWhiteLimitLoading = false
      });
    },

    // 物件名グループ
    // #########################################################################################
    // 「物件名」物件名とホストを取得する
    async getHost(): Promise<void> {
      this.host = ""              // データの初期化
      this.parkingName = ""       // データの初期化
      this.getHostLoading = true  // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_host`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.getHostSuccessFlg = res.data.get_host_success_flg
        this.host = res.data.host
        this.parkingName = res.data.name
        this.getHostLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getHostLoading = false
      });
    },
    //「物件名」webアドレスをコピーする
    copyHost(): void{
      const text = document.getElementById('clip-board-host');
      console.log(text)
      navigator.clipboard.writeText(text.innerText).then(e => { alert('コピーしました')})
    },
    //「物件名」物件名をコピーする
    copyName(): void{
      const text = document.getElementById('clip-board-name');
      navigator.clipboard.writeText(text.innerText).then(e => { alert('コピーしました')})
    },
    // #########################################################################################

    // サーバーグループ
    // #########################################################################################
    //「基本情報」基本情報を取得する
    async getBasicInformation(): Promise<void> {
      this.basicInformationParkingData = []  // データの初期化
      this.basicInformationImageUrl  = ""    // データの初期化
      this.getBasicInformationLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_basic_information`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.getBasicInformationSuccessFlg = res.data.get_basic_information_success_flg
        this.basicInformationParkingData = res.data.parking_data
        this.basicInformationImageUrl = res.data.fee_list_url
        this.getBasicInformationLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getBasicInformationLoading = false
      });
    },
    //「基本情報」看板画像モーダルウインドウを表示する
    openDialogImageUpload(): void {
      this.dialogImageUpload = true
    },
    //「基本情報」IPをコピーする
    copyIp(id): void{
      const text = document.getElementById(`clip-board-ip-${id}`);
      console.log(text)
      navigator.clipboard.writeText(text.innerText).then(e => { alert('コピーしました')})
    },
    //「基本情報」領収書印字設定を取得する
    async getReceiptSettings(): Promise<void> {
      this.receiptSettingsData = []  // データの初期化
      this.getReceiptSettingsLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_receipt_settings`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.getReceiptSettingsSuccessFlg = res.data.success_flg
        this.receiptSettingsData = res.data.result
        console.log(res.data.result)
        this.getReceiptSettingsLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getReceiptSettingsLoading = false
      });
    },
    //「領収書印字設定」モーダルウインドウを表示する
    openDialogReceiptSettings(
      pid:number, corporateNum:string, pName1:string, pName2:string,  pName3:string,  pName4:string,  pName5:string,  pName6:string
    ): void {
      this.receiptSettingsParkingId = pid
      this.receiptSettingsCorporateNumber = corporateNum
      this.receiptSettingsParkingName01 = pName1
      this.receiptSettingsParkingName02 = pName2
      this.receiptSettingsParkingName03 = pName3
      this.receiptSettingsParkingName04 = pName4
      this.receiptSettingsParkingName05 = pName5
      this.receiptSettingsParkingName06 = pName6
      this.receiptSettingsModelReload = false;
      this.$nextTick(() => (this.receiptSettingsModelReload = true));
      this.dialogReceiptSettings = true
    },
    // #########################################################################################

    // コモンズグループ
    // #########################################################################################
    // 「売上予測」を取得する
    async getSalesForecast(): Promise<void> {
      this.dailySalesForecast = null      // データの初期化
      this.maxFreeTime = null             // データの初期化
      this.getSalesForecastLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_sales_forecast`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.getSalesForecastSuccessFlg = res.data.get_sales_forecast_success_flg
        this.dailySalesForecast = res.data.daily_sales_forecast
        this.maxFreeTime = res.data.max_free_time
        this.getSalesForecastLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getSalesForecastLoading = false
      });
    },
    // 「売上予測」を更新する
    async updateSalesForecast(): Promise<void> {
      const text = '以下の内容で更新しますか？\n\n'
      const parking = `■ ${this.parkingName}\n`
      const sales = `・売上予測 : ${this.dailySalesForecast}円\n`
      const freetime = `・最大無料時間 : ${this.maxFreeTime}分\n`
      const result = window.confirm(text + parking + sales + freetime)
      if (result) {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers/update_sales_forecast`;
        const data = {
          id: this.selectParkings,
          dailySalesForecast: this.dailySalesForecast,
          maxFreeTime: this.maxFreeTime
        }
        return await axios
        .post(url, data)
        .then(res => {
          alert("更新しました");
          this.getSalesForecast()
        })
        .catch(error => {
          console.log(error)
          alert("更新に失敗しました");
          this.getSalesForecast()
        });
      }
    },
    // #########################################################################################

    // 駐車場情報グループ（停止中）
    // #########################################################################################
    // 「駐車場情報」の取得する
    async getParkingInformation(): Promise<void> {
      this.ParkingInformationData = []         // データの初期化
      this.getParkingInformationLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_parking_information`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.ParkingInformationData = res.data
        this.getParkingInformationLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getParkingInformationLoading = false
      });
    },
    // #########################################################################################

    // モバイルグループ
    // #########################################################################################
    // 「時間帯検索誘導設定(Mobile)」を取得する
    async getYonketaPlusConf(): Promise<void> {
      this.YonketaPlusConfData = []         // データの初期化
      this.getYonketaPlusConfLoading = true // loading開始
      this.getYonketaPlusConfSuccessFlg = 0
      this.YonketaConfSwitch = false
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_yonketa_plus_conf`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.YonketaPlusConfData = res.data
        this.YonketaPlusUpdateMessage = res.data.message
        this.YonketaPlusUpdateStartDate = res.data.start_date
        this.YonketaPlusUpdateEndDate = res.data.end_date
        this.getYonketaPlusConfLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getYonketaPlusConfSuccessFlg = 1
        this.getYonketaPlusConfLoading = false
      });
    },
    // 「時間帯検索誘導設定(Mobile)」を更新する
    async getYonketaPlusConfUpdate(message,start,end): Promise<void> {
      const validate = this.$refs.form.validate()
      if (start <= end) {
        if (validate) {
          const result = window.confirm('更新しますか？')
          if (result) {
            const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_yonketa_plus_conf_update`;
            const formData = { params: {
                                        name: this.userName,
                                        id: this.selectParkings,
                                        message: message,
                                        start: start,
                                        end: end
                                        }
                              };
            return await axios
            .post(url, formData.params)
            .then(res => {
              alert("更新しました");
              this.YonketaConfSwitch = false
              this.getYonketaPlusConf()
            })
            .catch(error => {
              alert("更新に失敗しました");
              console.log(error)
              this.YonketaConfSwitch = false
              this.getYonketaPlusConf()
            });
          }
        }
      } else {
        alert("開始日を終了日より未来に設定することは出来ません");
      }
    },
    // 「時間帯検索誘導設定(Mobile)設定」を削除する
    async getYonketaPlusConfDelete(): Promise<void> {
      const result = window.confirm('表示を停止しますか？')
      if (result) {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_yonketa_plus_conf_delete`;
        const formData = { params: { id: this.selectParkings, name: this.userName } };
        return await axios
        .post(url, formData.params)
        .then(res => {
          alert("メッセージの表示を停止しました");
          this.YonketaConfSwitch = false
          this.getYonketaPlusConf()
        })
        .catch(error => {
          alert("メッセージの表示を停止できませんでした");
          console.log(error)
          this.YonketaConfSwitch = false
          this.getYonketaPlusConf()
        });
      }
    },
    //「時間帯検索誘導設定(Mobile)」未設定 → 設定しますか？ スイッチ(trueで新規作成入力フォームの表示を行う)
    YonketaConfSwitchBtn():void{
      this.YonketaPlusCreateMessage = ""
      this.YonketaPlusCreateStartDate = ""
      this.YonketaPlusCreateEndDate = ""
      this.YonketaConfSwitch = true
    },
    // #########################################################################################

    // 依頼ツールグループ
    // #########################################################################################
    // 「事業用車両請求取り消し設定」を取得する
    async getDeleteUnpaidTimeCondition(): Promise<void> {
      this.getDeleteUnpaidTimeConditionLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_delete_unpaid_time_condition`;
      const formData = { params: { id: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.desserts = res.data.configData
        this.parkingIdList = res.data.parkingData
        this.getDeleteUnpaidTimeConditionLoading = false
      })
      .catch(error => {
        console.log(error)
        this.getDeleteUnpaidTimeConditionLoading = false
      });
    },
    // #########################################################################################

    // 月極契約管理グループ
    // #########################################################################################
    // 月極契約のリストを取得する
    async getMonthlyTollContracts(): Promise<void> {
      this.getMonthlyTollContractsLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_toll_monthly_car_records`;
      const formData = { params: { serverId: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.monthlyContracts = res.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.getMonthlyTollContractsLoading = false
      });
    },
    // #########################################################################################

    // ４桁プラス
    // #########################################################################################
    // ４桁プラスのリストを取得する
    async getMessageSettings(): Promise<void> {
      this.getMessageSettingsLoading = true // loading開始
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/message_settings`;
      const formData = { params: { serverId: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.messageSettings = res.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.getMessageSettingsLoading = false
      });
    },
    // #########################################################################################

    // 「物件リスト（検索セレクト）」を取得する
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
        console.log(this.servers)
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getServerInfo();
  }
});
