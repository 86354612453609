























































































































































































































































import Vue from "vue";
import axios from "axios";
export default {
  data: () => ({
    // テーブルヘッダー
    headers: [
      { text: '車両分類', align: 'start', value: 'NAME' },
      { text: 'PARKING_ID', value: 'PARKING_ID' },
      { text: '種別', value: 'CLASS_IFICATION' },
      { text: '上限時間（分）', value: 'MAXIMUM_TIME_MINUTE' },
      { text: '更新時間', value: 'UPDATE_DTE' },
      { text: '', value: 'actions', sortable: false },
    ],
    // 車両分類のセレクトボックス
    carValue: [
      { text: '大型貨物  : 1', value: '1' },
      { text: '観光バス  : 2', value: '2' },
      { text: '普通貨物  : 4 ', value: '4' },
      { text: 'タクシー  : 5 ', value: '5' },
      { text: '西武園タイプ専用  : 7  ', value: '7' },
      { text: '緊急車両  : 8', value: '8' }
    ],

    // モーダルウインドウ表示スイッチ
    dialogInsert: false,
    dialogUpdate: false,
    dialogDelete: false,

    // 選択中の車両分類
    deleteItemName:"",
    updateItemName:"",

    // 新規作成データ
    insertParkingId:null,
    insertLimit:null,
    insertClass:null,

    // 更新データ
    updateParkingId:null,
    updateLimit:null,
    updateClass:null,
  }),

  props: {
    selectParkings: {},
    desserts: {},
    parkingIdList: {},
    getDeleteUnpaidTimeConditionLoading: {},
  },

  methods: {
    reload () {
      this.$emit('reload')
    },
    // 新規作成
    async insert(): Promise<void> {
      const validation = this.$refs.insertform.validate()
      if (validation) {
        const result = window.confirm('新規登録を行いますか？')
        if (result) {
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/delete_unpaid_time_condition_insert`;
          const formData = { params: {
                                      id: this.selectParkings,
                                      class: this.insertClass,
                                      parkingId: this.insertParkingId,
                                      limit: this.insertLimit
                                      }
                            };
          return await axios
          .post(url, formData.params)
          .then(res => {
            this.$emit('reload')
            this.closeInsert ()
          })
          .catch(error => {
            alert("登録に失敗しました\n車両種別の重複は許可されていません\n既に同じ種別の登録がある場合は、更新ボタンからデータを変更してください");
            console.log(error)
            this.$emit('reload')
            this.closeInsert ()
          });
        }
      }
    },
    closeInsert () {
      this.dialogInsert = false
    },
    // 更新
    updateItem (item) {
      console.log(item)
      this.updateItemName = item.NAME
      this.updateClass = item.CLASS_IFICATION
      this.updateParkingId = item.PARKING_ID
      this.updateLimit = item.MAXIMUM_TIME_MINUTE
      this.dialogUpdate = true
    },
    async update(): Promise<void> {
      if( this.$refs.updateform.validate() ) {
        const result = window.confirm('更新しますか？')
        if (result) {
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/delete_unpaid_time_condition_update`;
          const formData = { params: {
                                      id: this.selectParkings,
                                      parkingId: this.updateParkingId,
                                      limit: this.updateLimit,
                                      class: this.updateClass
                                      }
                            };
          return await axios
          .post(url, formData.params)
          .then(res => {
            this.$emit('reload')
            this.closeUpdate ()
          })
          .catch(error => {
            alert("更新に失敗しました");
            console.log(error)
            this.$emit('reload')
            this.closeUpdate ()
          });
        }
      }
    },
    closeUpdate () {
      this.dialogUpdate = false
    },
    // 削除
    deleteItem (item) {
      console.log(item)
      this.deleteItemName = item.NAME
      this.deleteItemId = item.CLASS_IFICATION
      this.dialogDelete = true
    },
    async deleteItemConfirm(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/delete_unpaid_time_condition_delete`;
      const formData = { params: {
                                  id: this.selectParkings,
                                  class: this.deleteItemId,
                                  }
                        };
      return await axios
      .post(url, formData.params)
      .then(res => {
        this.$emit('reload')
        this.closeDelete ()
      })
      .catch(error => {
        alert("削除に失敗しました");
        console.log(error)
        this.$emit('reload')
        this.closeDelete ()
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  }
}
