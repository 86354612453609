




















































































import axios  from "axios";
export default {
  data() {
    return {
      userList: [],
      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'ログイン名', align: 'start', value: 'name' },
        { text: '氏名', value: 'realName' },
        { text: '部署', value: 'department' },
        { text: 'アクション', value: 'actions' },
      ],
      page: 1,
      itemsPerPage: 20,
      dialogUpdate: false,
      id: 0,
      name: '',
      nameRules: [ 
        (v: any) => !!v || '入力必須項目です。', 
        (v: any) => /^([a-z]|[a-z][a-z])_[a-z]{1,}/.test(v) || '入力が正しくありません。（例 : 山田太郎 =  t_yamada）', 
      ],
      familyName: '',
      familyNameRules: [ 
        (v: any) => !!v || '入力必須項目です。', 
      ],
      firstName: '',
      firstNameRules: [ 
        (v: any) => !!v || '入力必須項目です。', 
      ],
      department: '',
      departments: [
        "システム運営部",
        "システム開発部",
        "システム調整部",
        "営業部", 
        "大阪支店", 
        "情報システム部", 
        "メンテナンス部",
        "製品開発アルバイト", 
        "オペレーションGアルバイト",
        "稼働保守アルバイト",
      ],
      currentUser: {},
    }
  },
  methods: {
    async loadUsers() {
      this.$vloading.show()
      await axios.get("/api/users")
      .then(res => {
        console.log(res.data)
        this.userList = res.data.users
        console.log(this.userList)
      })
      .catch(error=>{
        console.log(error)
        alert(error.message)
      })
      .finally(() => {
        this.$vloading.hide()
      })
    },
    editUser(userId: number) {
      const user = this.userList.filter((user) => user.id === userId)[0]
      this.id = user.id
      this.name = user.name
      const realName = user.realName?.split(' ')
      this.familyName = realName[0]
      this.firstName = realName[1]
      this.department = user.department
      this.dialogUpdate = true
    },
    async updateUser() {
      this.dialogUpdate = false
      const user = {
        name: this.name,
        realName: this.familyName + ' ' + this.firstName,
        department: this.department,
      }
      await axios.put(`/api/users/${this.id}`, { user: user })
      .then(res => {
        console.log(res.data)
        this.loadUsers()
      })
      .catch(error =>{
        console.log(error)
        alert(error.message)
      })
    },
    async destroyUser(userId: number) {
      const user = this.userList.filter((user) => user.id === userId)[0]
      if (confirm(`${user.realName}(${user.name})を削除します。よろしいですか？`)) {
        await axios.delete(`/api/users/${user.id}`)
        .then(res => {
          console.log(res.data)
          this.loadUsers()
        })
        .catch(error =>{
          console.log(error)
          alert(error.message)
        })
      }
    },
  },
  computed: {
    pageCount(): number {
      return Math.ceil(this.userList.length / this.itemsPerPage)
    },
    formIsValid(): boolean {
      return this.nameRules.every(rule => rule(this.name) === true) && this.familyNameRules.every(rule => rule(this.familyName) === true) && this.firstNameRules.every(rule => rule(this.firstName) === true)
    },
  },
  mounted() {
    this.loadUsers()
    this.currentUser = JSON.parse(localStorage.user)
  }
}
