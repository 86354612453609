























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    selectParking:                        {},
    startDate:                            {},
    endDate:                              {},
    inputNumber:                          {},
    researchTypeFlg:                      {},
    researchStepFlg:                      {},
    researchEndFlg:                       {},
    type3SeisankiCheckLists:              {},
    type3SeisankiCheckCompletedLists:     {},
    type3CameraCheckLists:                {},
    type3CameraCheckCompletedLists:       {},
    type3HoseiCheckLists:                 {},
    type3HoseiCheckCompletedListsHosei:   {},
    type3HoseiCheckCompletedListsHoseiSP: {},
    type3HoseiCheckCompletedListsDaburi:  {},
    type3SurroundingsCheckLists:          {},
    type3SurroundingsCheckStart:          {},
    type3SurroundingsCheckEnd:            {},
    hoseiImgUrl:                          {},
    problemFlg:                           {},
    dialogType03Step03End01:              {},
    dialogType03Step03End02:              {},
    dialogType03Step03End04:              {},
    dialogType03Step03End05:              {},
    hoseiCheckHeaders01:                  {},
    hoseiCheckHeaders02:                  {},
    hoseiCheckHeaders03:                  {},
    cameraCheckHeaders:                   {},
    cameraCheckCompletedHeaders:          {},
    seisankiCheckHeaders:                 {},
    footerProps:                          {},
  },

  computed: {
    type3SeisankiCheckCompletedListsValue:     { get () { return this.type3SeisankiCheckCompletedLists },     set (newVal) { if (this.type3SeisankiCheckCompletedLists !== newVal)     this.$emit('ChangeType3SeisankiCheckCompletedLists', newVal) }},
    type3CameraCheckCompletedListsValue:       { get () { return this.type3CameraCheckCompletedLists },       set (newVal) { if (this.type3CameraCheckCompletedLists !== newVal)       this.$emit('ChangeType3CameraCheckCompletedLists', newVal) }},
    type3HoseiCheckCompletedListsHoseiValue:   { get () { return this.type3HoseiCheckCompletedListsHosei },   set (newVal) { if (this.type3HoseiCheckCompletedListsHosei !== newVal)   this.$emit('ChangeType3HoseiCheckCompletedListsHosei', newVal) }},
    type3HoseiCheckCompletedListsHoseiSPValue: { get () { return this.type3HoseiCheckCompletedListsHoseiSP }, set (newVal) { if (this.type3HoseiCheckCompletedListsHoseiSP !== newVal) this.$emit('ChangeType3HoseiCheckCompletedListsHoseiSP', newVal) }},
    type3HoseiCheckCompletedListsDaburiValue:  { get () { return this.type3HoseiCheckCompletedListsDaburi },  set (newVal) { if (this.type3HoseiCheckCompletedListsDaburi !== newVal)  this.$emit('ChangeType3HoseiCheckCompletedListsDaburi', newVal) }},
    problemFlgValue:                           { get () { return this.problemFlg },                           set (newVal) { if (this.problemFlg !== newVal)                           this.$emit('ChangeProblemFlg', newVal) }},
    dialogType03Step03End01Value:              { get () { return this.dialogType03Step03End01 },              set (newVal) { if (this.dialogType03Step03End01 !== newVal)              this.$emit('ChangeDialogType03Step03End01', newVal) }},
    dialogType03Step03End02Value:              { get () { return this.dialogType03Step03End02 },              set (newVal) { if (this.dialogType03Step03End02 !== newVal)              this.$emit('ChangeDialogType03Step03End02', newVal) }},
    dialogType03Step03End04Value:              { get () { return this.dialogType03Step03End04 },              set (newVal) { if (this.dialogType03Step03End04 !== newVal)              this.$emit('ChangeDialogType03Step03End04', newVal) }},
    dialogType03Step03End05Value:              { get () { return this.dialogType03Step03End05 },              set (newVal) { if (this.dialogType03Step03End05 !== newVal)              this.$emit('ChangeDialogType03Step03End05', newVal) }},
    type3SurroundingsCheckStartValue:          { get () { return this.type3SurroundingsCheckStart },          set (newVal) { if (this.type3SurroundingsCheckStart !== newVal)          this.$emit('ChangeType3SurroundingsCheckStart', newVal) }},
    type3SurroundingsCheckEndValue:            { get () { return this.type3SurroundingsCheckEnd },            set (newVal) { if (this.type3SurroundingsCheckEnd !== newVal)            this.$emit('ChangeType3SurroundingsCheckEnd', newVal) }},
  },

  methods: {
    normalEnd(number): void{
      this.$emit('normal-end', number)
    },
    type03Step03DialogEnd(number, endNumber): void{
      this.$emit('type03-step03-dialog-end', number, endNumber)
    },
    backToStep3(): void{
      this.$emit('back-to-step3', 0, 3, 0, [], [], [], "")
    },
    // 調査終了
    reload(): void{
      document.location.reload()
    },
    // 補正画像取得
    hoseiImgview(url): void{
      this.$emit('hosei-img-view', url)
    },
    // 入出庫なし [step2]カメラ管制調査へ移行
    async type03CameraCheckStartBtn(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_camera_check`;
      const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
      return await axios
      .post(url, formData.params)
      .then(res => {
        this.$emit('type03-camera-check-start', res.data, 2)
        this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },
    // 入出庫なし [step3]補正記録調査へ移行
    async type03HoseiCheckStartBtn(): Promise<void>{
      this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_hosei_check`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit('type03-hosei-check-start', res.data, 3)
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },
    // 入出庫なし [step4]撮影環境調査へ移行
    async type03SurroundingsCheckStartBtn(): Promise<void>{
      this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_surroundings_check`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit('type03-surroundings-check-start', res.data, 4, 0)
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },
    }
})
