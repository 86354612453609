


















































































































import Vue from "vue";
import axios from "axios";

interface VersionUpCheckSQL {
  // セレクトボックスで使用する物件一覧
  servers:         any;
  selectParkings:  any;
  result:          any;
  sql:             strings;
  zoom:            number;
}

export default Vue.extend({
  data(): VersionUpCheckSQL {
    return {
      // セレクトボックスで使用する物件一覧
      servers:        [],
      selectParkings: [],
      result:         [],
      sql:            "SELECT * FROM tbl_parking_mgr;",
      zoom:           100,
    }
  },
  methods: {
    // 物件情報取得
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
        console.log(this.servers)
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
    // 確認開始
    async checkSQL(): Promise<void> {
      const user     = localStorage.user
      this.userName  = JSON.parse(user).name
      this.result    = []
      this.$vloading.show()
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/update_check_sql`
      const formData = { sql: this.sql, selectParkings: this.selectParkings, user: this.userName }
      return await axios
      .post(url, formData)
      .then(res => {
        // select構文以外のSQLだった場合
        if (res.data.regexperror === true) {
          alert(res.data.message)
          this.$vloading.hide();
        // Mysql2::Errorが発生したSQLだった場合
        } else if (res.data.mysqlerror === true) {
          alert(res.data.message)
          this.$vloading.hide();
        // 取得が成功した場合
        } else {
          this.result = res.data;
          console.log(this.result)
          this.$vloading.hide();
        }
      })
      .catch(error => {
        console.log(error);
        alert("確認に失敗しました！")
        this.$vloading.hide();
      });
    },
    // SQLを消去する
    resetSQL () {
      this.sql = ""
    },
    // ズームアウト
    zoomOut () {
      this.zoom = (this.zoom - 10) || 0
    },
    // ズームイン
    zoomIn () {
      this.zoom = (this.zoom + 10) || 100
    },
  },
  mounted() {
    this.getServerInfo();
  }
});
