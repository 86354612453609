






























































































import Vue from "vue";
import axios from "axios";

interface OutputUnauthorizedUseVehicles {
  // セレクトボックスで使用する物件一覧
  servers:         any[];

  // インフォメーションのリスト
  informationItem: any;

  // 入力フォーム
  startDate:       string;
  endDate:         string;
  selectParking:   any;

  // バリデーション
  valid:           boolean;
  startDateRules:  any;
  endDateRules:    any;
}

export default Vue.extend({
  data(): OutputUnauthorizedUseVehicles {
    return {
      // セレクトボックスで使用する物件一覧
      servers: [],

      // インフォメーションのリスト
      informationItem:[
        {
          index: 1,
          show:  false,
          title: "出力条件",
          text:  "・1日に3回以上入出庫している。<br>・それぞれの入出庫でサービス券を利用している。<br>・精算不要車両、月極登録車両ではない。"
        },
        {
          index: 2,
          show:  false,
          title: "出力内容",
          text:  "[利用日]:入出庫回数の基準となる日付です。入庫日を基準としている為、日をまたいだ利用もカウントされます。<br>[物件名]:駐車した物件名です。PARKING_ID別に分かれます。<br>[車両情報]:車両のフルナンバーです。<br>[入出庫回数]:出力条件に一致した入出庫をカウントしています。<br>[サービス券利用枚数]:カウントされた入出庫情報で使用されたサービス券の総枚数です。<br>[サービス券情報]:読み込まれたサービス券のコードです。"
        },
        {
          index: 3,
          show:  false,
          title: "自動送信依頼",
          text:  "開発中"
        },
        {
          index: 4,
          show:  false,
          title: "downloadする",
          text:  "・始点～終点の日付けを入力する。<br>・物件を選択する。（複数物件の選択が可能）<br>・ダウンロードボタンをクリックする。"
        }
      ],

      // 入力フォーム
      startDate: '',
      endDate: '',
      selectParking: [],

      // バリデーション
      valid:true,
      startDateRules: [ 
        v => !!v || '入力必須項目です。',
      ],
      endDateRules: [ 
        v => !!v || '入力必須項目です。',
        v => v >= this.startDate || '始点より過去の日付を入力することは出来ません。',
      ]
    }
  },

  methods: {
    // 物件情報取得
    async getServerInfo(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
      })
      .catch(error => {
        alert("サーバー情報の取得に失敗しました！");
      });
    },

    // ダウンロード
    async sendCsv(): Promise<void>{
      if (this.selectParking.length != 0) {
        if (this.$refs.form.validate()) {
          this.$vloading.show();
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/unauthorized_use_vehicles_create_csv_file`;
          const formData = {
                            params: {
                                    startDate:     this.startDate,
                                    endDate:       this.endDate,
                                    selectParking: this.selectParking
                                    }
                            };
          const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);                                   // 文字化け対応
          return await axios
          .post(url, formData.params)
          .then(res => {
            const url = URL.createObjectURL(new Blob([bom,res.data], {type: 'text/csv'}));  // ここからcsvダウンロード処理
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '不正利用車両の抽出.csv');
            document.body.appendChild(link);
            link.click();
            this.$vloading.hide();
          })
          .catch(error => {
            console.log(error)
            alert("検索に失敗しました");
            this.$vloading.hide();
          });
        } else {
          this.$refs.form.validate()
        }
      } else {
        alert("物件を選択してください");
      }
    },
  },

  mounted() {
    this.getServerInfo();
  }

});
