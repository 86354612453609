



























































































































































































import Vue from "vue";
import axios from "axios";

interface Groups {
  groups: any[];
  parkings: any[];
  headers: any[];
  labels: any[];
  createLabels: any[];
  createName:"";
  createDescription:"";
  parkingsDialog: boolean;
  createDialog: boolean;
  createValid: boolean;
  updateLabels: any[];
  updateName:"";
  updateDescription:"";
  updateDialog: boolean;
  updateValid: boolean;
  loginUser:number;
}

export default Vue.extend({
  data(): Groups {
    return {
      groups:[],
      parkings:[],
      labels:[],
      createLabels:[],
      createName:"",
      createDescription:"",
      createValid: true,
      headers:[
        { text: '製作者', value: 'real_name' },
        { text: 'グループタイトル', value: 'name' },
        { text: '説明', value: 'description' },
        { text: '物件', value: 'parkings' },
        { text: '更新', value: 'update', sortable: false },
        { text: '削除', value: 'delete', sortable: false },
      ],
      parkingsDialog: false,
      createDialog: false,
      updateLabels:[],
      updateName:"",
      updateDescription:"",
      updateValid: true,
      updateDialog: false,
      loginUser: JSON.parse(localStorage.user).id,
    }
  },

  methods: {
    // 物件グループの取得
    async getGroups(): Promise<void> {
      this.groups = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/groups`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.groups = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // 物件グループの作成
    async createGroups(): Promise<void> {
      if (!this.$refs.createForm.validate()) return
      if (!window.confirm('グループを作成しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/groups`;
      const formData = {
        name: this.createName,
        description: this.createDescription,
        labelIds: this.createLabels,
        userId: JSON.parse(localStorage.user).id
      };
      return await axios
      .post(url,formData)
      .then(res => {
        console.log(res);
        alert("作成しました")
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.getGroups();
        this.createDialog = false;
        this.$vloading.hide();
      });
    },
    // 物件グループの削除
    async deleteGroups(id:number): Promise<void> {
      if (!window.confirm('本当に削除しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/groups/${id}`;
      return await axios
      .delete(url)
      .then(res => {
        console.log(res);
        alert("削除しました")
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.getGroups();
        this.$vloading.hide();
      });
    },
    // 更新ダイアログを表示
    async OpenUpdateDialog(id:number, name:string, description:string): Promise<void> {
      this.$vloading.show();
      this.updateLabels = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/labels_groups/${id}`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        const labelsIds = res.data.map((obj) => obj.id);
        this.updateLabels = labelsIds;
        this.updateName = name;
        this.updateDescription = description;
        this.updateDialog = true;
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // 物件グループの更新
    async updateGroups(id:number): Promise<void> {
      if (!this.$refs.updateForm.validate()) return
      if (!window.confirm('グループを更新しますか？')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/groups/${id}`;
      const formData = {
        name: this.updateName,
        description: this.updateDescription,
        labelIds: this.updateLabels,
        userId: JSON.parse(localStorage.user).id
      };
      return await axios
      .patch(url, formData)
      .then(res => {
        console.log(res);
        alert("更新しました")
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.getGroups();
        this.updateDialog = false;
        this.$vloading.hide();
      });
    },
    // 物件グループのリストを取得
    async showParkings(id:number): Promise<void> {
      this.$vloading.show();
      this.parkingsDialog = false;
      this.parkings = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/labels_groups/${id}`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.parkings = res.data;
        this.parkingsDialog = true;
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // labelsを取得
    async getLabels(): Promise<void> {
      this.labels = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/labels`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.labels = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("エラーが発生しました")
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getGroups();
    this.getLabels();
  }

})

