





























import Vue from "vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"

interface Params {
  headers: any[];
  selectObj: any[];
}
export default Vue.extend({

  components:{
    ObjectToCsv,
    ObjectToBacklog
  },

  data(): Params {
    return {
      headers: [
        { text: '入庫時間', value: "entrantTime" },
        { text: '出庫時間', value: "exitTime" },
        { text: '物件名', value: "parkingName" },
        { text: '更新時間(支払)', value: "updateTime" },
        { text: '請求金額', value: "payArrears" }
      ],
      selectObj: [],
    }
  },
  props:{
    PaidList:{}
  }
});
