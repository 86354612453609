<template>
  <v-container v-if="roleId!==3" class="grey lighten-5" id="unpaid-records-staying">
    <v-alert icon="mdi-school" prominent text type="info" class="func-explanation" >
      <h1>入庫中車両の未精算レコード出力</h1>
      <p>選択した物件において、現在入庫中の未精算車両を検索する機能です。</p>
    </v-alert>
    <h3 class="font-weight-regular ml-3">未精算車両を検索したい物件を選択してください。</h3>
    <v-form v-model="valid" @submit.prevent="submit" class="get-unpaid-form">
      <v-row>
        <v-col cols="12" md="6" sm="6" class="ml-3">
          <v-text-field v-model="server.keyword" label="物件名" autofocus/>
        </v-col>
      </v-row>
      <div v-if="server.keyword !== ''">
        <v-list flat v-for="server in filteredServers" :key="server.id" class="grey lighten-5">
          <router-link :to="{name: 'output-unpaid-records-staying-result', params: {id: server.id, name: server.note}}" >
            <v-list-item-group color="primary">
              <v-list-item :id="`parking-name-${server.note}`" class="server-list-item">
                {{ server.note}}
              </v-list-item>
            </v-list-item-group>
          </router-link>
        </v-list>
      </div>
    </v-form>
  </v-container>
  <!-- -- -- -- -- -- -- -- -- --  協力会社専用ビュー  -- -- -- -- -- -- -- -- -- -->
  <v-container v-else-if="roleId===3" class="grey lighten-5" id="unpaid-records-staying">
    <v-overlay :value="overlay"> <v-progress-circular indeterminate size="64" /> </v-overlay>
    <v-alert icon="mdi-school" prominent text type="info" class="func-explanation" >
      <h1>入庫中車両の未精算レコード出力</h1>
      <p>選択した物件において、現在入庫中の未精算車両を検索する機能です。</p>
    </v-alert>
    <h3 v-if="this.servers.length!==0" class="font-weight-regular ml-3">未精算車両を検索したい物件を選択してください。</h3>
    <h3 v-else-if="this.servers.length===0 && overlay" class="font-weight-regular ml-3">位置情報取得中</h3>
    <h3 v-else class="font-weight-regular ml-3">現在地から店舗情報を取得できませんでした。</h3>
    <v-btn style="margin-top:20px;" @click="getLocation">店舗情報再取得</v-btn>
    <v-form v-model="valid" @submit.prevent="submit" class="get-unpaid-form">
      <v-list flat v-for="server in this.servers" :key="server.id" class="grey lighten-5">
        <router-link :to="{name: 'output-unpaid-records-staying-result', params: {id: server.id, name: server.note}}" >
          <v-list-item-group color="primary">
            <v-list-item :id="`parking-name-${server.note}`" class="server-list-item">
              {{ server.note}}
            </v-list-item>
          </v-list-item-group>
        </router-link>
      </v-list>
    </v-form>
  </v-container>
</template>
<script type="ts">
import Vue from "vue";
import qs from 'qs';
import * as axios from "axios";
import serversMixin from "../mixins/serversMixin.ts";
export default Vue.extend({
  mixins: [serversMixin],
  data: () => ({
    overlay: false,
    valid: true,
    rules: ["required"],
    invalid: false,
    disabled: true,
    result: [],
    roleId: Number,
    servers: [], // roleId 3 で使用
  }),
  computed: {
    dateRangeText() {
      return this.dateRange.dates.join(" ~ ");
    },
    filteredServers() {
      const servers = [];
      for (const i in this.server.list) {
        const server = this.server.list[i];
        if (server.note.indexOf(this.server.keyword) !== -1) {
          servers.push(server);
        }
      }
      return servers;
    }
  },
  methods: {
    async submit() {
      this.$vloading.show();
      const formData = { servers: this.server.selectedList };
      axios.post('/api/get_upaid_records_staying_now', formData)
        .then(res => {
          this.result = res.data;
          if (this.result.length == 0) {
            this.disabled = true;
            alert("対象のデータがありませんでした。");
            this.$vloading.hide();
            return;
          }
          this.disabled = false;
          this.$vloading.hide();
        })
        .catch(err => {
          this.$vloading.hide();
          alert("データの取得に失敗しました。");
          console.log(err);
        });
    },
    getLocation () {   
      this.overlay = !this.overlay;
      if (!navigator.geolocation) { 
        alert('現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。') 
        return }
      const options = { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
      navigator.geolocation.getCurrentPosition(this.success, this.error, options)
    },
    success (position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      this.getUnpaidInfo(latitude, longitude)
    },
    error (error) {
      switch (error.code) {
        case 1: alert('位置情報の利用が許可されていません') //PERMISSION_DENIED
          break
        case 2: alert('現在位置が取得できませんでした')//POSITION_UNAVAILABLE
          break
        case 3: alert('タイムアウトになりました') //TIMEOUT
          break
        default: alert('現在位置が取得できませんでした')
          break
      }
      this.overlay = !this.overlay;
    },
    async getUnpaidInfo(latitude, longitude){
      const params = { location: { latitude: latitude, longitude: longitude, } }
      axios.post('/api/get_records_staying_for_partner', qs.stringify(params))
      .then((res)=>{
        console.log(res.data);
        this.servers = res.data;
        this.overlay = !this.overlay;
      })
      .catch((error)=>{
        console.log(error);
        this.overlay = !this.overlay;
      })
    },
  },
  mounted() {
    if (localStorage.user){
      this.roleId = JSON.parse(localStorage.user).role_id;
      if (localStorage.user===undefined) {
        this.$router.push("/");
      } else if (this.roleId!==3) {
        this.getServerInfo();
      } else if (this.roleId===3){
        this.getLocation();
      }
    }
  }
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&family=Sawarabi+Gothic&display=swap');

#unpaid-records-staying { font-family: 'Sawarabi Gothic', sans-serif; }

h1 { font-size: 1rem; }

h3 { color: #757575 }

p { font-size: 0.8rem; margin-bottom: 0; }

a { text-decoration: none; }

a:hover { background-color: rgb(227, 238, 250);}

.server-list-item:hover {background-color: rgb(227, 238, 250);}

.get-unpaid-form { width: 100%; }

.unpaid-title { color: rgba(0, 0, 0, 0.6) !important; margin-left: 15px; }

.func-explanation { width: 100%; }

.parkingtime-wrapper { align-items: center; margin-left: 0; }

.parkingtime-wrapper p { text-align: center; }

.unpaid-title-container { margin-left: 0; }

.server-select { display: flex; flex-wrap: wrap; }

.serverlist-item { width: 20%; }

.error-message { color: red; font-size: 1.1rem; }

.server-list-item { border-bottom: 1px solid gray; }
</style>
