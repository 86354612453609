
















































































































import Vue from "vue";
import axios from "axios";

interface TitleBar {
  dialog:  boolean;
  parkingDate: any[];
  img:string;
}

export default Vue.extend({
  data(): TitleBar {
    return {
      dialog: false,
      parkingDate:[],
      img:"",
    };
  },
  props: {
    selectParking: {},
    menuTitle: {},
  },
  methods: {
    async send(id:number): Promise<void> {
      this.$vloading.show();
      this.parkingDate = [] // データの初期化
      this.img = "" // データの初期化
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_basic_information`;
      const formData = { params: { id: id} };
      return await axios
      .get(url, formData)
      .then(res => {
        this.parkingDate = res.data.parking_data
        this.img = res.data.fee_list_url
        this.dialog = true
      })
      .catch(error => {
        console.log(error);
        alert("物件情報の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async webUiOpen(id:number): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_host`;
      const formData = { params: { id: id} };
      return await axios
      .get(url, formData)
      .then(res => {
        window.open('https://' + res.data.host + '/');
      })
      .catch(error => {
        console.log(error);
        alert("アドレスの取得に失敗しました");
      });
    },
  }
})
