













































import Vue from "vue";
export default Vue.extend({
  props: {
    environment:     {},
    researchTypeFlg: {},
    researchStepFlg: {},
    researchEndFlg:  {},
  },
  methods: {
    type03Step00Pankuzu(): void{
      if(this.environment == "local"){
        this.$emit(
                    'pankuzu-nyusyukkonasi-A-local-event', // 親componentに記述したイベント名
                    0,
                    2,
                    0,
                    0,
                    0,
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    ""
                  )
      } else if (this.environment == "web"){
        this.$emit(
                    'pankuzu-nyusyukkonasi-A-web-event', // 親componentに記述したイベント名
                    0,
                    3,
                    0,
                    0,
                    0,
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    ""
                  )
      } else {
        document.location.reload()
      }
    },
    type03Step01Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkonasi-B-event', // 親componentに記述したイベント名
                  0,
                  1,
                  0,
                  [],
                  [],
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type03Step02Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkonasi-C-event', // 親componentに記述したイベント名
                  0,
                  2,
                  0,
                  [],
                  [],
                  [],
                  [],
                  ""
                )
    },
    type03Step03Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkonasi-D-event', // 親componentに記述したイベント名
                  0,
                  3,
                  0,
                  [],
                  [],
                  [],
                  ""
                )
    },
    type03Step04Pankuzu(): void{
      this.$emit(
                  'pankuzu-nyusyukkonasi-E-event', // 親componentに記述したイベント名
                  0,
                  4,
                  0,
                  [],
                  []
                )
    },
  }
})
