




























































































import Vue from "vue";
import axios from "axios";

interface Params {
  operationId: string;
  operationArray: any[];
  headers: any[] | null;
  result: any[]| null;
  copyRecord: any[];
  valid: boolean;
  startDate: string;
  endDate: string;
}

export default Vue.extend({
  data(): Params {
    return {
      startDate: "",
      endDate: "",
      operationId: "",
      operationArray:[
        { value: 1002000,	text: "認証コード送信" },
        { value: 1005000,	text: "アカウント登録" },
        { value: 1008000,	text: "ログイン" },
        { value: 2002000,	text: "アカウント削除" },
        { value: 2101000,	text: "車両登録" },
        { value: 2102000,	text: "車両登録解除" },
        { value: 2301000,	text: "クレジットカード登録" },
        { value: 2302000,	text: "クレジットカード登録解除" },
        { value: 2401000,	text: "お問い合わせ" },
        { value: 7001000,	text: "料金算出" },
        { value: 7002000,	text: "未払い取得" },
        { value: 7004000,	text: "サービス受領" },
        { value: 7004100,	text: "サービス受領失敗" },
        { value: 7008000,	text: "決済開始" },
        { value: 8001000,	text: "決済完了" },
        { value: 8003000,	text: "決済失敗" },
        { value: 8004000,	text: "領収書" }
      ],
      headers: null,
      result: null,
      copyRecord: [],
      valid: true
    }
  },

  computed: {
    // テーブルのレコードを選択するためにレコードにインデックス（一意の値）を追加
    indexedItems () {
      return this.result.map((item, index) => ({
        index: index,
        ...item
      }))
    }
  },

  methods: {
    // 終点に始点＋1を代入する
    andOne(num:number) {
      // 現在の日付を取得
      const end = new Date();
      // 昨日の日付を取得するために、今日の日付から1日引く
      const start = new Date(end);
      start.setDate(start.getDate() - num);
      // 日付を 'yyyy-mm-dd' フォーマットに変換する関数
      function formatDate(date:Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      // フォーマットされた今日と昨日の日付
      if (num != 9999) {
        this.startDate = formatDate(start);
      } else {
        // 全期間
        this.startDate = "2000-01-01";
      }
      this.endDate = formatDate(end);
    },
    // SQL実行
    async send(startDate:string, endDate:string, operationId:string): Promise<void>{
      if (!this.$refs.form.validate()) return
      this.result = [];
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/sql_select/sql19`;
      const formData = { params:
        { 
          startDate: startDate,
          endDate: endDate,
          operationId: operationId
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        if (res.data.length == 0) return alert("検索結果がありませんでした");
        this.copyRecord = [];
        this.result = res.data;
        const headerAry = [];
        for (const element of Object.keys(res.data[0])) {
          headerAry.push({text: element, value: element })
        };
        this.headers = headerAry;
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // コピー
    copyToClipBoard(): void{
      if(this.copyRecord.length != 0) {
        const text = document.getElementById('spl19copy');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else {
        alert('コピー対象を選んでください');
      }
    },
  },
})
