

























































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/index.ts";
import VehiclesInformationSearchList from '../components/lists/VehiclesInformationSearchList.vue';

interface VehicleInformation {
  servers:                 any[];
  parkingIdFrom:           any[];
  vehicleStatusIdForm:     any[];
  keyword:                 string;
  selectToCurrentServerId: string;
  selectToServerNote:      string;
  inputToParkingId:        string;
  inputToStartDate:        string;
  inputToEndDate:          string;
  inputToStartTime:        string;
  inputToEndTime:          string;
  inputToPlace:            string;
  inputToClassNumber:      string;
  inputToKana:             string;
  inputToCarNumber:        string;
  inputToFullNumber:       string;
  inputToVehicheStatus:    string;
  valid:                   boolean;
  searchNow:               boolean;
  searchFormShow:          boolean;
  selectParkings: any;
  address: string;
}
export default Vue.extend({
  data(): VehicleInformation {
    return {
      servers:                  [],                                // 物件検索のサーバー配列
      keyword:                  "",                                // 物件検索キーワード
      parkingIdFrom:            [],                                // PARKING_IDのセレクトボックス
      vehicleStatusIdForm:      [                                  // 状態のセレクトボックス（固定値）
                                  {value: "", text: ""},
                                  {value: 0,  text: "入庫中"},
                                  {value: 1,  text: "未精算出庫"},
                                  {value: 2,  text: "出庫済み"}
                                ],
      selectToCurrentServerId:  "",                                // 選択した物件のserver_id
      selectToServerNote:       "",                                // 選択した物件の名前
      inputToParkingId:         "",                                // 入力したPARKING_ID
      inputToStartDate:         "",                                // 入力した入出庫日付（始点）
      inputToEndDate:           "",                                // 入力した入出庫日付（終点）
      inputToStartTime:         "",                                // 入力した入庫時間（始点）
      inputToEndTime:           "",                                // 入力した入庫時間（終点）
      inputToPlace:             "",                                // 入力した地域
      inputToClassNumber:       "",                                // 入力したクラスナンバー
      inputToKana:              "",                                // 入力したかな
      inputToCarNumber:         "",                                // 入力した4桁ナンバー
      inputToFullNumber:        "",                                // 入力したフルナンバー（優先計画）
      inputToVehicheStatus:     "",                                // 入力した状態
      valid:                    true,                              // バリデーション（検索ボタンの送信許可）
      searchNow:                true,                              // 物件検索中フラグ
      searchFormShow:           true,                              // 入力フォームの表示 / 非表示
      selectParkings: null,
      address:""
    }
  },
  components: { 
    VehiclesInformationSearchList
  },
  computed: {
    // storeに保管した検索データをsearchDataとして出力
    searchData() {
      return this.$store.state.vehiclesInformationList
    },
  },
  methods: {
    // ページを開いたときに実行するイベント
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      if (this.$store.state.vehiclesInformationInput.length != 0) { // 入力データをstoreに保存していれば値をフォームに入力する
                                                                    this.parkingIdFrom           = this.$store.state.vehiclesInformationInput.parkingIdFrom
                                                                    this.selectToServerNote      = this.$store.state.vehiclesInformationInput.serverNote
                                                                    this.selectToCurrentServerId = this.$store.state.vehiclesInformationInput.server
                                                                    this.inputToStartDate        = this.$store.state.vehiclesInformationInput.startDate
                                                                    this.inputToEndDate          = this.$store.state.vehiclesInformationInput.endDate
                                                                    this.inputToStartTime        = this.$store.state.vehiclesInformationInput.startTime
                                                                    this.inputToEndTime          = this.$store.state.vehiclesInformationInput.endTime
                                                                    this.inputToPlace            = this.$store.state.vehiclesInformationInput.place
                                                                    this.inputToClassNumber      = this.$store.state.vehiclesInformationInput.classNumber
                                                                    this.inputToKana             = this.$store.state.vehiclesInformationInput.kana
                                                                    this.inputToCarNumber        = this.$store.state.vehiclesInformationInput.carNumber
                                                                    this.inputToVehicheStatus    = this.$store.state.vehiclesInformationInput.statusId
                                                                    this.inputToParkingId        = this.$store.state.vehiclesInformationInput.parkingId
                                                                    this.address                 = this.$store.state.serverDate
                                                                    this.selectParkings          = this.$store.state.selectParking
                                                                  }
      this.$refs.form.validate()
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
      this.inputToStartDate = today.getFullYear() + "-" + ('00' + (today.getMonth()+1)).slice(-2) + "-"+ ('00' + today.getDate()).slice(-2);
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
        this.$vloading.hide();
      })
      .catch(error => {
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
    // 選択した物件のIDを固定し、PARKING_IDのセレクトボックスに値を挿入するイベント
    async selectParking(value: object): Promise<void> {
      store.commit('setVehiclesInformationList', [])
      this.searchNow = false;
      this.inputToParkingId = "";
      this.selectToCurrentServerId = value.id;
      this.selectToServerNote      = value.note;
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_search_form_insert`;
      const formData = { params: { server: this.selectToCurrentServerId } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.address = res.data[1]
        this.parkingIdFrom = res.data[0];
      })
      .catch(error => {
        alert("PARKING_IDの取得に失敗しました");
      });
    },
    // 検索ボタン実行イベント
    async submit(): Promise<void> {
      this.$store.commit('setVehiclesInformationList')
      this.$refs.form.validate()
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_search`;
      let formData;
      if (this.inputToFullNumber != "") {
        const fullNumberAry = this.inputToFullNumber.split(' ');
        if (fullNumberAry[3] != null) {
          formData = { params: {
            server:         this.selectToCurrentServerId,
            serverNote:     this.selectToServerNote,
            parkingId:      this.inputToParkingId,
            startDate:      this.inputToStartDate,
            endDate:        this.inputToEndDate,
            startTime:      this.inputToStartTime,
            endTime:        this.inputToEndTime,
            place:          fullNumberAry[0],
            classNumber:    fullNumberAry[1],
            kana:           fullNumberAry[2],
            carNumber:      fullNumberAry[3],
            statusId:       this.inputToVehicheStatus,
            parkingIdFrom:  this.parkingIdFrom
          } };
        } else {
          const place       = this.inputToFullNumber.match(/^[一-鿐ヶ]{1,4}|[あ-ん]{1,4}/)[0]
          const classNumber = this.inputToFullNumber.match(/[1-9][0-9][0-9ACFHKLMPXY]|[1-9][0-9]/)[0]
          const kana        = this.inputToFullNumber.split(/[1-9][0-9][0-9ACFHKLMPXY]|[1-9][0-9]/)[1].slice(0,1)
          const carNumber   = this.inputToFullNumber.match(/\*\*\*[1-9]$|\*\*[1-9][0-9]$|\*[1-9][0-9][0-9]$|[1-9][0-9][0-9][0-9]$|[1-9]$|[1-9][0-9]$|[1-9][0-9][0-9]$|^$/)[0]
        if (carNumber.length == 4) {
          formData = { params: {
            server:         this.selectToCurrentServerId,
            serverNote:     this.selectToServerNote,
            parkingId:      this.inputToParkingId,
            startDate:      this.inputToStartDate,
            endDate:        this.inputToEndDate,
            startTime:      this.inputToStartTime,
            endTime:        this.inputToEndTime,
            place:          place,
            classNumber:    classNumber,
            kana:           kana,
            carNumber:      carNumber,
            statusId:       this.inputToVehicheStatus,
            parkingIdFrom:  this.parkingIdFrom
          } };
        } else {
          formData = { params: {
            server:         this.selectToCurrentServerId,
            serverNote:     this.selectToServerNote,
            parkingId:      this.inputToParkingId,
            startDate:      this.inputToStartDate,
            endDate:        this.inputToEndDate,
            startTime:      this.inputToStartTime,
            endTime:        this.inputToEndTime,
            place:          place,
            classNumber:    classNumber,
            kana:           kana,
            carNumber:      carNumber.padStart(4, "*"),
            statusId:       this.inputToVehicheStatus,
            parkingIdFrom:  this.parkingIdFrom
          } };
        }
        }
      } else {
        formData = { params: {
          server:         this.selectToCurrentServerId,
          serverNote:     this.selectToServerNote,
          parkingId:      this.inputToParkingId,
          startDate:      this.inputToStartDate,
          endDate:        this.inputToEndDate,
          startTime:      this.inputToStartTime,
          endTime:        this.inputToEndTime,
          place:          this.inputToPlace,
          classNumber:    this.inputToClassNumber,
          kana:           this.inputToKana,
          carNumber:      this.inputToCarNumber,
          statusId:       this.inputToVehicheStatus,
          parkingIdFrom:  this.parkingIdFrom
        } };
      }
      return await axios
      .get(url, formData)
      .then(res => {
        store.commit('selectParking', this.selectParkings) // 物件データをstoreに保管
        store.commit('serverDate', this.address) // 物件データをstoreに保管
        store.commit('vehiclesInformationInput', formData) // 入力データをstoreに保管
        store.commit('setVehiclesInformationList', res.data) // 検索データをstoreに保管
        if(res.data == 0) {
          alert("検索結果がありませんでした");
          this.$vloading.hide();
        } else {
          this.searchFormShow = false;
          this.$vloading.hide();
        }
      })
      .catch(error => {
        alert("検索に失敗しました");
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getServerInfo();
  }
});
