import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import {LoadingPlugin} from "vuetify-loading-overlay";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { appendRequiredRules, appendNumberRules } from "./plugins/validationRules";
import axios from "axios";
import VueAxiosPlugin from "./plugins/vue-axios";

Vue.use(Vuetify);
Vue.use(VueAxiosPlugin, { axios: axios })

Vue.use(LoadingPlugin, {
  spinnerProps: {},
  overlayProps: {},
});

Vue.config.productionTip = false;

Vue.component('ValidationProvider', ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)
appendRequiredRules()
appendNumberRules()

new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: h => h(App)
}).$mount("#app");
