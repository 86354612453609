



























































































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  data(): VehiclesInformationShow { 
    return {
      reqReason: "【コモンズ削除】" + JSON.parse(localStorage.user).name + " ",
      serverId:        this.$route.query.serverId,
      place:           this.$route.query.place,
      classNumber:     this.$route.query.classNumber,
      kana:            this.$route.query.kana,
      carNumber:       this.$route.query.carNumber,
      operationJournalName: "",
      operationJournalEntrant: "",
      operationJournalExit: "",
      operationJournalFormat: "",
      backlogParkingTime : "",
      backlogAccountTotal : "",
      showData:        [],
      operationDate: [],
      checkoutDate:[],
      mobileDate: "",
      dialog:false,
      headers: [
        { text: '精算機', value: 'NAME' },
        { text: 'parking_id', value: 'PARKING_ID' },
        { text: '実行時間', value: 'journalTime' },
        { text: '操作種別', value: 'SHORT_LABEL' },
        { text: 'パラメーター', value: 'PARAMATER' }
      ],
      unpaidListHeader:[
        { text: '入庫', value: 'entrantTime' },
        { text: '出庫', value: 'exitTime' },
        { text: '駐車場名', value: 'parkingName' },
        { text: '請求金額', value: 'payArrears' },
        { text: '精算済み', value: 'paidArrears' },
        { text: '未払金額', value: 'unpaidAmount' },
      ],
      tab3selected:[],
      tab3Dialog:false,
      deleteData:[],
      kindId:1,
      reason:""
    };
  },
  methods: {
    paidDelete() :void{
      if (this.tab3selected.length != 0) {
        this.tab3Dialog = true
      } else {
        alert("削除するレコードを選択してください")
      }
    },

    async deleteSubmit(): Promise<void> {
      this.$vloading.show();
      this.tab3Dialog = false
      // 選択したinout_noを配列に収める
      const inOutNoAry = []
      for (const v in this.tab3selected) {
        inOutNoAry.push(this.tab3selected[v]['inoutNo'])
      }
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_delete_payment`;
      const formData = {
        params: {
          server: this.serverId,
          inoutNo: inOutNoAry,
          kindId: this.kindId,
          reason: this.reqReason + this.reason
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        if (res.data.status != 500) {
          alert("成功しました");
          document.location.reload();
        } else {
          alert("更新に失敗しました");
        }
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("更新に失敗しました");
        this.$vloading.hide();
      });
    },
    async operationJournalOpen(id:any,no:any,time:any, name:any, eixt:any, pTime:any, aTotal:any, pTotal:any): Promise<void>{
        this.$vloading.show();
        this.mobileDate = ""
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/operation_show`;
        const formData = { params: {
          id: this.serverId,
          parkingId:   id,
          entrntNo:    no,
          entrntTime:  time,
          payTotal:    pTotal,
          place:       this.place,
          classNumber: this.classNumber,
          kana:        this.kana,
          carNumber:   this.carNumber,
        } };
        return await axios
        .get(url, formData)
        .then(res => {
          console.log(res.data)
          this.operationDate = res.data.operation_journal;
          this.mobileDate = res.data.mobile
          this.checkoutDate = res.data.parking_checkout
          this.operationJournalFormat = res.data.operation_journal_format
          this.operationJournalEntrant = time
          this.operationJournalName = name
          this.operationJournalExit = eixt
          this.backlogParkingTime = pTime
          this.backlogAccountTotal = aTotal
          this.dialog = true
          this.$vloading.hide();
        })
        .catch(error => {
          alert("精算機情報の取得に失敗しました");
          this.$vloading.hide();
        });
      },
    copyToClipBoard(flg): void{
      if (flg == 1) { // 他店舗未払い
        const text = document.getElementById('show_data_read_common');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else if (flg == 2) { // 精算履歴
        const text = document.getElementById('operation_journal');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else if (flg == 3) { // backlog貼付フォーマット
        const text = document.getElementById('backlog_format');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else if (flg == 4) { // 現時点未払請求
        const text = document.getElementById('unpaid_list');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      }
    },
    async getShowData(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.serverId,
                                  place:       this.place,
                                  classNumber: this.classNumber,
                                  kana:        this.kana,
                                  carNumber:   this.carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.showData = res.data;
        console.log(this.showData)
        this.$vloading.hide();
      })
      .catch(error => {
        alert("詳細情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getShowData();
  }
})
