

































































































































import Vue from "vue";
import * as axios from "axios";
import SelectServerList from "../components/forms/SelectServerList.vue";
import serversMixin from "../mixins/serversMixin";

export default Vue.extend({
  mixins: [serversMixin],
  components: { SelectServerList },
  data: () => ({
    valid: true,
    place: "",
    placeRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => !!v?.match(/^([あ-ん]+|[一-鿐ヶ]+)$/) || '使用できない文字が含まれています。',
      (v: any) => !!v?.match(/^[あ-ん]{3}|[一-鿐ヶ]{1,4}$/) || '文字数が異常です。',
    ],
    classNumber: "",
    classNumberRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => !!v?.match(/^[0-9ACFHKLMPXY]+$/) || '使用できない文字が含まれています。',
      (v: any) => !!v?.match(/^[1-9][0-9][0-9ACFHKLMPXY]?$/) || 'フォーマットが異常です。',
    ],
    kana: "",
    kanaRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => v.length === 1 || '１文字で入力してください。',
      (v: any) => !!v?.match(/^[あ-えか-さす-ふほ-を]$/) || '使用できない文字が含まれています。',
    ],
    carNumber: "",
    carNumberRules: [
      (v: any) => !!v || '必須です。',
      (v: any) => !!v?.match(/^[*0-9]+$/) || '使用できない文字が含まれています。',
      (v: any) => v.length === 4 || `4桁未満のナンバーは先頭に*を追加してください。=> ${`***${v}`.slice(-4)}`,
      (v: any) => !!v?.match(/^(\*\*\*[1-9]|\*\*[1-9][0-9]|\*[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/) || 'フォーマットが異常です。',
    ],
    rules: ["required"],
    invalid: false,
    disabled: true,
    result: [],
    kind: 1, //デフォルト 店舗指定,
    kindItems: [{ id: 0, kind: "未払い" }, { id: 1, kind: "店舗指定" }, { id: 2, kind: "警察指定" }, { id: 3, kind: "その他" }],
    alertMailAddress: [''],
    emailRules: [
      (v: any) => !!v || 'メールアドレスを入力してください',
      (v: any) => !!v.match(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/) || '不正なメールアドレスです',
    ],
    alertTitle: '',
    alertText: '',
    registrationResult: [],
    rentalCar: false,
    registrationResultDialog: false,
  }),
  computed: {
    placeIsValid() { return this.placeRules.every(rule => rule(this.place) === true) },
    classNumberIsValid() { return this.classNumberRules.every(rule => rule(this.classNumber) === true) },
    kanaIsValid() { return this.kanaRules.every(rule => rule(this.kana) === true) },
    carNumberIsValid() { return this.carNumberRules.every(rule => rule(this.carNumber) === true) },
    alertMailAddressIsValid() { return this.alertMailAddress.every(email => this.emailRules.every(rule => rule(email) === true)) },
  },
  methods: {
    selectedServers(arr: string): void { this.server.selectedList = arr },
    submit () {
      this.registrationResult = [];
      const user = localStorage.user;
      const parseUser = JSON.parse(user);
      this.$vloading.show();
      const data = {
        place: this.place,
        kana: this.kana,
        classNumber: this.classNumber,
        carNumber: this.carNumber,
        kindId: this.kind,
        alertMailAddress: this.alertMailAddress,
        alertTitle: this.alertTitle || "アラート車両が入庫しました。",
        alertText: this.alertText || "アラート車両が入庫しました。",
        serverId: this.server.selectedList.map(id => id),
        userId: parseUser.id,
      }
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/select_black_lists`
      axios.post(url, data, { headers: this.headers, responseType: 'json' })
      .then((res) => {
        const data = res.data;
        data?.success?.forEach(result => this.registrationResult.push({ server: result, result: true }));
        data?.error?.forEach(result => this.registrationResult.push({ server: result, result: false }));
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
        this.registrationResultDialog = true;
      });
    },
    onDeleteClick(index: number) {
      if (this.alertMailAddress.length === 1) {
        this.alertMailAddress[0] = ''
      } else {
        this.alertMailAddress = this.alertMailAddress.filter((elem: string, i: number) => i !== index)
      }
    },
    onPlusClick() {
      if (this.alertMailAddress.length < 5) {
        this.alertMailAddress.push('')
      }
    },
  },
  mounted() {
    this.getServerInfo(); //serversMixinで定義
  }
});
