






























































import Vue from "vue";
import * as axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import CarNumberInputField from "../components/forms/CarNumberInputField.vue";

export default Vue.extend({
  components: { VueJsonToCsv, CarNumberInputField },
  data: () => ({
    place: "",
    classNumber: "",
    kana: "",
    carNumber: "",
    disabled: true,
    result: [],
    labels: {
      SHOP_NAME: { title: "物件名" },
      PARKING_ID: { title: "駐車場ID" },
      PARKING_NAME: { title: "駐車場名" },
      CAR_INFO: { title: "車両情報" },
      KIND_LABEL: { title: "種別" },
      NOTE: { title: "備考" }
    },
    headersArray: [
      { text: "物件名", value: "SHOP_NAME" },
      { text: "駐車場名", value: "PARKING_NAME" },
      { text: "車両情報", value: "CAR_INFO" },
      { text: "種別", value: "KIND_ID" },
      { text: "備考", value: "NOTE" }
    ],
    itemsPerPage: 1000
  }),
  methods: {
    async submit(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_black_lists`;
      const formData = {
        place: this.place,
        classNumber: this.classNumber,
        kana: this.kana,
        carNumber: this.carNumber
      };
      const headers = { "Content-Type": "application/json" };
      return await axios
        .post(url, formData, { headers: headers, responseType: "json" })
        .then(res => {
          this.result = res.data;
          if (this.result.length == 0) {
            this.disabled = true;
            alert("対象のデータがありませんでした。");
            this.$vloading.hide();
            return;
          }
          this.disabled = false;
          this.$vloading.hide();
        })
        .catch(err => {
          this.$vloading.hide();
          alert("データの取得に失敗しました。\n再度実行してください。");
          console.log(err);
        });
    }
  }
});
