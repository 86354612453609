








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    selectParking:                        {},
    startDate:                            {},
    endDate:                              {},
    inputNumber:                          {},
    researchTypeFlg:                      {},
    researchStepFlg:                      {},
    researchEndFlg:                       {},
    problemFlg:                           {},
    type1NumberCheckCompletedListsStep1:   {},
    type1SeisankiCheckCompletedListsStep1: {},
    NumberCheckLists:                      {},
    numberCheckHeaders:                    {},
    numberImgCheckHeaders:                 {},
    seisankiCheckHeaders:                  {},
    footerProps:                           {},
    type1SeisankiCheckLists:               {},
    type1NumberCheckCompletedListsStep2:         {},
    type1SeisankiCheckCompletedListsStep2:       {},
    type1InOutMaintenancesCompletedCheckLists:   {},
    type1InOutMaintenancesCheckLists: {},
    deleteCheckHeaders:                          {},
    inOutMaintenancesCheckHeaders:               {},
    type1NumberCheckCompletedListsStep3:               {},
    type1SeisankiCheckCompletedListsStep3:               {},
    uploadCheckHeaders:               {},
    type1NumberCheckDisplayLists: {},
    type1NumberCheckPagination: {},
    type1NumberCheckPaginationPageSize: {},
    type1NumberCheckPaginationLength: {},
    type1NumberCheckCompletedLists: {},
    type1CameraCheckLists: {},
    type1CameraCheckCompletedLists: {},
    cameraCheckHeaders: {},
    cameraCheckCompletedHeaders:{},
    hoseiImgUrl:{},
    type1HoseiCheckLists:{},
    type1HoseiCheckCompletedListsHosei:{},
    type1HoseiCheckCompletedListsHoseiSP:{},
    type1HoseiCheckCompletedListsDaburi:{},
    hoseiCheckHeaders01:{},
    hoseiCheckHeaders02:{},
    hoseiCheckHeaders03:{},
    type1SurroundingsCheckLists:{},
    type1SurroundingsCheckStart:{},
    type1SurroundingsCheckEnd:{},
  },

  computed: {
    type1NumberCheckCompletedListsStep1Value:            { get () { return this.type1NumberCheckCompletedListsStep1 },            set (newVal) { if (this.type1NumberCheckCompletedListsStep1 !== newVal)            this.$emit('ChangeType1NumberCheckCompletedListsStep1', newVal) }},
    type1SeisankiCheckCompletedListsStep1Value:            { get () { return this.type1SeisankiCheckCompletedListsStep1 },            set (newVal) { if (this.type1SeisankiCheckCompletedListsStep1 !== newVal)            this.$emit('ChangeType1SeisankiCheckCompletedListsStep1', newVal) }},
    type1NumberCheckCompletedListsStep2Value:            { get () { return this.type1NumberCheckCompletedListsStep2 },            set (newVal) { if (this.type1NumberCheckCompletedListsStep2 !== newVal)            this.$emit('ChangeType1NumberCheckCompletedListsStep2', newVal) }},
    type1SeisankiCheckCompletedListsStep2Value:            { get () { return this.type1SeisankiCheckCompletedListsStep2 },            set (newVal) { if (this.type1SeisankiCheckCompletedListsStep2 !== newVal)            this.$emit('ChangeType1SeisankiCheckCompletedListsStep2', newVal) }},
    type1InOutMaintenancesCompletedCheckListsValue:            { get () { return this.type1InOutMaintenancesCompletedCheckLists },            set (newVal) { if (this.type1InOutMaintenancesCompletedCheckLists !== newVal)            this.$emit('ChangeType1InOutMaintenancesCompletedCheckLists', newVal) }},
    type1NumberCheckCompletedListsStep3Value:            { get () { return this.type1NumberCheckCompletedListsStep3 },            set (newVal) { if (this.type1NumberCheckCompletedListsStep3 !== newVal)            this.$emit('ChangeType1NumberCheckCompletedListsStep3', newVal) }},
    type1SeisankiCheckCompletedListsStep3Value:            { get () { return this.type1SeisankiCheckCompletedListsStep3 },            set (newVal) { if (this.type1SeisankiCheckCompletedListsStep3 !== newVal)            this.$emit('ChangeType1SeisankiCheckCompletedListsStep3', newVal) }},
    type1NumberCheckCompletedListsValue:            { get () { return this.type1NumberCheckCompletedLists },            set (newVal) { if (this.type1NumberCheckCompletedLists !== newVal)            this.$emit('ChangeType1NumberCheckCompletedLists', newVal) }},
    type1NumberCheckPaginationValue:            { get () { return this.type1NumberCheckPagination },            set (newVal) { if (this.type1NumberCheckPagination !== newVal)            this.$emit('ChangeType1NumberCheckPagination', newVal) }},
    type1CameraCheckCompletedListsValue:            { get () { return this.type1CameraCheckCompletedLists },            set (newVal) { if (this.type1CameraCheckCompletedLists !== newVal)            this.$emit('ChangeType1CameraCheckCompletedLists', newVal) }},
    type1HoseiCheckCompletedListsHoseiValue:            { get () { return this.type1HoseiCheckCompletedListsHosei },            set (newVal) { if (this.type1HoseiCheckCompletedListsHosei !== newVal)            this.$emit('ChangeType1HoseiCheckCompletedListsHosei', newVal) }},
    type1HoseiCheckCompletedListsHoseiSPValue:            { get () { return this.type1HoseiCheckCompletedListsHoseiSP },            set (newVal) { if (this.type1HoseiCheckCompletedListsHoseiSP !== newVal)            this.$emit('ChangeType1HoseiCheckCompletedListsHoseiSP', newVal) }},
    type1HoseiCheckCompletedListsDaburiValue:            { get () { return this.type1HoseiCheckCompletedListsDaburi },            set (newVal) { if (this.type1HoseiCheckCompletedListsDaburi !== newVal)            this.$emit('ChangeType1HoseiCheckCompletedListsDaburi', newVal) }},
    type1SurroundingsCheckStartValue:            { get () { return this.type1SurroundingsCheckStart },            set (newVal) { if (this.type1SurroundingsCheckStart !== newVal)            this.$emit('ChangeType1SurroundingsCheckStart', newVal) }},
    type1SurroundingsCheckEndValue:            { get () { return this.type1SurroundingsCheckEnd },            set (newVal) { if (this.type1SurroundingsCheckEnd !== newVal)            this.$emit('ChangeType1SurroundingsCheckEnd', newVal) }},
  },

  methods: {
    normalEnd(number): void{
      this.$emit('normal-end', number)
    },
    // 調査終了
    reload(): void{
      document.location.reload()
    },
    // 補正画像取得
    hoseiImgview(url): void{
      this.$emit('hosei-img-view', url)
    },

    backToStep6(): void{
      this.$emit('back-to-step6',6,0,[],[],[],"")
    },

    type01PaginationMove(number): void{
      this.$emit('type01-pagination-move',number)
    },

    // 入出庫あり [step2]削除記録調査へ移行
    async type01InOutMaintenancesCheckBtn(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_in_out_maintenances_check`;
      const formData = { params: { startDate: this.startDate, selectParking: this.selectParking, endDate: this.endDate} };
      return await axios
      .post(url, formData.params)
      .then(res => {
        this.$emit('type01-in-out-maintenances-check-start', res.data, 2)
        this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

      // 入出庫あり [step3]アップロード遅延調査へ移行
        type01UploadCheckBtn(): void{
          this.$emit('type01-upload-check-start', 3)
        },

      // 入出庫あり [step4]車両画像調査へ移行
      type01VehicleCheckBtn(): void{
        this.$emit(
                    'type01-vehicle-check-start',
                    this.NumberCheckLists.slice(this.type1NumberCheckPaginationPageSize*(this.type1NumberCheckPagination - 1), this.type1NumberCheckPaginationPageSize*(this.type1NumberCheckPagination)),
                    Math.ceil(this.NumberCheckLists.length/this.type1NumberCheckPaginationPageSize),
                    4
                    )
      },

    // 入出庫あり[step5]カメラ管制調査へ移行
    async type01CameraCheckStartBtn(eventFlg): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_camera_check`;
      const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
      return await axios
      .post(url, formData.params)
      .then(res => {
        this.$emit('type01-camera-check-start', res.data, 5, eventFlg)
        this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

    // 入出庫あり[step6]補正内容調査へ移行
    async type01HoseiCheckStartBtn(): Promise<void>{
      this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_hosei_check`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit('type01-hosei-check-start', res.data, 6)
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

    // 入出庫あり[step7]撮影環境調査へ移行
    async type01SurroundingsCheckStartBtn(): Promise<void>{
      this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/not_number_research_surroundings_check`;
        const formData = { params: { startDate: this.startDate, inputNumber: this.inputNumber, selectParking: this.selectParking, endDate: this.endDate} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          this.$emit('type01-surroundings-check-start', res.data, 7)
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("検索に失敗しました");
          this.$vloading.hide();
        });
      },

  },

})
