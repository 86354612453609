


























import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      placeValue: "",
      classValue: "",
      kanaValue: "",
      carValue: ""
    }
  },
  props: ["addVali"]
  ,
  computed: {
    place: {
      get(){
        return this.placeValue
      },
      set(newValue){
        this.placeValue = newValue
        this.$emit('place-data', newValue)
      }
    },
    classNumber: {
      get(){
        return this.classValue
      },
      set(newValue){
        this.classValue = newValue
        this.$emit('class-data', newValue)
      }
    },
    kana: {
      get(){
        return this.kanaValue
      },
      set(newValue){
        this.kanaValue = newValue
        this.$emit('kana-data', newValue)
      }
    },
    carNumber: {
      get(){
        return this.carValue
      },
      set(newValue){
        this.carValue = newValue
        this.$emit('car-data', newValue)
      }
    }
  }
})
