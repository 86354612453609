




































































































import Vue    from "vue";
import axios  from "axios";
import Modal  from "../components/modals/UnpaidCarDetails.vue"; // モーダルウインドウの表示

interface Servers {
  servers:        any[];
  cars:           any[];
  keyword:        string;
  number:         string;
  searchCars:     boolean;
  selectParking:  boolean;
  showModal:      boolean;
}

export default Vue.extend({

  name: "App",
  components: { Modal },

  data(): Servers { 
    return { 
      servers:        [],     // はじめに取得する全サーバーの配列
      cars:           [],     // 検索した未払い車両の配列

      currentServer:  "",     // 選択したサーバーのID取得用文字列
      keyword:        "",     // 【物件】検索用文字列
      number:         "",     // 【車両】検索用文字列
      serverNote:     "",     // 選択中物件の物件名表示用文字列

      selectParking:  false,   // ユーザーの検索操作で、部分的なビューの表示/非表示を決定するスイッチ その１
      searchCars:     false,   // ユーザーの検索操作で、部分的なビューの表示/非表示を決定するスイッチ その２
      showModal:      false,   // モーダルウインドウの表示＆非表示の切替スイッチ

      currentCar:{ // モーダルウインドウに持っていくパラメーター（→ CommonsManagementNew/front/src/components/modals/UnpaidCarDetails.vue）
        place: "", classNumber: "", kana: "", carNumber: "", image: "", serverid: "",
      },
    };
  },

  computed: {
    filteredServers(): any[] { // 【Server.vueコピー】全サーバーを配列化する
      const servers = [];
      console.log(this.servers)
      for (const i in this.servers) {
        const server = this.servers[i];
        if (server.note.indexOf(this.keyword) !== -1) {
          servers.push(server);
        }
      }
      return servers;
    },
  },

  methods: {
    async getServerInfo(): Promise<void> { // 【Server.vueコピー】全物件をリスト化するためのajax通信
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.servers = res.data;
      })
      .catch(error => {
        alert("サーバー情報の取得に失敗しました！");
      });
    },
    async getUnpaidInfo(): Promise<void> { // 検索した未払い車両を表示するためのajax通信
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_unpaid_reserchs`;
      const formData = {
          params: {
          carNumber: this.number,                   // SQLで使用する（入力した４桁ナンバー）
          server:    this.currentServer,            // サーバーデータベースを取得のため使用する（選択した物件のid）
        }
      }
      return await axios
      .get(url, formData) 
      .then(res => {                                // 成功
        console.log(res);                           // consoleに記録を残す
        this.cars = res.data;                       // cars[]にデータをいれる
        this.searchCars = true;                     // 車両一覧ビューを表示させる
      })
      .catch(error => {                              // 失敗
        console.log("サーバーへの接続に失敗しました！")   // consoleに記録を残す
        this.searchCars = false;                    // 車両一覧ビューを消す
      });
    },
    selectToParking(elm: string, elm2: string): void { // 検索時の操作で部分的なビューの表示/非表示を決定するスイッチ（物件を選択した時）
      this.selectParking = true;
      this.searchCars    = false;
      this.number        = "";
      this.cars          = [];
      this.currentServer = elm;
      this.serverNote    = elm2;
      this.showModal     = false;
    },
    searchToParking(): void { // 検索時の操作で部分的なビューの表示/非表示を決定するスイッチ（物件検索フォームにフォーカスした時）
      this.selectParking = false;
      this.searchCars    = false;
      this.number        = "";
      this.cars          = [];
      this.showModal     = false;
    },
    openModal(place: string, classNumber: string, kana: string, carNumber: string, image: string): void{ // モーダル表示イベント（必要情報を取得してウインドウをスイッチをONにする）
      this.currentCar.place       = place;
      this.currentCar.classNumber = classNumber;
      this.currentCar.kana        = kana;
      this.currentCar.carNumber   = carNumber;
      this.currentCar.image       = image;
      this.currentCar.serverid    = this.currentServer;
      this.showModal              = true;
    },

  },

  mounted() {
    this.getServerInfo();
  }

});
