
























































































import Vue from "vue";
import * as axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import SelectServerList from "../components/forms/SelectServerList.vue";
import CarNumberInputField from "../components/forms/CarNumberInputField.vue";
import RentalCarCheckBox from "../components/forms/RentalCarCheckBox.vue";
import ParkingTimeRangeField from "../components/forms/ParkingTimeRangeField.vue";
import ParkingDateRangeField from "../components/forms/ParkingDateRangeField.vue";
import serversMixin from "../mixins/serversMixin";

Vue.use(VueJsonToCsv);

export default Vue.extend({
  mixins: [serversMixin],
  components: { VueJsonToCsv, SelectServerList, CarNumberInputField, RentalCarCheckBox, ParkingTimeRangeField, ParkingDateRangeField },
  data: () => ({
    dateRange: {
      start: "",
      startLabel: "開始日",
      end: "",
      endLabel: "終了日"
    },
    timeRange: {
      start: "",
      startLabel: "駐車時間(開始・分)",
      end: "",
      endLabel: "駐車時間(終了・分)"
    },
    valid: true,
    place: "",
    classNumber: "",
    kana: "",
    carNumber: "",
    rules: ["required"],
    invalid: false,
    disabled: true,
    result: [],
    labels: {
      PARKING_NAME_1: { title: "物件名" },
      ENTRANT_TIME:   { title: "入庫日時" },
      EXIT_TIME:      { title: "出庫日時" },
      PARKING_TIME:   { title: "駐車時間(分)" },
      CAR_INFO:       { title: "車両情報" },
      PAY_ARREARS:    { title: "未払い請求金額" },
      PAID_ARREARS:   { title: "支払い済未払い金額" }
    },
    rentalCar: false
  }),
  methods: {
    selectedServers(arr: string): void { this.server.selectedList = arr },
    async submit(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_unpaid_records`;
      const formData = {
        startDate:   this.dateRange.start,
        endDate:     this.dateRange.end,
        servers:     this.server.selectedList,
        startTime:   this.timeRange.start,
        endTime:     this.timeRange.end,
        place:       this.place,
        classNumber: this.classNumber,
        kana:        this.kana,
        carNumber: this.carNumber,
        rentalCar: this.rentalCar
      };
      const headers = { "Content-Type": "application/json" };
      return await axios
        .post(url, formData, { headers: headers, responseType: "json" })
        .then(res => {
          this.result = res.data;
          if (this.result.length == 0) {
            this.disabled = true;
            alert("対象のデータがありませんでした。");
            this.$vloading.hide();
            return;
          }
          this.disabled = false;
          this.$vloading.hide();
        })
        .catch(err => {
          this.$vloading.hide();
          alert("データの取得に失敗しました。");
          console.log(err);
        });
    }
  },
  mounted() {
    this.getServerInfo(); //serversMixinで定義
  }
});
