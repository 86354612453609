
























































































































































































































import axios from "axios";
export default {

  data: () => ({
    parkings: [],
    records: [],
    dialog:false,
    selectParking: null,
    kindId:1,
    startTime:"",
    exitTime:"",
    exitTimeFlg:false,
    result: [],
    headers: [
        { text: 'parking_id', value: 'parking_id'},
        { text: '駐車場名', value: 'parking_name' },
        { text: 'アカウント名', value: 'account_name' },
        { text: '作成時間', value: 'create_date' },
        { text: 'ログ', value: 'message' }
      ],
  }),

  props: {
    selectParkings: {},
  },

  methods: {
    onClickList(item:object) {
      this.records = [];
      this.selectParking = item;
      this.dialog = true;
    },
    // 物件の設定を取得する
    async getLocalSettings(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/trouble_shootings`;
      const formData = { params: { serverId: this.selectParkings} };
      return await axios
      .get(url, formData)
      .then((res: { data: object; }) => {
        this.parkings = res.data
      })
      .catch((error: any) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // 精算機のアラートログ
    async getAlertLog(num:number): Promise<void> {
      this.result = [];
      console.log(num)
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/trouble_shootings/alert_log`;
      const formData = { params: { serverId: this.selectParkings, type: num} };
      return await axios
      .get(url, formData)
      .then((res: { data: object; }) => {
        this.result = res.data
      })
      .catch((error: any) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // 請求を取り消す
    async listUpdate(): Promise<void> {
      if (window.confirm('請求を取り消しますか？')) {
        this.$vloading.show();
        // 選択したinout_noを配列に収める
        const inOutNoAry = []
        for (const v in this.records) {
          inOutNoAry.push(this.records[v]['INOUT_NO'])
        }
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_delete_payment`;
        const formData = {
          params: {
            server: this.selectParkings,
            inoutNo: inOutNoAry,
            kindId: this.kindId,
            reason: "【コモンズ時間帯削除】" + JSON.parse(localStorage.user).name
          }
        };
        return await axios
        .post(url, formData.params)
        .then(res => {
          if (res.data.status != 500) {
            alert("成功しました");
            document.location.reload();
          } else {
            alert("更新に失敗しました");
          }
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("更新に失敗しました");
        })
        .finally(() => {
          this.$vloading.hide();
        });
      }
    },

    // 該当の車両を出力する
    async show(
      serverId: number,
      parkingId: number,
      serverType: boolean | null,
      exitTimeFlg: boolean,
      startTime: string,
      exitTime: string
    ): Promise<void> {
      this.$vloading.show();
      this.records = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/trouble_shootings/search_by_time_range`;
      const formData = {
        params: {
          serverId: serverId,
          parkingId: parkingId,
          serverType: serverType,
          exitTimeFlg: exitTimeFlg,
          startTime: startTime,
          exitTime: exitTime
        } 
      };
      return await axios
      .get(url, formData)
      .then((res: { data: object; }) => {
        if (res.data.length != 0) {
          this.records = res.data;
        } else {
          alert("対象車両が見つかりませんでした");
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
}
