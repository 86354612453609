import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import qs from 'qs'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    vehiclesInformationList: [],
    vehiclesInformationInput: [],
    serverDate: [],
    selectParking:[],
  },
  getters: {
    isAuthenticated: state => !!state.user
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clearUser(state) {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("x-csrf-token");
    },
    setVehiclesInformationList (state, searchList) {
      state.vehiclesInformationList = searchList
    },
    vehiclesInformationInput (state, input) {
      state.vehiclesInformationInput = input.params
    },
    serverDate (state, data) {
      state.serverDate = data
    },
    selectParking (state, data) {
      state.selectParking = data
    },
  },
  actions: {
    async signIn({ commit }, { userName, password }) {
      const params = { session: { name: userName, password: password } }
      await axios.post("session/", qs.stringify(params)
        , { withCredentials: true }
      )
        .then((res) => {
          commit("setUser", res.data);
        })
    },
    async signOut({ commit }) {
      await axios.delete("session", { withCredentials: true })
      .then(() => {
        commit("clearUser")
        // this.$router.push("/")
      })
    },
    clearUserObject({ commit }) {
      commit("clearUser")
    },
    async gerUserSession({ commit }) {      
      await axios.get("session/")
      .then((res) => { commit("setUser", res.data) })
      .catch(() => {
        location.href = "/";
        commit("clearUser")
      })
    },
  },
  modules: {}
});
