








































































import Vue from "vue";
import axios from "axios";

interface Car {
  cars:           any[];
  selectedCars:   any[];
  place:          string;
  classNumber:    string;
  kana:           string;
  carNumber:      string;
  server:         string;
  totalPayArears: number;
}

export default Vue.extend({

  name: "unpaid-car-details",

  data(): Car { 
    return { 
      cars:           [],
      place:          "",
      classNumber:    "",
      kana:           "",
      carNumber:      "",
      totalPayArears: 0,
    };
  },

  props: {
    selectedCars: { }, // モーダルの表示元からデータを取得する（OutputUnpaidResearch.vue より）
  },

  methods: {
      async getUnpaidCar(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_unpaid_reserchs_show`;
      const formData = {
        params: {
          place:        this.selectedCars.place,
          classNumber:  this.selectedCars.classNumber,
          kana:         this.selectedCars.kana,
          carNumber:    this.selectedCars.carNumber,
          server:       this.selectedCars.serverid,
        }
      }
      return await axios
      .get(url, formData)
      .then(res => {
        console.log(res);
        this.cars = res.data;
        for (const i in this.cars) { // 取得した未払いのPAY_ARREARSの合計を計算 → ビューで使用
          this.totalPayArears = this.totalPayArears + this.cars[i].PAY_ARREARS;
        }
        console.log("未払い情報の取得に成功しました！");
      })
      .catch(error => {
        alert("未払い情報の取得に失敗しました！");
      });
    },
  },

  mounted() {
    this.getUnpaidCar();
  }
});

