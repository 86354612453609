














































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Index {
  time: number;
  headers: any[];
  result: any[];  
}
export default Vue.extend({
  data(): Index {
      return {
        time: 48,
        headers: [
          { text: '駐車時間', value: 'PARKING_TERM' },
          { text: '入庫時間', value: 'entrant_time' },
          { text: '駐車場名', value: 'PARKING_NAME_1' },
          { text: '入口名', value: 'EXT_ENTR_NAME' },
          { text: 'ナンバー', value: 'CAR_NUMBER' },
        ],
        result:[]
      };
    },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  },
  filters: {
    formatTime(value: number) {
      if (!value) return '0分';
      const days = Math.floor(value / 86400);
      const hours = Math.floor((value % 86400) / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      let formattedTime = '';
      if (days > 0) {
        formattedTime += days + '日';
      }
      if (hours > 0) {
        formattedTime += hours + '時間';
      }
      if (minutes > 0) {
        formattedTime += minutes + '分';
      }
      return formattedTime || '0分';
    }
  },
  methods: {
      async send(): Promise<void> {
        if (this.selectServer) {
          this.result = [];
          this.$vloading.show();
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/commons/long_term_parking_vehicle`;
          const formData = {
            params: { server: this.selectServer, time: this.time }
          }
          return await axios
          .get(url, formData)
          .then(res => {
            this.result = res.data;
          })
          .catch(error => {
            console.log(error)
            alert("情報の取得に失敗しました");
          })
          .finally(() => {
            this.$vloading.hide()
          });
        } else {
          alert("物件を選択してください");
        }
      },
    },
});
