













































import Vue from "vue";
import axios from "axios";
export default {
  props: {
    selectParkings: {},
    getWhiteListDeletePeriodLoading: {},
    whiteListDeletePeriod: {},
  },
  computed: {
    whiteListDeletePeriodValue: {
      get: function () { return this.whiteListDeletePeriod },
      set: function (newValue) { this.$emit("changeWhiteListDeletePeriod", newValue) }
    }
  },
  methods: {
    async updateWhiteListDeletePeriod(): Promise<void> {
      if( this.$refs.updateform.validate() ) {
        const result = window.confirm('更新しますか？')
        if (result) {
          const url = `${process.env.VUE_APP_API_URL_BASE}/api/update_white_list_delete_period`;
          const formData = { params: { id: this.selectParkings, whiteListDeletePeriod: this.whiteListDeletePeriodValue } };
          return await axios
          .post(url, formData.params)
          .then(res => {
            alert("更新しました")
            this.$emit('reload')
          })
          .catch(error => {
            console.log(error)
            alert("更新に失敗しました")
            this.$emit('reload')
          });
        }
      }
    },
    reload () {
      this.$emit('reload')
    },
  }
}
