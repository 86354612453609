import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';
import qs from 'qs';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    WebUI: {
      server: "",
    },
  },
  mutations: {
    setServer(state, server) {
      state.WebUI.server = server;
    },
  },
  getters: {
    server(state) {
      return state.WebUI.server;
    }
  }
});
