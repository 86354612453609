import Vue from 'vue';
import * as axios from "axios";
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'

interface Server {
  server: {
    list: object[]
    selectedList: object[],
    selectedAll: boolean,
    keyword: string
  }
}

export default Vue.extend({
  name: "serversMixin",
  data(): Server {
    return {
      server: {
        list: [],
        selectedList: [],
        selectedAll: false,
        keyword: ""
      },
    }
  },
  methods: {
    async getServerInfo(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
        .get(url)
        .then(res => { this.server.list = res.data; })
        .catch(error => {
          alert("サーバー情報の取得に失敗しました！");
          console.log(error);
      });
    },
  },
});