
























import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      querytextaVlue: "",
      height:"60px",
    }
  },
  props: {
  },
  computed: {
    querytext: {
      get(){
        return this.querytextaVlue;
      },
      set(newValue){
        this.querytextaVlue = newValue
        this.$emit('querytext-data', newValue)
      }
    },
    styles(){
      return {
          "height": this.height,
      }
    }    
  }
})
