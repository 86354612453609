






































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dateValue: ""
      }
  },
  props: {
    dateLabel: {
      required: false
    }
  },
  computed: {
    dateRange: {
      get(){
        return this.dateValue
      },
      set(newValue){
        this.dateValue = newValue
        this.$emit('date-event', newValue)
      }
    }
  }
})
