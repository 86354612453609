





















































import Vue from "vue";
import axios from "axios";

interface User {
  loginUser: number;
  dialog:boolean;
  dialogColor:number;
  dialogName:string;
  dialogDepartment:number;
  dialogRealName:string;
  dialogStart:string;
  dialogEnd:string;
  dialogDetails:string;
  colors:any;
}

export default Vue.extend({
  data(): User {
    return {
      loginUser: JSON.parse(localStorage.user).id,
      dialog:false,
      dialogColor:0,
      dialogName:"",
      dialogDepartment:0,
      dialogRealName:"",
      dialogStart:"",
      dialogEnd:"",
      dialogDetails:"",
      colors: ['#2E2D36', '#2E2D36', '#C41827', '#EDC309', '#035BD5', '#D85D98'],
    }
  },
  props: {
    notification: {}
  },
  methods: {
    // SQL実行
    async destroy(id:number): Promise<void>{
      if (!window.confirm('お知らせ削除の注意点\n\nお知らせの削除は「コモンズの表示」を削除するのみであり、すでに送信済の「チームスの投稿」や、「メールの送信」は取り消せません。\n誤ったお知らせを送信してしまっていた場合、お知らせの削除と共に、訂正のお知らせも送信していただくよう、よろしくお願いします。\n\nOKを押すと削除を実施します。')) return
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/notifications/${id}`;
      return await axios
      .delete(url)
      .then(res => {
        alert("削除しました");
      })
      .catch(error => {
        alert("失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
        document.location.reload(); //$emitがなぜか効かなかったのでリロード
      });
    },
    show(item:object){
      console.log(item)
      this.dialogColor = item.department;
      this.dialogName = item.title;
      this.dialogDepartment = item.department;
      this.dialogRealName = item.realName;
      this.dialogStart = item.start;
      this.dialogEnd = item.end;
      this.dialogDetails = item.text;
      this.dialog = true;
    },
  },
})
