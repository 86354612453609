














































































































































































































































































import Vue from "vue";
import axios from "axios";

interface Backlogs {
  // 全体で使用
    parkings:                              any[];   // セレクトボックスなどで使用する物件を保管する配列（自動取得）
    todayValidation:                       string;  // バリデーション等で使用するために取得する本日の日付の文字列（自動取得）
    todayView:                             string;  // viewで表示するための本日の日付の文字列（自動取得）

  //【CSV出力】 物件別 入電率集計
    typeRatioStartDate:                    string;  // 入電率集計の始点日付の文字列
    typeRatioEndDate:                      string;  // 入電率集計の終点日付の文字列

  //【CSV出力】 物件別 入電カテゴリ数集計
    typeIssueStartDate:                    string;  // 入電カテゴリ数集計の始点日付の文字列
    typeIssueEndDate:                      string;  // 入電カテゴリ数集計の始点日付の文字列
    selectedParking:                       any[];   // v-selectを使用しない、セレクトボックスで選択された物件を保管する配列
    keyword:                               string;  // v-selectを使用しない、セレクトボックスで検索キーワード文字列
    isAllSelected:                         boolean; // 全選択を確認するスイッチ

  //【Excel出力】物件別 入電推移集計
    encodeUrl:                             string;  // 選択した物件idをencodeURIで変換するために保存する文字列
    typeCallTransitionAggregateGroups:     any[];   // ユーザー別に登録してある設定データを保管する配列
    // 指定して出力(tab_01)
    typeCallTransitionSelectedParking:     any[];   // 選択した物件ID一覧を保管する配列
    typeCallTransitionStartDate:           string;  // 入電推移集計の始点日付の文字列
    typeCallTransitionEndDate:             string;  // 入電推移集計の終点日付の文字列
    // 登録済みの設定で出力(tab_02)
    typeCallTransitionSelectRecord:        string;  // 選択した設定のレコードIDの文字列
    typeCallTransitionStartDateSelectVer:  string;  // 入電推移集計の始点日付の文字列（登録済みの設定で出力）
    typeCallTransitionEndDateSelectVer:    string;  // 入電推移集計の終点日付の文字列（登録済みの設定で出力）
    // 登録(tab_03)
    typeCallTransitionRegisterParking:     any[];   // 登録する設定の物件ID一覧を保管する配列
    typeCallTransitionRegisterTitle:       string;  // 登録する設定のタイトル
    // 追加(tab_04)
    typeCallTransitionUpdateParking:         any[];   // 追加を行う設定のID一覧を保管する配列
    typeCallTransitionUpdateRecord:          string;  // 追加を行う設定のIDの文字列
    // 削除(tab_05)
    typeCallTransitionDeleteRecord:        string;  // 削除する設定のレコードID文字列
}

export default Vue.extend({
  name: "backlog-aggregate",

  data(): Backlogs {
    return {
      todayValidation:                      "",
      todayView:                            "",
      selectedParking:                      [],
      isAllSelected:                        false,
      parkings:                             [],
      keyword:                              "",
      typeRatioStartDate:                   "",
      typeRatioEndDate:                     "",
      typeCallTransitionStartDateSelectVer: "",
      typeCallTransitionEndDateSelectVer:   "",
      typeCallTransitionStartDate:          "",
      typeCallTransitionEndDate:            "",
      typeCallTransitionSelectedParking:    [],
      typeCallTransitionRegisterParking:    [],
      typeCallTransitionRegisterTitle:      "",
      typeCallTransitionAggregateGroups:    [],
      typeCallTransitionDeleteRecord:       "",
      typeCallTransitionSelectRecord:       "",

      typeCallTransitionUpdateParking:        [],
      typeCallTransitionUpdateRecord:         "",

      encodeUrl:                            "",
      typeIssueStartDate:                   "",
      typeIssueEndDate:                     "",
    };
  },

  computed: {
    // 【CSV出力】期間指定：カテゴリ / 物件別 入電課題集計 の物件検索
    filteredParkings(): any[] {
      const searchParking = []
      console.log(this.parkings)
      for (const i in this.parkings) {
        const parking = this.parkings[i];
        if (parking.parking_name.indexOf(this.keyword) !== -1) {
          searchParking.push(parking);
        }
      }
      return searchParking;
    },
  },

  methods: {
    // 検索フォームの表示 / 非表示
    Form001Open(): void {
      const target = document.getElementById("search-component-001");
      const p1 = document.getElementById("search-form-icon-001");
      if (this.searchFormShow == true) {
        target.style.height = "28px";
        p1.className = 'v-icon notranslate v-icon--link mdi mdi-arrow-down-drop-circle theme--dark';
        this.searchFormShow = false;
      } else {
        target.style.height = "150%";
        p1.className = 'v-icon notranslate v-icon--link mdi mdi-close theme--dark';
        this.searchFormShow = true;
      }
    },
    // 検索フォームの表示 / 非表示
    Form002Open(): void {
      const target = document.getElementById("search-component-002");
      const p1 = document.getElementById("search-form-icon-002");
      if (this.searchFormShow == true) {
        target.style.height = "28px";
        p1.className = 'v-icon notranslate v-icon--link mdi mdi-arrow-down-drop-circle theme--dark';
        this.searchFormShow = false;
      } else {
        target.style.height = "100%";
        p1.className = 'v-icon notranslate v-icon--link mdi mdi-close theme--dark';
        this.searchFormShow = true;
      }
    },
    // 検索フォームの表示 / 非表示
    Form003Open(): void {
      const target = document.getElementById("search-component-003");
      const p1 = document.getElementById("search-form-icon-003");
      if (this.searchFormShow == true) {
        target.style.height = "28px";
        p1.className = 'v-icon notranslate v-icon--link mdi mdi-arrow-down-drop-circle theme--dark';
        this.searchFormShow = false;
      } else {
        target.style.height = "100%";
        p1.className = 'v-icon notranslate v-icon--link mdi mdi-close theme--dark';
        this.searchFormShow = true;
      }
    },
    // 日付けのフォームは直接入力を許可しない
    onFocus(): void {
      const elem = document.activeElement;
      elem.blur();
    },
    // チェックボックス全選択
    allSelected(): void {
      let arr: any[] = [];
      if (this.isAllSelected) {
        this.parkings.map(parking => arr.push(parking.parking_name));
      } else {
        arr = [];
      }
      this.selectedParking = arr
      console.log(arr)
      console.log(this.isAllSelected)
    },
    // チェックボックスが１つでも外れると全選択チェックボックスが解除
    allSelectedValidation(): void {
      if (this.selectedParking.length - 1 !== this.parkings.length) {
        this.isAllSelected = false
      } else {
        this.isAllSelected = true
      }
      console.log(this.isAllSelected)
    },
    // 【Excel出力】物件別 入電推移集計(指定して出力 日別出力)
    callTransitionExcelBtn(): void{
      if (this.typeCallTransitionSelectedParking == "" || this.typeCallTransitionStartDate == "" || this.typeCallTransitionEndDate == "") {
        alert('必要項目が入力されていません。');
      } else {
        this.encodeUrl = encodeURI(this.typeCallTransitionSelectedParking);
        const link = document.createElement('a');
        link.href = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_call_transition_excel?startDate=${this.typeCallTransitionStartDate}&endDate=${this.typeCallTransitionEndDate}&parkingId=${this.encodeUrl}`;
        console.log(link.href);
        link.setAttribute('download', 'backlog.xlsx');
        document.body.appendChild(link);
        link.click();
      }
    },
    // 【Excel出力】物件別 入電推移集計(指定して出力 週別出力)
    callTransitionExcelBtn2(): void{
      if (this.typeCallTransitionSelectedParking == "" || this.typeCallTransitionStartDate == "" || this.typeCallTransitionEndDate == "") {
        alert('必要項目が入力されていません。');
      } else {
        this.encodeUrl = encodeURI(this.typeCallTransitionSelectedParking);
        const link = document.createElement('a');
        link.href = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_call_transition_excel?startDate=${this.typeCallTransitionStartDate}&endDate=${this.typeCallTransitionEndDate}&parkingId=${this.encodeUrl}&outputType=2`;
        link.setAttribute('download', 'backlog.xlsx');
        document.body.appendChild(link);
        link.click();
      }
    },
    // 【Excel出力】物件別 入電推移集計(登録済みの設定で出力 日別出力)
    async callTransitionExcelBtnSelectVer(): Promise<void>{
      if (this.typeCallTransitionSelectRecord == "" || this.typeCallTransitionStartDateSelectVer == "" || this.typeCallTransitionEndDateSelectVer == "") {
        alert('必要項目が入力されていません。');
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_get_parking_ids`;
        const formData = { params: { id: this.typeCallTransitionSelectRecord} };
        return await axios
        .get(url, formData)
        .then(res => {
          console.log(res)
          this.encodeUrl = encodeURI(res.data);
          const link = document.createElement('a');
          link.href = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_call_transition_excel?startDate=${this.typeCallTransitionStartDateSelectVer}&endDate=${this.typeCallTransitionEndDateSelectVer}&parkingId=${this.encodeUrl}`;
          console.log(link.href);
          link.setAttribute('download', 'backlog.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error)
        });
      }
    },
    // 【Excel出力】物件別 入電推移集計(登録済みの設定で出力 週別出力)
    async callTransitionExcelBtn2SelectVer(): Promise<void>{
      if (this.typeCallTransitionSelectRecord == "" || this.typeCallTransitionStartDateSelectVer == "" || this.typeCallTransitionEndDateSelectVer == "") {
        alert('必要項目が入力されていません。');
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_get_parking_ids`;
        const formData = { params: { id: this.typeCallTransitionSelectRecord} };
        return await axios
        .get(url, formData)
        .then(res => {
          console.log(res)
          this.encodeUrl = encodeURI(res.data);
          const link = document.createElement('a');
          link.href = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_call_transition_excel?startDate=${this.typeCallTransitionStartDateSelectVer}&endDate=${this.typeCallTransitionEndDateSelectVer}&parkingId=${this.encodeUrl}&outputType=2`;
          console.log(link.href);
          link.setAttribute('download', 'backlog.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error)
        });
      }
    },
    // 【Excel出力】物件別 入電推移集計(登録)
    async callTransitionRegisterBtn(): Promise<void>{
      this.$vloading.show();
      if (this.typeCallTransitionRegisterParking == "" || this.typeCallTransitionRegisterTitle == "") {
        alert('必要項目が入力されていません。');
        this.$vloading.hide();
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/regist_get_backlog_aggregates`;
        const formData = { params: { parkingIds: this.typeCallTransitionRegisterParking, title: this.typeCallTransitionRegisterTitle} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          console.log(res.data)
          alert(res.data);
          this.$vloading.hide();
          if (res.data == "登録が完了しました。") { // 登録が完了した場合だけリロード
            document.location.reload()
          }
        })
        .catch(error => {
          console.log(error)
          this.$vloading.hide();
        });
      }
    },
    // 【Excel出力】物件別 入電推移集計(追加)
    async callTransitionUpdateBtn(): Promise<void>{
      this.$vloading.show();
      if (this.typeCallTransitionUpdateParking == "" || this.typeCallTransitionUpdateRecord == "") {
        alert('必要項目が入力されていません。');
        this.$vloading.hide();
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/update_get_backlog_aggregates`;
        const formData = { params: { parkingIds: this.typeCallTransitionUpdateParking, recordId: this.typeCallTransitionUpdateRecord} };
        return await axios
        .post(url, formData.params)
        .then(res => {
          console.log(res.data)
          alert(res.data);
          this.$vloading.hide();
          if (res.data == "物件の追加が完了しました。") { // 追加が完了した場合だけリロード
            document.location.reload()
          }
        })
        .catch(error => {
          console.log(error)
          this.$vloading.hide();
        });
      }
    },
    // 【Excel出力】物件別 入電推移集計(削除)
    async callTransitionDeleteBtn(): Promise<void>{
      this.$vloading.show();
      if (this.typeCallTransitionDeleteRecord == "") {
        alert('削除する項目を選択してください');
        this.$vloading.hide();
      } else {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_delete`;
        const formData = { params: { id: this.typeCallTransitionDeleteRecord} };
        return await axios
        .delete(url, formData)
        .then(res => {
          console.log(res.data)
          alert(res.data);
          this.$vloading.hide();
          document.location.reload()
        })
        .catch(error => {
          console.log(error)
          this.$vloading.hide();
        });
      }
    },
    // 【CSV出力】期間指定：日別集計csv出力
    async callRatioCsvBtn(): Promise<void> {
      this.$vloading.show();
      const today                        = new Date();                                   // 日付:今日
      const typeRatioStartDate           = new Date(this.typeRatioStartDate);            // 日付:始点
      const typeRatioEndDate             = new Date(this.typeRatioEndDate);              // 日付:終点
      const typeRatioTodayValidation     = today.getFullYear() + "/" + ('00' + (today.getMonth()+1)).slice(-2) + "/"+ ('00' + today.getDate()).slice(-2);
      const typeRatioStartDateValidation = typeRatioStartDate.getFullYear() + "/" + ('00' + (typeRatioStartDate.getMonth()+1)).slice(-2) + "/"+ ('00' + typeRatioStartDate.getDate()).slice(-2);
      const typeRatioEndDateValidation   = typeRatioEndDate.getFullYear() + "/" + ('00' + (typeRatioEndDate.getMonth()+1)).slice(-2) + "/"+ ('00' + typeRatioEndDate.getDate()).slice(-2);
      if (typeRatioStartDate <= typeRatioEndDate && typeRatioTodayValidation > typeRatioStartDateValidation && typeRatioTodayValidation > typeRatioEndDateValidation) {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_create_smp_call_rate_csv`;
        const formData = { params: { startDate: this.typeRatioStartDate, endDate: this.typeRatioEndDate} };
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);                                   // 文字化け対応
        return await axios
        .get(url, formData)
        .then(res => {
          const url = URL.createObjectURL(new Blob([bom,res.data], {type: 'text/csv'}));  // ここからcsvダウンロード処理
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backlog.csv');
          document.body.appendChild(link);
          link.click();
          link.revokeObjectURL();                                                          // ここまでcsvダウンロード処理
          this.$vloading.hide();
        })
        .catch(error => {
          console.log("取得失敗！")
          this.$vloading.hide();
        });
      } else {
        alert('値が不正です。');
        this.$vloading.hide();
      }
    },
    //【CSV出力】期間指定：カテゴリ / 物件別 入電課題集計
    async categoryCsvBtn(): Promise<void> {
      this.$vloading.show();
      const today                        = new Date();                                  // 日付:今日
      const typeIssueStartDate           = new Date(this.typeIssueStartDate);           // 日付:始点
      const typeIssueEndDate             = new Date(this.typeIssueEndDate);             // 日付:終点
      const typeIssueTodayValidation     = today.getFullYear() + "/" + ('00' + (today.getMonth()+1)).slice(-2) + "/"+ ('00' + today.getDate()).slice(-2);
      const typeIssueStartDateValidation = typeIssueStartDate.getFullYear() + "/" + ('00' + (typeIssueStartDate.getMonth()+1)).slice(-2) + "/"+ ('00' + typeIssueStartDate.getDate()).slice(-2);
      const typeIssueEndDateValidation   = typeIssueEndDate.getFullYear() + "/" + ('00' + (typeIssueEndDate.getMonth()+1)).slice(-2) + "/"+ ('00' + typeIssueEndDate.getDate()).slice(-2);
      if (typeIssueStartDateValidation <= typeIssueEndDateValidation && typeIssueTodayValidation > typeIssueStartDateValidation && typeIssueTodayValidation > typeIssueEndDateValidation && this.selectedParking.length > 0) {
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_create_smp_call_issues_csv`;
        const formData = { selectedParking: this.selectedParking, startDate: this.typeIssueStartDate, endDate: this.typeIssueEndDate };
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);                                   // 文字化け対応
        return await axios
        .post(url, formData)
        .then(res => {
          const url = URL.createObjectURL(new Blob([bom,res.data], {type: 'text/csv'}));  // ここからcsvダウンロード処理
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backlog.csv');
          document.body.appendChild(link);
          link.click();
          link.revokeObjectURL();                                                          // ここまでcsvダウンロード処理
          this.$vloading.hide();
        })
        .catch(error => {
          console.log("取得失敗！")
          this.$vloading.hide();
        });
      } else {
        alert('値が不正です。');
        this.$vloading.hide();
        console.log(this.selectedParking)
      }
    },
    // 物件名の取得(mounted)
    async getParkings(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates`;
      return await axios // ajax通信開始
      .get(url)
      .then(res => {
        console.log(res);
        this.parkings = res.data;
      })
      .catch(error => {
        console.log("取得失敗！")
      });
    },
    // 設定の取得(mounted)
    async getAggregateGroups(): Promise<void> {
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_backlog_aggregates_groups`;
      return await axios // ajax通信開始
      .get(url)
      .then(res => {
        console.log(res);
        this.typeCallTransitionAggregateGroups = res.data;
      })
      .catch(error => {
        console.log("取得失敗！")
      });
    },
  },

  mounted() {
    const today = new Date();
    this.todayView = today.getFullYear() + "/" + (today.getMonth()+1) + "/" + today.getDate();
    this.todayValidation = today.getFullYear() + "-" + ('00' + (today.getMonth()+1)).slice(-2) + "-"+ ('00' + (today.getDate()-2)).slice(-2);
    this.typeCallTransitionStartDate = today.getFullYear() + "-" + ('00' + (today.getMonth()+1)).slice(-2) + "-"+ ('00' + today.getDate()).slice(-2);
    this.typeCallTransitionStartDateSelectVer = today.getFullYear() + "-" + ('00' + (today.getMonth()+1)).slice(-2) + "-"+ ('00' + today.getDate()).slice(-2);
    this.getParkings();
    this.getAggregateGroups();
  }
});
