






















































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";

interface Params {
  dates: any;
  panel: number | null;
  switch1:boolean;
  switch2:boolean;
  valid: boolean;
  dialog:boolean;
  result: any;
}
export default Vue.extend({
  data(): Params {
    return {
      dates: [],
      panel: 0,
      switch1:true,
      switch2:true,
      valid: false,
      dialog: false,
      result:[]
    }
  },
  computed: {
    // datesの日付がindex[0](始点)とindex[1]（終点）で、逆転していた場合はその二つを入れ替える
    sortedDates() {
      const datesCopy:any = [...this.dates];
      if (datesCopy[0] && datesCopy[1] && new Date(datesCopy[0]) > new Date(datesCopy[1])) {
        const temp = datesCopy[0];
        datesCopy[0] = datesCopy[1];
        datesCopy[1] = temp;
      }
      return datesCopy;
    },
    selectServer() {
      return store.state.WebUI.server;
    },
  },
  methods: {
    addOneDay(dateStr: string): string {
      const date = new Date(dateStr);
      date.setDate(date.getDate() + 1);
      return date.toISOString().split('T')[0];
    },
    async send(sortedDates:any, switch1:boolean, switch2:boolean): Promise<void> {
      if (this.selectServer) {
        this.$vloading.show();
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/commons/miss_recognizeds`;
        const formData = {
          params: {
            server: this.selectServer,
            start: sortedDates[0],
            end: this.addOneDay(sortedDates[1]),
            notNumberFlg: switch1,
            searchType: switch2,
          }
        }
        return await axios
        .get(url, formData)
        .then(res => {
          this.result = res.data;
          this.$vloading.hide()
        })
        .catch(error => {
          console.log(error)
          alert("サーバー情報の取得に失敗しました");
          this.$vloading.hide()
        })
        .finally(() => {
          this.$vloading.hide()
        });
      } else {
        alert("物件を選択してください");
      }
    },
  },
});
