



































import Vue from "vue";
import axios from "axios";
interface Params {
  notification:any;
}
export default Vue.extend({
  data(): Params {
    return {
      notification:[]
    }
  },
  methods: {
    async getNotification(): Promise<void>{
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/notifications`;
      return await axios
      .get(url)
      .then(res => {
        this.notification = res.data.slice(0, 5);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getNotification();
  }
})
